class Calculations {

  calculateGrossTotal(price, share): number {
    const total = (price * (share * 100));
    return total;
  }

  calculateNetTotal(price, share): number {
    const brokerageMin = 8;
    const clearing = 0.0003;
    const stampDutyFee = (price * share * 100) / 1000;
    // Clearing below RM 1000
    if (((price * (share * 100)) === 333333) || ((price * (share * 100)) < 333333)) {
      // Stamp duty below RM 1000
      if (((price * (share * 100)) === 666666.67) || ((price * (share * 100)) < 666666.67)) {
        const totalOut = (price * (share * 100)) + brokerageMin + (Math.ceil(stampDutyFee) * 1.5) + (clearing * price * share * 100);
        return totalOut;
      }
      // Stamp duty max RM 1000
      if ((price * (share * 100)) > 666666.67) {
        const totalOut = (price * (share * 100)) + brokerageMin + 1000 + (clearing * price * share * 100);
        return totalOut;
      }
    }
    // Clearing max RM 1000
    if ((price * (share * 100)) > 333333) {
      // Stamp duty below RM 1000
      if (((price * (share * 100)) === 666666.67) || ((price * (share * 100)) < 666666.67)) {
        const totalOut = (price * (share * 100)) + brokerageMin + (Math.ceil(stampDutyFee) * 1.5) + 1000;
        return totalOut;
      }
      // Stamp duty max RM 1000
      if ((price * (share * 100)) > 666666.67) {
        const totalOut = (price * (share * 100)) + brokerageMin + 1000 + 1000;
        return totalOut;
      }
    }
  }

  calculateEntryNetTotal(price, share): number {
    const brokerageMin = 8;
    const clearing = 0.0003;
    const stampDutyFee = (price * share * 100) / 1000;
    // Clearing below RM 1000
    if (((price * (share * 100)) === 333333) || ((price * (share * 100)) < 333333)) {
      // Stamp duty below RM 1000
      if (((price * (share * 100)) === 666666.67) || ((price * (share * 100)) < 666666.67)) {
        const totalOut = (price * (share * 100)) + brokerageMin + (Math.ceil(stampDutyFee) * 1.5) + (clearing * price * share * 100);
        return totalOut;
      }
      // Stamp duty max RM 1000
      if ((price * (share * 100)) > 666666.67) {
        const totalOut = (price * (share * 100)) + brokerageMin + 1000 + (clearing * price * share * 100);
        return totalOut;
      }
    }
    // Clearing max RM 1000
    if ((price * (share * 100)) > 333333) {
      // Stamp duty below RM 1000
      if (((price * (share * 100)) === 666666.67) || ((price * (share * 100)) < 666666.67)) {
        const totalOut = (price * (share * 100)) + brokerageMin + (Math.ceil(stampDutyFee) * 1.5) + 1000;
        return totalOut;
      }
      // Stamp duty max RM 1000
      if ((price * (share * 100)) > 666666.67) {
        const totalOut = (price * (share * 100)) + brokerageMin + 1000 + 1000;
        return totalOut;
      }
    }
  }

  calculateEntryNetTotalByBrokerage(price, share, brokerage, brokerageMin): number {
    const grossTotal = (price * (share * 100));
    const brokerageFee = grossTotal * brokerage / 100;
    const stampDutyFee = grossTotal / 1000;
    const clearing = 0.0003;
    if ((brokerageFee < brokerageMin) || (brokerageFee === brokerageMin)) {
      // Clearing below RM 1000
      if ((grossTotal === 333333) || (grossTotal < 333333)) {
        // Stamp duty below RM 1000
        if ((grossTotal=== 666666.67) || (grossTotal < 666666.67)) {
          const totalOut = grossTotal + brokerageMin + (Math.ceil(stampDutyFee) * 1.5) + (clearing * grossTotal);
          return totalOut;
        }
        // Stamp duty max RM 1000
        if (grossTotal > 666666.67) {
          const totalOut = grossTotal + brokerageMin + 1000 + (clearing * grossTotal);
          return totalOut;
        }
      }
      // Clearing max RM 1000
      if (grossTotal > 333333) {
        // Stamp duty below RM 1000
        if ((grossTotal === 666666.67) || (grossTotal < 666666.67)) {
          const totalOut = grossTotal + brokerageMin + (Math.ceil(stampDutyFee) * 1.5) + 1000;
          return totalOut;
        }
        // Stamp duty max RM 1000
        if (grossTotal > 666666.67) {
          const totalOut = (price * (share * 100)) + brokerageMin + 1000 + 1000;
          return totalOut;
        }
      }
    }
    if (brokerageFee > brokerageMin) {
      // Clearing below RM 1000
      if ((grossTotal === 333333) || (grossTotal < 333333)) {
        // Stamp duty below RM 1000
        if ((grossTotal === 666666.67) || (grossTotal < 666666.67)) {
          const totalOut = grossTotal + brokerageMin + (Math.ceil(stampDutyFee) * 1.5) + (clearing * grossTotal);
          return totalOut;
        }
        // Stamp duty max RM 1000
        if (grossTotal > 666666.67) {
          const totalOut = grossTotal + brokerageMin + 1000 + (clearing * grossTotal);
          return totalOut;
        }
      }
      // Clearing max RM 1000
      if (grossTotal > 333333) {
        // Stamp duty below RM 1000
        if ((grossTotal === 666666.67) || (grossTotal < 666666.67)) {
          const totalOut = grossTotal + brokerageMin + (Math.ceil(stampDutyFee) * 1.5) + 1000;
          return totalOut;
        }
        // Stamp duty max RM 1000
        if (grossTotal > 666666.67) {
          const totalOut = (price * (share * 100)) + brokerageMin + 1000 + 1000;
          return totalOut;
        }
      }
    }
  }

  calculateExitNetTotal(price, share): number {
    const brokerageMin = 8;
    const clearing = 0.0003;
    const stampDutyFee = (price * share * 100) / 1000;
    // Clearing below RM 1000
    if (((price * (share * 100)) === 333333) || ((price * (share * 100)) < 333333)) {
      // Stamp duty below RM 1000
      if (((price * (share * 100)) === 666666.67) || ((price * (share * 100)) < 666666.67)) {
        const totalOut = (price * (share * 100)) - brokerageMin - (Math.ceil(stampDutyFee) * 1.5) - (clearing * price * share * 100);
        return totalOut;
      }
      // Stamp duty max RM 1000
      if ((price * (share * 100)) > 666666.67) {
        const totalOut = (price * (share * 100)) - brokerageMin - 1000 - (clearing * price * share * 100);
        return totalOut;
      }
    }
    // Clearing max RM 1000
    if ((price * (share * 100)) > 333333) {
      // Stamp duty below RM 1000
      if (((price * (share * 100)) === 666666.67) || ((price * (share * 100)) < 666666.67)) {
        const totalOut = (price * (share * 100)) - brokerageMin - (Math.ceil(stampDutyFee) * 1.5) - 1000;
        return totalOut;
      }
      // Stamp duty max RM 1000
      if ((price * (share * 100)) > 666666.67) {
        const totalOut = (price * (share * 100)) - brokerageMin - 1000 - 1000;
        return totalOut;
      }
    }
  }

  calculateGrossReturn(entryPrice, entryShare, exitPrice, exitShare): number {
    if ((exitPrice !== null) && (exitShare !== null)) {
      const grossEntry = (entryPrice * entryShare * 100);
      const grossExit = (exitPrice * exitShare * 100);
      const grossReturn = (grossExit - grossEntry);
      return grossReturn;
    } else {
      const grossReturn = 0;
      return grossReturn;
    }
  }

  calculateGrossReturnPercentage(entryPrice, entryShare, exitPrice, exitShare): number {
    if ((exitPrice !== null) && (exitShare !== null)) {
      const grossEntry = (entryPrice * entryShare * 100);
      const grossExit = (exitPrice * exitShare * 100);
      const grossReturnPercentage = (grossExit - grossEntry) / grossEntry * 100;
      return grossReturnPercentage;
    } else {
      const grossReturnPercentage = 0;
      return grossReturnPercentage;
    }
  }

  calculateNetReturn(entryPrice, entryShare, exitPrice, exitShare): number {
    const brokerageMin = 8;
    const clearing = 0.0003;
    const stampDutyFeeEntry = (entryPrice * entryShare * 100) / 1000;
    const stampDutyFeeExit = (exitPrice * exitShare * 100) / 1000;
    if ((exitPrice !== null) && (exitShare !== null)) {
      const grossEntry = (entryPrice * entryShare * 100);
      const grossExit = (exitPrice * exitShare * 100);

      // Clearing below RM 1000
      if ((grossEntry === 333333) || (grossEntry < 333333)) {
        // Stamp duty below RM 1000
        if ((grossEntry === 666666.67) || (grossEntry < 666666.67)) {
          const netEntry = grossEntry + brokerageMin + (Math.ceil(stampDutyFeeEntry) * 1.5) + (clearing * grossEntry);
          // Clearing below RM 1000
          if ((grossExit === 333333) || (grossExit < 333333)) {
            // Stamp duty below RM 1000
            if ((grossExit === 666666.67) || (grossExit < 666666.67)) {
              const netExit = grossExit - brokerageMin - (Math.ceil(stampDutyFeeExit) * 1.5) - (clearing * grossExit);
              const netReturn = netExit - netEntry;
              return netReturn;
            }
            // Stamp duty max RM 1000
            if (grossExit > 666666.67) {
              const netExit = grossExit - brokerageMin - 1000 - (clearing * grossExit);
              const netReturn = netExit - netEntry;
              return netReturn;
            }
          }
          // Clearing max RM 1000
          if (grossExit > 333333) {
            // Stamp duty below RM 1000
            if ((grossExit === 666666.67) || (grossExit < 666666.67)) {
              const netExit = grossExit - brokerageMin - (Math.ceil(stampDutyFeeExit) * 1.5) - 1000;
              const netReturn = netExit - netEntry;
              return netReturn;
            }
            // Stamp duty max RM 1000
            if (grossExit > 666666.67) {
              const netExit = grossExit - brokerageMin - 1000 - 1000;
              const netReturn = netExit - netEntry;
              return netReturn;
            }
          }
        }
        // Stamp duty max RM 1000
        if (grossEntry > 666666.67) {
          const netEntry = grossEntry + brokerageMin + 1000 + (clearing * grossEntry);
          // Clearing below RM 1000
          if ((grossExit === 333333) || (grossExit < 333333)) {
            // Stamp duty below RM 1000
            if ((grossExit === 666666.67) || (grossExit < 666666.67)) {
              const netExit = grossExit - brokerageMin - (Math.ceil(stampDutyFeeExit) * 1.5) - (clearing * grossExit);
              const netReturn = netExit - netEntry;
              return netReturn;
            }
            // Stamp duty max RM 1000
            if (grossExit > 666666.67) {
              const netExit = grossExit - brokerageMin - 1000 - (clearing * grossExit);
              const netReturn = netExit - netEntry;
              return netReturn;
            }
          }
          // Clearing max RM 1000
          if (grossExit > 333333) {
            // Stamp duty below RM 1000
            if ((grossExit === 666666.67) || (grossExit < 666666.67)) {
              const netExit = grossExit - brokerageMin - (Math.ceil(stampDutyFeeExit) * 1.5) - 1000;
              const netReturn = netExit - netEntry;
              return netReturn;
            }
            // Stamp duty max RM 1000
            if (grossExit > 666666.67) {
              const netExit = grossExit - brokerageMin - 1000 - 1000;
              const netReturn = netExit - netEntry;
              return netReturn;
            }
          }
        }
      }

      // Clearing max RM 1000
      if (grossEntry > 333333) {
        // Stamp duty below RM 1000
        if ((grossEntry === 666666.67) || (grossEntry < 666666.67)) {
          const netEntry = grossEntry + brokerageMin + (Math.ceil(stampDutyFeeEntry) * 1.5) + 1000;
          // Clearing below RM 1000
          if ((grossExit === 333333) || (grossExit < 333333)) {
            // Stamp duty below RM 1000
            if ((grossExit === 666666.67) || (grossExit < 666666.67)) {
              const netExit = grossExit - brokerageMin - (Math.ceil(stampDutyFeeExit) * 1.5) - (clearing * grossExit);
              const netReturn = netExit - netEntry;
              return netReturn;
            }
            // Stamp duty max RM 1000
            if (grossExit > 666666.67) {
              const netExit = grossExit - brokerageMin - 1000 - (clearing * grossExit);
              const netReturn = netExit - netEntry;
              return netReturn;
            }
          }
          // Clearing max RM 1000
          if (grossExit > 333333) {
            // Stamp duty below RM 1000
            if ((grossExit === 666666.67) || (grossExit < 666666.67)) {
              const netExit = grossExit - brokerageMin - (Math.ceil(stampDutyFeeExit) * 1.5) - 1000;
              const netReturn = netExit - netEntry;
              return netReturn;
            }
            // Stamp duty max RM 1000
            if (grossExit > 666666.67) {
              const netExit = grossExit - brokerageMin - 1000 - 1000;
              const netReturn = netExit - netEntry;
              return netReturn;
            }
          }
        }
        // Stamp duty max RM 1000
        if (grossEntry > 666666.67) {
          const netEntry = grossEntry + brokerageMin + 1000 + 1000;
          // Clearing below RM 1000
          if ((grossExit === 333333) || (grossExit < 333333)) {
            // Stamp duty below RM 1000
            if ((grossExit === 666666.67) || (grossExit < 666666.67)) {
              const netExit = grossExit - brokerageMin - (Math.ceil(stampDutyFeeExit) * 1.5) - (clearing * grossExit);
              const netReturn = netExit - netEntry;
              return netReturn;
            }
            // Stamp duty max RM 1000
            if (grossExit > 666666.67) {
              const netExit = grossExit - brokerageMin - 1000 - (clearing * grossExit);
              const netReturn = netExit - netEntry;
              return netReturn;
            }
          }
          // Clearing max RM 1000
          if (grossExit > 333333) {
            // Stamp duty below RM 1000
            if ((grossExit === 666666.67) || (grossExit < 666666.67)) {
              const netExit = grossExit - brokerageMin - (Math.ceil(stampDutyFeeExit) * 1.5) - 1000;
              const netReturn = netExit - netEntry;
              return netReturn;
            }
            // Stamp duty max RM 1000
            if (grossExit > 666666.67) {
              const netExit = grossExit - brokerageMin - 1000 - 1000;
              const netReturn = netExit - netEntry;
              return netReturn;
            }
          }
        }
      }
    }
  }

  calculateNetReturnPercentage(entryPrice, entryShare, exitPrice, exitShare): number {
    const brokerageMin = 8;
    const clearing = 0.0003;
    const stampDutyFeeEntry = (entryPrice * entryShare * 100) / 1000;
    const stampDutyFeeExit = (exitPrice * exitShare * 100) / 1000;
    if ((exitPrice !== null) && (exitShare !== null)) {
      const grossEntry = (entryPrice * entryShare * 100);
      const grossExit = (exitPrice * exitShare * 100);
      // Clearing below RM 1000
      if ((grossEntry === 333333) || (grossEntry < 333333)) {
        // Stamp duty below RM 1000
        if ((grossEntry === 666666.67) || (grossEntry < 666666.67)) {
          const netEntry = grossEntry + brokerageMin + (Math.ceil(stampDutyFeeEntry) * 1.5) + (clearing * grossEntry);
          // Clearing below RM 1000
          if ((grossExit === 333333) || (grossExit < 333333)) {
            // Stamp duty below RM 1000
            if ((grossExit === 666666.67) || (grossExit < 666666.67)) {
              const netExit = grossExit - brokerageMin - (Math.ceil(stampDutyFeeExit) * 1.5) - (clearing * grossExit);
              const netReturnPercentage = ((netExit - netEntry) / netEntry) * 100;
              return netReturnPercentage;
            }
            // Stamp duty max RM 1000
            if (grossExit > 666666.67) {
              const netExit = grossExit - brokerageMin - 1000 - (clearing * grossExit);
              const netReturnPercentage = ((netExit - netEntry) / netEntry) * 100;
              return netReturnPercentage;
            }
          }
          // Clearing max RM 1000
          if (grossExit > 333333) {
            // Stamp duty below RM 1000
            if ((grossExit === 666666.67) || (grossExit < 666666.67)) {
              const netExit = grossExit - brokerageMin - (Math.ceil(stampDutyFeeExit) * 1.5) - 1000;
              const netReturnPercentage = ((netExit - netEntry) / netEntry) * 100;
              return netReturnPercentage;
            }
            // Stamp duty max RM 1000
            if (grossExit > 666666.67) {
              const netExit = grossExit - brokerageMin - 1000 - 1000;
              const netReturnPercentage = ((netExit - netEntry) / netEntry) * 100;
              return netReturnPercentage;
            }
          }
        }
        // Stamp duty max RM 1000
        if (grossEntry > 666666.67) {
          const netEntry = grossEntry + brokerageMin + 1000 + (clearing * grossEntry);
          // Clearing below RM 1000
          if ((grossExit === 333333) || (grossExit < 333333)) {
            // Stamp duty below RM 1000
            if ((grossExit === 666666.67) || (grossExit < 666666.67)) {
              const netExit = grossExit - brokerageMin - (Math.ceil(stampDutyFeeExit) * 1.5) - (clearing * grossExit);
              const netReturnPercentage = ((netExit - netEntry) / netEntry) * 100;
              return netReturnPercentage;
            }
            // Stamp duty max RM 1000
            if (grossExit > 666666.67) {
              const netExit = grossExit - brokerageMin - 1000 - (clearing * grossExit);
              const netReturnPercentage = ((netExit - netEntry) / netEntry) * 100;
              return netReturnPercentage;
            }
          }
          // Clearing max RM 1000
          if (grossExit > 333333) {
            // Stamp duty below RM 1000
            if ((grossExit === 666666.67) || (grossExit < 666666.67)) {
              const netExit = grossExit - brokerageMin - (Math.ceil(stampDutyFeeExit) * 1.5) - 1000;
              const netReturnPercentage = ((netExit - netEntry) / netEntry) * 100;
              return netReturnPercentage;
            }
            // Stamp duty max RM 1000
            if (grossExit > 666666.67) {
              const netExit = grossExit - brokerageMin - 1000 - 1000;
              const netReturnPercentage = ((netExit - netEntry) / netEntry) * 100;
              return netReturnPercentage;
            }
          }
        }
      }

      // Clearing max RM 1000
      if (grossEntry > 333333) {
        // Stamp duty below RM 1000
        if ((grossEntry === 666666.67) || (grossEntry < 666666.67)) {
          const netEntry = grossEntry + brokerageMin + (Math.ceil(stampDutyFeeEntry) * 1.5) + 1000;
          // Clearing below RM 1000
          if ((grossExit === 333333) || (grossExit < 333333)) {
            // Stamp duty below RM 1000
            if ((grossExit === 666666.67) || (grossExit < 666666.67)) {
              const netExit = grossExit - brokerageMin - (Math.ceil(stampDutyFeeExit) * 1.5) - (clearing * grossExit);
              const netReturnPercentage = ((netExit - netEntry) / netEntry) * 100;
              return netReturnPercentage;
            }
            // Stamp duty max RM 1000
            if (grossExit > 666666.67) {
              const netExit = grossExit - brokerageMin - 1000 - (clearing * grossExit);
              const netReturnPercentage = ((netExit - netEntry) / netEntry) * 100;
              return netReturnPercentage;
            }
          }
          // Clearing max RM 1000
          if (grossExit > 333333) {
            // Stamp duty below RM 1000
            if ((grossExit === 666666.67) || (grossExit < 666666.67)) {
              const netExit = grossExit - brokerageMin - (Math.ceil(stampDutyFeeExit) * 1.5) - 1000;
              const netReturnPercentage = ((netExit - netEntry) / netEntry) * 100;
              return netReturnPercentage;
            }
            // Stamp duty max RM 1000
            if (grossExit > 666666.67) {
              const netExit = grossExit - brokerageMin - 1000 - 1000;
              const netReturnPercentage = ((netExit - netEntry) / netEntry) * 100;
              return netReturnPercentage;
            }
          }
        }
        // Stamp duty max RM 1000
        if (grossEntry > 666666.67) {
          const netEntry = grossEntry + brokerageMin + 1000 + 1000;
          // Clearing below RM 1000
          if ((grossExit === 333333) || (grossExit < 333333)) {
            // Stamp duty below RM 1000
            if ((grossExit === 666666.67) || (grossExit < 666666.67)) {
              const netExit = grossExit - brokerageMin - (Math.ceil(stampDutyFeeExit) * 1.5) - (clearing * grossExit);
              const netReturnPercentage = ((netExit - netEntry) / netEntry) * 100;
              return netReturnPercentage;
            }
            // Stamp duty max RM 1000
            if (grossExit > 666666.67) {
              const netExit = grossExit - brokerageMin - 1000 - (clearing * grossExit);
              const netReturnPercentage = ((netExit - netEntry) / netEntry) * 100;
              return netReturnPercentage;
            }
          }
          // Clearing max RM 1000
          if (grossExit > 333333) {
            // Stamp duty below RM 1000
            if ((grossExit === 666666.67) || (grossExit < 666666.67)) {
              const netExit = grossExit - brokerageMin - (Math.ceil(stampDutyFeeExit) * 1.5) - 1000;
              const netReturnPercentage = ((netExit - netEntry) / netEntry) * 100;
              return netReturnPercentage;
            }
            // Stamp duty max RM 1000
            if (grossExit > 666666.67) {
              const netExit = grossExit - brokerageMin - 1000 - 1000;
              const netReturnPercentage = ((netExit - netEntry) / netEntry) * 100;
              return netReturnPercentage;
            }
          }
        }
      }
    }
  }

  calculateNetReturnByBrokerage(entryPrice, entryShare, exitPrice, exitShare, brokerage, minBrokerage): number {
    if ((exitPrice !== null) && (exitShare !== null)) {
      const grossEntry = (entryPrice * entryShare * 100);
      const grossExit = (exitPrice * exitShare * 100);
      const entryBrokerageFee = grossEntry * brokerage / 100;
      const exitBrokerageFee = grossExit * brokerage / 100;
      var netEntry;
      var netExit;
      const clearing = 0.0003;
      const stampDutyFeeEntry = (entryPrice * entryShare * 100) / 1000;
      const stampDutyFeeExit = (exitPrice * exitShare * 100) / 1000;
      if ((entryBrokerageFee < minBrokerage) || (entryBrokerageFee === minBrokerage)) {
        // Clearing below RM 1000
        if ((grossEntry === 333333) || (grossEntry < 333333)) {
          // Stamp duty below RM 1000
          if ((grossEntry === 666666.67) || (grossEntry < 666666.67)) {
            netEntry = grossEntry + minBrokerage + (Math.ceil(stampDutyFeeEntry) * 1.5) + (clearing * grossEntry);
          }
          // Stamp duty max RM 1000
          if (grossEntry > 666666.67) {
            netEntry = grossEntry + minBrokerage + 1000 + (clearing * grossEntry);
          }
        }
        // Clearing max RM 1000
        if (grossEntry > 333333) {
          // Stamp duty below RM 1000
          if ((grossEntry === 666666.67) || (grossEntry < 666666.67)) {
            netEntry = grossEntry + minBrokerage + (Math.ceil(stampDutyFeeEntry) * 1.5) + 1000;
          }
          // Stamp duty max RM 1000
          if (grossEntry > 666666.67) {
            netEntry = grossEntry + minBrokerage + 1000 + 1000;
          }
        }
      }
      if (entryBrokerageFee > minBrokerage) {
        // Clearing below RM 1000
        if ((grossEntry=== 333333) || (grossEntry < 333333)) {
          // Stamp duty below RM 1000
          if ((grossEntry === 666666.67) || (grossEntry < 666666.67)) {
            netEntry = grossEntry + entryBrokerageFee + (Math.ceil(stampDutyFeeEntry) * 1.5) + (clearing * grossEntry);
          }
          // Stamp duty max RM 1000
          if (grossEntry > 666666.67) {
            netEntry = grossEntry + entryBrokerageFee + 1000 + (clearing * grossEntry);
          }
        }
        // Clearing max RM 1000
        if (grossEntry > 333333) {
          // Stamp duty below RM 1000
          if ((grossEntry === 666666.67) || (grossEntry < 666666.67)) {
            netEntry = grossEntry + entryBrokerageFee + (Math.ceil(stampDutyFeeEntry) * 1.5) + 1000;
          }
          // Stamp duty max RM 1000
          if (grossEntry > 666666.67) {
            netEntry = grossEntry + entryBrokerageFee + 1000 + 1000;
          }
        }
      }
      if ((exitBrokerageFee < minBrokerage) || (exitBrokerageFee === minBrokerage)) {
        // Clearing below RM 1000
        if ((grossExit=== 333333) || (grossExit < 333333)) {
          // Stamp duty below RM 1000
          if ((grossExit === 666666.67) || (grossExit < 666666.67)) {
            netExit = grossExit - minBrokerage - (Math.ceil(stampDutyFeeExit) * 1.5) - (clearing * grossExit);
          }
          // Stamp duty max RM 1000
          if (grossExit > 666666.67) {
            netExit = grossExit - minBrokerage - 1000 - (clearing * grossExit);
          }
        }
        // Clearing max RM 1000
        if (grossExit > 333333) {
          // Stamp duty below RM 1000
          if ((grossExit === 666666.67) || (grossExit < 666666.67)) {
            netExit = grossExit - minBrokerage - (Math.ceil(stampDutyFeeExit) * 1.5) - 1000;
          }
          // Stamp duty max RM 1000
          if (grossExit > 666666.67) {
            netExit = grossExit - minBrokerage - 1000 - 1000;
          }
        }
      }
      if (exitBrokerageFee > minBrokerage) {
        // Clearing below RM 1000
        if ((grossExit === 333333) || (grossExit < 333333)) {
          // Stamp duty below RM 1000
          if ((grossExit === 666666.67) || (grossExit < 666666.67)) {
            netExit = grossExit - exitBrokerageFee - (Math.ceil(stampDutyFeeExit) * 1.5) - (clearing * grossExit);
          }
          // Stamp duty max RM 1000
          if (grossExit > 666666.67) {
            netExit = grossExit - exitBrokerageFee - 1000 - (clearing * grossExit);
          }
        }
        // Clearing max RM 1000
        if (grossExit > 333333) {
          // Stamp duty below RM 1000
          if ((grossExit === 666666.67) || (grossExit < 666666.67)) {
            netExit = grossExit - exitBrokerageFee - (Math.ceil(stampDutyFeeExit) * 1.5) - 1000;
          }
          // Stamp duty max RM 1000
          if (grossExit > 666666.67) {
            netExit = grossExit - exitBrokerageFee - 1000 - 1000;
          }
        }
      }
      const netReturn = netExit - netEntry;
      return netReturn;
    }
  }

  calculateNetReturnPercentageByBrokerage(entryPrice, entryShare, exitPrice, exitShare, brokerage, minBrokerage): number {
    if ((exitPrice !== null) && (exitShare !== null)) {
      const grossEntry = (entryPrice * entryShare * 100);
      const grossExit = (exitPrice * exitShare * 100);
      const entryBrokerageFee = grossEntry * brokerage / 100;
      const exitBrokerageFee = grossExit * brokerage / 100;
      var netEntry;
      var netExit;
      const clearing = 0.0003;
      const stampDutyFeeEntry = (entryPrice * entryShare * 100) / 1000;
      const stampDutyFeeExit = (exitPrice * exitShare * 100) / 1000;
      if ((entryBrokerageFee < minBrokerage) || (entryBrokerageFee === minBrokerage)) {
        // Clearing below RM 1000
        if ((grossEntry === 333333) || (grossEntry < 333333)) {
          // Stamp duty below RM 1000
          if ((grossEntry === 666666.67) || (grossEntry < 666666.67)) {
            netEntry = grossEntry + minBrokerage + (Math.ceil(stampDutyFeeEntry) * 1.5) + (clearing * grossEntry);
          }
          // Stamp duty max RM 1000
          if (grossEntry > 666666.67) {
            netEntry = grossEntry + minBrokerage + 1000 + (clearing * grossEntry);
          }
        }
        // Clearing max RM 1000
        if (grossEntry > 333333) {
          // Stamp duty below RM 1000
          if ((grossEntry === 666666.67) || (grossEntry < 666666.67)) {
            netEntry = grossEntry + minBrokerage + (Math.ceil(stampDutyFeeEntry) * 1.5) + 1000;
          }
          // Stamp duty max RM 1000
          if (grossEntry > 666666.67) {
            netEntry = grossEntry + minBrokerage + 1000 + 1000;
          }
        }
      }
      if (entryBrokerageFee > minBrokerage) {
        // Clearing below RM 1000
        if ((grossEntry=== 333333) || (grossEntry < 333333)) {
          // Stamp duty below RM 1000
          if ((grossEntry === 666666.67) || (grossEntry < 666666.67)) {
            netEntry = grossEntry + entryBrokerageFee + (Math.ceil(stampDutyFeeEntry) * 1.5) + (clearing * grossEntry);
          }
          // Stamp duty max RM 1000
          if (grossEntry > 666666.67) {
            netEntry = grossEntry + entryBrokerageFee + 1000 + (clearing * grossEntry);
          }
        }
        // Clearing max RM 1000
        if (grossEntry > 333333) {
          // Stamp duty below RM 1000
          if ((grossEntry === 666666.67) || (grossEntry < 666666.67)) {
            netEntry = grossEntry + entryBrokerageFee + (Math.ceil(stampDutyFeeEntry) * 1.5) + 1000;
          }
          // Stamp duty max RM 1000
          if (grossEntry > 666666.67) {
            netEntry = grossEntry + entryBrokerageFee + 1000 + 1000;
          }
        }
      }
      if ((exitBrokerageFee < minBrokerage) || (exitBrokerageFee === minBrokerage)) {
        // Clearing below RM 1000
        if ((grossExit=== 333333) || (grossExit < 333333)) {
          // Stamp duty below RM 1000
          if ((grossExit === 666666.67) || (grossExit < 666666.67)) {
            netExit = grossExit - minBrokerage - (Math.ceil(stampDutyFeeExit) * 1.5) - (clearing * grossExit);
          }
          // Stamp duty max RM 1000
          if (grossExit > 666666.67) {
            netExit = grossExit - minBrokerage - 1000 - (clearing * grossExit);
          }
        }
        // Clearing max RM 1000
        if (grossExit > 333333) {
          // Stamp duty below RM 1000
          if ((grossExit === 666666.67) || (grossExit < 666666.67)) {
            netExit = grossExit - minBrokerage - (Math.ceil(stampDutyFeeExit) * 1.5) - 1000;
          }
          // Stamp duty max RM 1000
          if (grossExit > 666666.67) {
            netExit = grossExit - minBrokerage - 1000 - 1000;
          }
        }
      }
      if (exitBrokerageFee > minBrokerage) {
        // Clearing below RM 1000
        if ((grossExit === 333333) || (grossExit < 333333)) {
          // Stamp duty below RM 1000
          if ((grossExit === 666666.67) || (grossExit < 666666.67)) {
            netExit = grossExit - exitBrokerageFee - (Math.ceil(stampDutyFeeExit) * 1.5) - (clearing * grossExit);
          }
          // Stamp duty max RM 1000
          if (grossExit > 666666.67) {
            netExit = grossExit - exitBrokerageFee - 1000 - (clearing * grossExit);
          }
        }
        // Clearing max RM 1000
        if (grossExit > 333333) {
          // Stamp duty below RM 1000
          if ((grossExit === 666666.67) || (grossExit < 666666.67)) {
            netExit = grossExit - exitBrokerageFee - (Math.ceil(stampDutyFeeExit) * 1.5) - 1000;
          }
          // Stamp duty max RM 1000
          if (grossExit > 666666.67) {
            netExit = grossExit - exitBrokerageFee - 1000 - 1000;
          }
        }
      }
      const netReturnPercentage = ((netExit - netEntry) / netEntry) * 100;
      return netReturnPercentage;
    }
  }

  calculateShareFromCapital(price, capital): number {
    const brokerageMin = 8;
    const clearing = 0.003;
    const stampDutyFee = capital / 1000;
    const suggestedQuantity = ((capital - brokerageMin - (Math.ceil(stampDutyFee) * 1.5)) / (100 + clearing + 0.1)) / price;
    return suggestedQuantity;
  }

  calculateShareFromCapitalByBrokerage(price, capital, minBrokerage): number {
    const clearing = 0.0003;
    const stampDutyFee = capital / 1000;
    const suggestedQuantity = Math.round(((capital - minBrokerage - (Math.ceil(stampDutyFee) * 1.5)) / (100 + clearing + 0.1)) / price);
    return suggestedQuantity;
  }

  calculateAccountValue(totalCapital, totalReturn): number {
    const total = totalCapital + parseFloat(totalReturn);
    return total;
  }

  calculateAccountValuePercentage(totalCapital, totalReturn): number {
    const totalPercentage = (((totalCapital + parseFloat(totalReturn)) - totalCapital) / totalCapital) * 100;
    return totalPercentage;
  }
}

export default new Calculations();
