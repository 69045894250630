import React, { useEffect, useState } from 'react';
import GraphqlClient from '../../utils/GraphqlClient';
import { StockDetailsModel } from '../../models/StockDetails';
import { modelFactory } from '../../models/ModelFactory';
import { Link } from 'react-router-dom';
import SpinnerNoDisplay from '../shared/SpinnerNoDisplay';

const NonDisplayAllStockList = () => {
  const [allStocks, setAllStocks] = useState<StockDetailsModel[]>(null);
  const [filteredStocks, setFilteredStocks] = useState<StockDetailsModel[]>(null);
  const [tempScreenedStocks] = useState([]);

  useEffect(() => {
    setAllStocks(null);
    const listAllStocks = async () => {
      const results = await Promise.all([
        GraphqlClient.listStockDetailss(null)
      ]);
      const allStocks = results[0].map(i => modelFactory<StockDetailsModel>(i, StockDetailsModel))
      .sort((a, b) => {
        if (b.stockName > a.stockName) return -1;
        if (b.stockName < a.stockName) return 1;
        return 0;
      });
      setAllStocks(allStocks);
      const newForm = Object.assign([], allStocks);
      for (let i = 0; i < newForm.length; i++) {
        if ((newForm[i].listedOn === "100") || (newForm[i].listedOn === "400")) {
          if ((newForm[i].stockName.indexOf("-OR") === -1) 
            && (newForm[i].lastPrice !== 0)
            && (newForm[i].stockName.indexOf("-PA") === -1) 
            && (newForm[i].stockName.indexOf("-PB") === -1) 
            && (newForm[i].stockName.indexOf("-LA") === -1) 
            && (newForm[i].stockName !== "BIMB-WA") 
            && (newForm[i].stockName !== "BIOHLDG-WA") 
            && (newForm[i].stockName !== "CABNET-WA") 
            && (newForm[i].stockName !== "DGB-WB") 
            && (newForm[i].stockName !== "DNEX-WD")
            && (newForm[i].stockName !== "EDEN-WB")
            && (newForm[i].stockName !== "GLOTEC-WA")
            && (newForm[i].stockName !== "HIAPTEK-WB")
            && (newForm[i].stockName !== "PRLEXUS-WA")
            && (newForm[i].stockName !== "TOMYPAK-WA")
          ) {
            tempScreenedStocks.push({
              id: newForm[i].id,
              stockName: newForm[i].stockName
            });
          }
        }
      }
      setFilteredStocks(tempScreenedStocks);
    };
    listAllStocks();
  }, [tempScreenedStocks]);

  if (!allStocks || !tempScreenedStocks) {
    <SpinnerNoDisplay />
  }

  return(
    <>
      {filteredStocks && filteredStocks.map(stock => (
        <Link key={stock.id} to={`/search/MYX/${stock.stockName}`} style={{ display: 'none' }}>https://stocks.ringgitlab.com/search/MYX/{stock.stockName}</Link>
      ))}
    </>
  )
}

export default NonDisplayAllStockList;