import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import DashboardImg from '../../assets/trading-journal-dashboard.png';
import PerformanceManagementImg from '../../assets/trading.png';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';

const TradingJournal = () => {

  const colorBg = {
    backgroundColor: 'rgb(248, 248, 248)',
  };
  
  return(
    <>
      <Container className="pt-5 pb-5 mt-5">
        <Slide bottom>
          <h1 className="text-center mt-5 mb-3 font-weight-bold">
            Trading Journal on Ringgit Lab
          </h1>
          <img alt="Ringgit Lab: Trading Journal" className="mt-4" src={DashboardImg} style={{ width: '100%' }} />
          <h2 className="mt-5 text-center grey-color" style={{ fontSize: '24px' }}>
            Trading Journal on Ringgit Lab is one of the most effective tools for performance management. It is where you record and review 
            daily trades for better output and for future reference. A journal can help you track progress as well as study 
            mistakes made when entering or exiting a trade. In the long run, these reports can act as the foundation for better executions.
          </h2>
          <div className="mt-5 pt-5 pb-5 text-center">
            <a href="https://www.ringgitlab.com/signup" className="mb-3">
              <h3 className="mb-0">
                <Button className="primary-btn header-pill font-weight-bold">Sign Up For Free</Button>
              </h3>
            </a>
          </div>
        </Slide>
        <div className="divider mt-5"></div>
        <Slide bottom>
          <h2 className="mt-5 font-weight-bold text-center" style={{ fontSize: '28px' }}>
            Why You Should Keep A Trading Journal?
          </h2>
          <Row className="mt-5 pt-5">
            <Col md={4} sm={12} xs={12} className="mb-5">
              <img src={PerformanceManagementImg} alt="Ringgit Lab: Trading Journal" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', height: '400px', objectFit: 'cover', objectPosition: 'center', borderRadius: '10px' }} />
              <div className="mt-5 pb-5 text-center">
                <a href="https://www.ringgitlab.com/signup" className="mb-3">
                  <h3 className="mb-0">
                    <Button className="primary-btn header-pill font-weight-bold">Sign Up For Free</Button>
                  </h3>
                </a>
              </div>
            </Col>
            <Col md={8} sm={12} xs={12} className="mb-5">
              <h3 className="font-weight-bold" style={{ fontSize: '24px' }}>
                Identify trends and patterns
              </h3>
              <h4 className="grey-color" style={{ fontSize: '22px' }}>
                Notes are useful in analyzing which trading strategies work, and which are worth reconsidering. Make notes 
                on the charts you're using, the patterns you're watching, and how certain events impact your trade. Over time, 
                you'll be able to recognize the underlying mistakes costing you money. Writing things down prevents you from 
                repeating the same mistakes.
              </h4>
              <h3 className="font-weight-bold mt-5" style={{ fontSize: '24px' }}>
                Improve your trading techniques
              </h3>
              <h4 className="grey-color" style={{ fontSize: '22px' }}>
                Having a detailed record of past trades allows traders to better understand their strengths and weaknesses. 
                Jotting down how you feel is a good idea - it can help you realize whether you're making emotionally driven 
                decisions that ultimately hurt your trade. A trading journal gives you important insight about who you are 
                as a trader, and what you should focus to polish up your skills.
              </h4>
              <h3 className="font-weight-bold mt-5" style={{ fontSize: '24px' }}>
                Monitor your progress
              </h3>
              <h4 className="grey-color" style={{ fontSize: '22px' }}>
                The longer you trade, the more difficult it becomes to keep your progress in perspective. Writing down 
                your goals makes it easier for you to remember what you want to accomplish. It also serves as a source 
                of motivation. A trading journal allows you to keep tabs on your growth as a trader, helping you become 
                more confident.
              </h4>
            </Col>
          </Row>
        </Slide>
      </Container>
      <div style={colorBg}>
        <Container className="pt-5 pb-5">
          <Fade bottom>
            <h2 className="font-weight-bold text-center" style={{ fontSize: '28px' }}>
              3 Easy Steps To Improve Your Performance
            </h2>
            <Row className="mt-5">
              <Col md={4} sm={12} xs={12} className="mb-5">
                <p className="grey-color mb-0">Step 1</p>
                <h3 className="font-weight-bold" style={{ fontSize: '24px' }}>
                  Journal Your Trades
                </h3>
                <h4 style={{ fontSize: '22px' }}>
                  Journal your trades in seconds. The more you use your trading journal, the more we can help 
                  sharpen your trading edge.
                </h4>
              </Col>
              <Col md={4} sm={12} xs={12} className="mb-5">
                <p className="grey-color mb-0">Step 2</p>
                <h3 className="font-weight-bold" style={{ fontSize: '24px' }}>
                  Identify Your Pattern
                </h3>
                <h4 style={{ fontSize: '22px' }}>
                  Trading Journal shows you which strategies to focus on, which ones to avoid, and how to manage 
                  your account better to avoid losses.
                </h4>
              </Col>
              <Col md={4} sm={12} xs={12} className="mb-5">
                <p className="grey-color mb-0">Step 3</p>
                <h3 className="font-weight-bold" style={{ fontSize: '24px' }}>
                  Boost Your Performance
                </h3>
                <h4 style={{ fontSize: '22px' }}>
                  Maximize your profits with actionable feedback. We'll determine how you can improve 
                  based on your trading journal record.
                </h4>
              </Col>
            </Row>
            <div className="mt-3 pt-5 pb-5 text-center">
              <a href="https://www.ringgitlab.com/signup" className="mb-3">
                <h3 className="mb-0">
                  <Button className="primary-btn header-pill font-weight-bold">Sign Up For Free</Button>
                </h3>
              </a>
            </div>
          </Fade>
        </Container>
      </div>
    </>
  )
}

export default TradingJournal;