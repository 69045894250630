export abstract class StockDetails {
  id: string;
  market: string;
  updatedDateTime: string;
  stockLongName: string;
  stockName: string;
  stockCode: string;
  lastPrice: number;
  change: number;
  changePercentage: string;
  volume: number;
  transaction: number;
  buyValue: number;
  sellValue: number;
  buyMinusSellValue: number;
  vwap: number;
  ema5: number;
  volumeVMAChangePercentage20: string;
  lastForthQuarterNetProfit: number;
  averageBuyPrice: number;
  previousClosePrice: number;
  openPrice: number;
  highPrice: number;
  lowPrice: number;
  oneYearHighPrice: number;
  ema20: number;
  marketCap: string;
  eps: number;
  peRatio: number;
  rsi: string;
  sector: string;
  subSector: string;
  listedOn: string;
  shareIssued: string;
  shortSelling: string;
  dividend: number;
  dividendYield: string;
  fyeDate: string;
  quarterDate: string;
  lastForthQuarterDividend: number;
  lastForthQuarterDividendYield: string;
  lastForthQuarterEps: number;
  lastForthQuarterNab: number;
  lastForthQuarterPeRatio: number;
  lastForthQuarterRevenue: number;
  nab: number;
  netProfit: number;
  revenue: number;
}

export class StockDetailsModel extends StockDetails {
  json: any;

  constructor(json) {
    super();
    this.json = json;
  }
}