import React from 'react';
import { Row, Col } from 'reactstrap';
import Logo from '../../assets/ringgit-lab.png';

const LoadingSpinnerNoWord = () => {
  return (
    <Row>
      <Col className="align-self-center">
        <div className="spinner">
          <img src={Logo} className="w-100" alt="Ringgit Lab" />
        </div>
      </Col>
    </Row>
  );
};

export default LoadingSpinnerNoWord;