import React from 'react';
import {
  FormGroup,
  Input,
} from 'reactstrap';

interface Props {
  exitPrice: number | string;
  handleChange: (event: any) => void;
}
export const ExitPriceForm: React.FC<Props> = (props) => {
  return (
    <FormGroup>
      <Input
        type="number"
        name="exitPrice"
        id="exitPrice"
        value={props.exitPrice}
        onChange={props.handleChange}
      />
    </FormGroup>
  );
};