import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import PotentialStocksDashboard from '../potential/PotentialStocksDashboard';
import NonDisplayAllStockList from '../stock/NonDisplayAllStockList';
import Introduction from './Introduction';

const Homepage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return(
    <>
      <Helmet>
        <title>Ringgit Lab: Bursa Stock Analysis & Information</title>
        <meta name="description" 
          content="Ringgit Lab provide latest information, fundamental and technical analysis on the public listed stocks at Bursa Malaysia, to support your stock trading activities."
        />

        {/* Google / Search Engine Tags */}
        <meta itemProp="name" content="Ringgit Lab: Bursa Stock Analysis & Information" />
        <meta itemProp="description" content="Ringgit Lab provide latest information, fundamental and technical analysis on the public listed stocks at Bursa Malaysia, to support your stock trading activities." />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content="https://stocks.ringgitlab.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Ringgit Lab: Bursa Stock Analysis & Information" />
        <meta property="og:description" content="Ringgit Lab provide latest information, fundamental and technical analysis on the public listed stocks at Bursa Malaysia, to support your stock trading activities." />
        <meta property="og:image" content="https://stocks.ringgitlab.com/trading.png" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Ringgit Lab: Bursa Stock Analysis & Information" />
        <meta name="twitter:description" content="Ringgit Lab provide latest information, fundamental and technical analysis on the public listed stocks at Bursa Malaysia, to support your stock trading activities." />
        <meta name="twitter:image" content="https://stocks.ringgitlab.com/trading.png" />
      </Helmet>
      <Header />
      <Introduction />
      <PotentialStocksDashboard />
      <NonDisplayAllStockList />
      <Footer />
    </>
  )
}

export default Homepage;