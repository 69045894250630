import { CurrentStockDetailsInput } from '../graphql/API';

export abstract class StockDetailsHistory {
  id: string;
  screenerDate: string;
  updatedDateTime: string;
  screenedStocks: CurrentStockDetailsInput[];
}

export class StockDetailsHistoryModel extends StockDetailsHistory {
  json: any;

  constructor(json) {
    super();
    this.json = json;
  }
}