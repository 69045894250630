import React, { useEffect } from 'react';

const TradingViewAnalysisWidget = (props) => {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js`;
    script.async = true;
    script.innerHTML = JSON.stringify({
      "interval": "30m",
      "width": "100%",
      "isTransparent": false,
      "height": 450,
      "symbol": `MYX:${props.stockName}`,
      "showIntervalTabs": true,
      "locale": "en",
      "colorTheme": "light"
    })
    document.getElementById("analysisContainer").appendChild(script);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return(
    <div id="analysisContainer">
      <div className="tradingview-widget-container">
        <div className="tradingview-widget-container__widget">
          </div>
      </div>
    </div>
  );
}

export default TradingViewAnalysisWidget;