import React from 'react';
import {
  FormGroup,
  Input,
} from 'reactstrap';

interface Props {
  entryShare: number | string;
  handleChange: (event: any) => void;
}
export const EntryShareForm: React.FC<Props> = (props) => {
  return (
    <FormGroup>
      <Input
        type="number"
        name="entryShare"
        id="entryShare"
        value={props.entryShare}
        onChange={props.handleChange}
      />
    </FormGroup>
  );
};