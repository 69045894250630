/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_appsync_graphqlEndpoint": "https://oroa2hyyx5e55abz74lhf2v4aa.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cloud_logic_custom": [
        {
            "name": "ringgitlabpayment",
            "endpoint": "https://jt114toej5.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "ringgitlabslack",
            "endpoint": "https://ujz57b0vx3.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "ringgitlabdata",
            "endpoint": "https://pcy6z2ynvk.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-2:6a8cde0d-8875-4d89-9fa9-9b0d026743c2",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_MyMmUhtuD",
    "aws_user_pools_web_client_id": "2vduetalme6t4huq74a3l3eput",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "ringgitlab-storageservice-production",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
