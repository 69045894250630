/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSystemMaintenance = /* GraphQL */ `
  query GetSystemMaintenance($id: ID!) {
    getSystemMaintenance(id: $id) {
      id
      switch
      notes
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const listSystemMaintenances = /* GraphQL */ `
  query ListSystemMaintenances(
    $filter: ModelSystemMaintenanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemMaintenances(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        switch
        notes
        updatedDateTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      accountType
      changeStatus
      changedBy
      accountStatus
      registeredDate
      email
      firstName
      lastName
      username
      phoneNumber
      address
      identityCard
      clientCode
      cdsNumber
      broker
      totalCapital
      paperTradeCapital
      remisierCode
      commonCode
      registered
      verified
      subscriptionId
      subscriptionType
      subscriptionPriceId
      subscriptionExpiryDate
      subscriptionStatus
      platformSubscriptionId
      remisierSubscriptionId
      remisierLearningMaterialNumber
      stripeAccountId
      stripePortalUrl
      productId
      cdsClientId
      dailyEmail
      btstEmail
      newsletterEmail
      userType
      profileId
      userFeedback
      clientSurvey
      clientReferralId
      batch
      notes
      activation
      referralCode
      referredByCode
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountType
        changeStatus
        changedBy
        accountStatus
        registeredDate
        email
        firstName
        lastName
        username
        phoneNumber
        address
        identityCard
        clientCode
        cdsNumber
        broker
        totalCapital
        paperTradeCapital
        remisierCode
        commonCode
        registered
        verified
        subscriptionId
        subscriptionType
        subscriptionPriceId
        subscriptionExpiryDate
        subscriptionStatus
        platformSubscriptionId
        remisierSubscriptionId
        remisierLearningMaterialNumber
        stripeAccountId
        stripePortalUrl
        productId
        cdsClientId
        dailyEmail
        btstEmail
        newsletterEmail
        userType
        profileId
        userFeedback
        clientSurvey
        clientReferralId
        batch
        notes
        activation
        referralCode
        referredByCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAccountActivation = /* GraphQL */ `
  query GetAccountActivation($id: ID!) {
    getAccountActivation(id: $id) {
      id
      userId
      fullName
      email
      remisierCode
      userType
      accountStatus
      emailNotification
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const listAccountActivations = /* GraphQL */ `
  query ListAccountActivations(
    $filter: ModelAccountActivationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountActivations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        fullName
        email
        remisierCode
        userType
        accountStatus
        emailNotification
        createdDateTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReferralActivation = /* GraphQL */ `
  query GetReferralActivation($id: ID!) {
    getReferralActivation(id: $id) {
      id
      userId
      referralId
      fullName
      email
      referralCode
      createdAt
      updatedAt
    }
  }
`;
export const listReferralActivations = /* GraphQL */ `
  query ListReferralActivations(
    $filter: ModelReferralActivationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReferralActivations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        referralId
        fullName
        email
        referralCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClientReferral = /* GraphQL */ `
  query GetClientReferral($id: ID!) {
    getClientReferral(id: $id) {
      id
      userId
      referralUserId
      fullName
      phoneNumber
      email
      numberOfReferral
      referralPoints
      bankName
      bankAccountType
      bankAccountNumber
      withdrawalRequestDate
      withdrawalStatus
      totalWithdrawal
      withdrawal {
        price
        date
      }
      adminNotes
      referralCode
      activation
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const listClientReferrals = /* GraphQL */ `
  query ListClientReferrals(
    $filter: ModelClientReferralFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientReferrals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        referralUserId
        fullName
        phoneNumber
        email
        numberOfReferral
        referralPoints
        bankName
        bankAccountType
        bankAccountNumber
        withdrawalRequestDate
        withdrawalStatus
        totalWithdrawal
        withdrawal {
          price
          date
        }
        adminNotes
        referralCode
        activation
        updatedDateTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRemisierClientLimit = /* GraphQL */ `
  query GetRemisierClientLimit($id: ID!) {
    getRemisierClientLimit(id: $id) {
      id
      registeredDate
      email
      firstName
      lastName
      phoneNumber
      remisierCode
      createdAt
      updatedAt
    }
  }
`;
export const listRemisierClientLimits = /* GraphQL */ `
  query ListRemisierClientLimits(
    $filter: ModelRemisierClientLimitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRemisierClientLimits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        registeredDate
        email
        firstName
        lastName
        phoneNumber
        remisierCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCdsClient = /* GraphQL */ `
  query GetCdsClient($id: ID!) {
    getCdsClient(id: $id) {
      id
      clientCode
      fullName
      identityCard
      phoneNumber
      email
      registeredDate
      accountType
      applicationStatus
      contactStatus
      contactChannel
      ringgitLabTelegram
      ringgitLabRegistration
      notes
      userId
      remisierCode
      createdAt
      updatedAt
    }
  }
`;
export const listCdsClients = /* GraphQL */ `
  query ListCdsClients(
    $filter: ModelCdsClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCdsClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientCode
        fullName
        identityCard
        phoneNumber
        email
        registeredDate
        accountType
        applicationStatus
        contactStatus
        contactChannel
        ringgitLabTelegram
        ringgitLabRegistration
        notes
        userId
        remisierCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTradingOrder = /* GraphQL */ `
  query GetTradingOrder($id: ID!) {
    getTradingOrder(id: $id) {
      id
      clientCode
      fullName
      stockName
      price
      type
      share
      total
      brokerageFee
      tradingStatusDate
      createdAt
      updatedAt
    }
  }
`;
export const listTradingOrders = /* GraphQL */ `
  query ListTradingOrders(
    $filter: ModelTradingOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTradingOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientCode
        fullName
        stockName
        price
        type
        share
        total
        brokerageFee
        tradingStatusDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPlatformSubscription = /* GraphQL */ `
  query GetPlatformSubscription($id: ID!) {
    getPlatformSubscription(id: $id) {
      id
      fullName
      email
      phoneNumber
      subscriptionUserId
      subscriptionId
      subscriptionPrice
      subscriptionPriceId
      subscriptionExpiryDate
      subscriptionStatus
      paymentMethod
      invoiceId
      invoiceUrl
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const listPlatformSubscriptions = /* GraphQL */ `
  query ListPlatformSubscriptions(
    $filter: ModelPlatformSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlatformSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fullName
        email
        phoneNumber
        subscriptionUserId
        subscriptionId
        subscriptionPrice
        subscriptionPriceId
        subscriptionExpiryDate
        subscriptionStatus
        paymentMethod
        invoiceId
        invoiceUrl
        updatedDateTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRemisierSubscription = /* GraphQL */ `
  query GetRemisierSubscription($id: ID!) {
    getRemisierSubscription(id: $id) {
      id
      fullName
      email
      phoneNumber
      remisierCode
      cmsrlNumber
      cmsrlExpiryDate
      broker
      clientNumber
      subscriptionUserId
      subscriptionId
      subscriptionPrice
      subscriptionPriceId
      subscriptionExpiryDate
      subscriptionStatus
      subscriptionType
      paymentMethod
      totalPayments {
        price
        date
      }
      invoiceId
      invoiceUrl
      profileId
      changeStatus
      changeSubscription
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const listRemisierSubscriptions = /* GraphQL */ `
  query ListRemisierSubscriptions(
    $filter: ModelRemisierSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRemisierSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fullName
        email
        phoneNumber
        remisierCode
        cmsrlNumber
        cmsrlExpiryDate
        broker
        clientNumber
        subscriptionUserId
        subscriptionId
        subscriptionPrice
        subscriptionPriceId
        subscriptionExpiryDate
        subscriptionStatus
        subscriptionType
        paymentMethod
        totalPayments {
          price
          date
        }
        invoiceId
        invoiceUrl
        profileId
        changeStatus
        changeSubscription
        updatedDateTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRemisierProfile = /* GraphQL */ `
  query GetRemisierProfile($id: ID!) {
    getRemisierProfile(id: $id) {
      id
      fullName
      remisierUserId
      remisierCode
      published
      cmsrlNumber
      broker
      profileImage {
        bucket
        region
        key
      }
      contactNumber
      email
      address
      introduction
      description
      website
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const listRemisierProfiles = /* GraphQL */ `
  query ListRemisierProfiles(
    $filter: ModelRemisierProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRemisierProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fullName
        remisierUserId
        remisierCode
        published
        cmsrlNumber
        broker
        profileImage {
          bucket
          region
          key
        }
        contactNumber
        email
        address
        introduction
        description
        website
        updatedDateTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBroker = /* GraphQL */ `
  query GetBroker($id: ID!) {
    getBroker(id: $id) {
      id
      brokerName
      brokerOfficialName
      phoneNumber
      email
      website
      personInCharge
      address
      remisierNumber
      createdAt
      updatedAt
    }
  }
`;
export const listBrokers = /* GraphQL */ `
  query ListBrokers(
    $filter: ModelBrokerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrokers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        brokerName
        brokerOfficialName
        phoneNumber
        email
        website
        personInCharge
        address
        remisierNumber
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVerification = /* GraphQL */ `
  query GetVerification($id: ID!) {
    getVerification(id: $id) {
      id
      verificationUserId
      verificationFile {
        bucket
        region
        key
      }
      status
      createdAt
      updatedAt
    }
  }
`;
export const listVerifications = /* GraphQL */ `
  query ListVerifications(
    $filter: ModelVerificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVerifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        verificationUserId
        verificationFile {
          bucket
          region
          key
        }
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLatestUpdate = /* GraphQL */ `
  query GetLatestUpdate($id: ID!) {
    getLatestUpdate(id: $id) {
      id
      postedDate
      title
      description
      updateImage {
        bucket
        region
        key
      }
      remisierCode
      broker
      userId
      createdAt
      updatedAt
    }
  }
`;
export const listLatestUpdates = /* GraphQL */ `
  query ListLatestUpdates(
    $filter: ModelLatestUpdateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLatestUpdates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postedDate
        title
        description
        updateImage {
          bucket
          region
          key
        }
        remisierCode
        broker
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTradingJournal = /* GraphQL */ `
  query GetTradingJournal($id: ID!) {
    getTradingJournal(id: $id) {
      id
      tradingJournalUserId
      stockName
      stockCode
      sector
      subSector
      status
      portfolio
      entries {
        date
        price
        share
        net
      }
      exits {
        date
        price
        share
        net
      }
      returnPrice
      returnPercentage
      side
      strategy
      otherStrategy
      mistake
      otherMistake
      cutLoss
      targetPrice
      notes
      createdAt
      updatedAt
    }
  }
`;
export const listTradingJournals = /* GraphQL */ `
  query ListTradingJournals(
    $filter: ModelTradingJournalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTradingJournals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tradingJournalUserId
        stockName
        stockCode
        sector
        subSector
        status
        portfolio
        entries {
          date
          price
          share
          net
        }
        exits {
          date
          price
          share
          net
        }
        returnPrice
        returnPercentage
        side
        strategy
        otherStrategy
        mistake
        otherMistake
        cutLoss
        targetPrice
        notes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJournal = /* GraphQL */ `
  query GetJournal($id: ID!) {
    getJournal(id: $id) {
      id
      journalUserId
      journalList {
        id
        portfolio
        name
        date
        price
        share
        net
        type
        side
        strategy
        otherStrategy
        mistake
        otherMistake
        cutLoss
        targetPrice
        notes
        updatedDateTime
      }
      stockName
      stockCode
      sector
      returnPrice
      returnPercentage
      notes
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const listJournals = /* GraphQL */ `
  query ListJournals(
    $filter: ModelJournalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJournals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        journalUserId
        journalList {
          id
          portfolio
          name
          date
          price
          share
          net
          type
          side
          strategy
          otherStrategy
          mistake
          otherMistake
          cutLoss
          targetPrice
          notes
          updatedDateTime
        }
        stockName
        stockCode
        sector
        returnPrice
        returnPercentage
        notes
        updatedDateTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPortfolio = /* GraphQL */ `
  query GetPortfolio($id: ID!) {
    getPortfolio(id: $id) {
      id
      portfolioUserId
      fullName
      accountStatus
      phoneNumber
      totalCapital
      capitalPercentage
      totalNetReturn
      totalNetReturnPercentage
      totalPaidCommissionFee
      totalWithdrawal
      withdrawal {
        price
        date
      }
      portfolioList {
        id
        stockName
        status
        entryDate
        entryPrice
        entryShare
        entryGross
        entryNet
        exitDate
        exitPrice
        exitShare
        exitGross
        exitNet
        returnPrice
        returnPercentage
        commissionFee
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPortfolios = /* GraphQL */ `
  query ListPortfolios(
    $filter: ModelPortfolioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPortfolios(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        portfolioUserId
        fullName
        accountStatus
        phoneNumber
        totalCapital
        capitalPercentage
        totalNetReturn
        totalNetReturnPercentage
        totalPaidCommissionFee
        totalWithdrawal
        withdrawal {
          price
          date
        }
        portfolioList {
          id
          stockName
          status
          entryDate
          entryPrice
          entryShare
          entryGross
          entryNet
          exitDate
          exitPrice
          exitShare
          exitGross
          exitNet
          returnPrice
          returnPercentage
          commissionFee
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPaperTrade = /* GraphQL */ `
  query GetPaperTrade($id: ID!) {
    getPaperTrade(id: $id) {
      id
      paperTradeUserId
      stockName
      stockCode
      status
      lastPrice
      entryDate
      entryPrice
      entryShare
      exitDate
      exitPrice
      exitShare
      returnPrice
      returnPercentage
      side
      strategy
      otherStrategy
      mistake
      otherMistake
      cutLoss
      targetPrice
      notes
      createdAt
      updatedAt
    }
  }
`;
export const listPaperTrades = /* GraphQL */ `
  query ListPaperTrades(
    $filter: ModelPaperTradeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaperTrades(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        paperTradeUserId
        stockName
        stockCode
        status
        lastPrice
        entryDate
        entryPrice
        entryShare
        exitDate
        exitPrice
        exitShare
        returnPrice
        returnPercentage
        side
        strategy
        otherStrategy
        mistake
        otherMistake
        cutLoss
        targetPrice
        notes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStockNews = /* GraphQL */ `
  query GetStockNews($id: ID!) {
    getStockNews(id: $id) {
      id
      apiId
      stockId
      stockName
      link
      editor
      title
      description
      imageLink
      publishedDateTime
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const listStockNewss = /* GraphQL */ `
  query ListStockNewss(
    $filter: ModelStockNewsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStockNewss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        apiId
        stockId
        stockName
        link
        editor
        title
        description
        imageLink
        publishedDateTime
        createdDateTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStockDetails = /* GraphQL */ `
  query GetStockDetails($id: ID!) {
    getStockDetails(id: $id) {
      id
      market
      updatedDateTime
      stockLongName
      stockName
      stockCode
      lastPrice
      change
      changePercentage
      volume
      transaction
      buyValue
      sellValue
      buyMinusSellValue
      vwap
      ema5
      volumeVMAChangePercentage20
      lastForthQuarterNetProfit
      averageBuyPrice
      previousClosePrice
      openPrice
      highPrice
      lowPrice
      oneYearHighPrice
      ema20
      marketCap
      eps
      peRatio
      rsi
      sector
      subSector
      listedOn
      shareIssued
      shortSelling
      dividend
      dividendYield
      fyeDate
      quarterDate
      lastForthQuarterDividend
      lastForthQuarterDividendYield
      lastForthQuarterEps
      lastForthQuarterNab
      lastForthQuarterPeRatio
      lastForthQuarterRevenue
      nab
      netProfit
      revenue
      createdAt
      updatedAt
    }
  }
`;
export const listStockDetailss = /* GraphQL */ `
  query ListStockDetailss(
    $filter: ModelStockDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStockDetailss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        market
        updatedDateTime
        stockLongName
        stockName
        stockCode
        lastPrice
        change
        changePercentage
        volume
        transaction
        buyValue
        sellValue
        buyMinusSellValue
        vwap
        ema5
        volumeVMAChangePercentage20
        lastForthQuarterNetProfit
        averageBuyPrice
        previousClosePrice
        openPrice
        highPrice
        lowPrice
        oneYearHighPrice
        ema20
        marketCap
        eps
        peRatio
        rsi
        sector
        subSector
        listedOn
        shareIssued
        shortSelling
        dividend
        dividendYield
        fyeDate
        quarterDate
        lastForthQuarterDividend
        lastForthQuarterDividendYield
        lastForthQuarterEps
        lastForthQuarterNab
        lastForthQuarterPeRatio
        lastForthQuarterRevenue
        nab
        netProfit
        revenue
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStockDetailsHistory = /* GraphQL */ `
  query GetStockDetailsHistory($id: ID!) {
    getStockDetailsHistory(id: $id) {
      id
      screenerDate
      updatedDateTime
      screenedStocks {
        id
        stockId
        updatedDateTime
        type
        market
        stockName
        stockCode
        screenedPrice
        stopLossPrice
        firstProfitPrice
        secondProfitPrice
        thirdProfitPrice
        lastPrice
        change
        changePercentage
        volume
        transaction
        buyValue
        sellValue
        buyMinusSellValue
        vwap
        ema5
        volumeVMAChangePercentage20
        lastForthQuarterNetProfit
        averageBuyPrice
        previousClosePrice
        openPrice
        highPrice
        lowPrice
        oneYearHighPrice
        ema20
        marketCap
        eps
        peRatio
        rsi
        sector
        subSector
        listedOn
        shareIssued
        shortSelling
        dividend
        dividendYield
        fyeDate
        quarterDate
        lastForthQuarterDividend
        lastForthQuarterDividendYield
        lastForthQuarterEps
        lastForthQuarterNab
        lastForthQuarterPeRatio
        lastForthQuarterRevenue
        nab
        netProfit
        revenue
        remisierCode
      }
      createdAt
      updatedAt
    }
  }
`;
export const listStockDetailsHistorys = /* GraphQL */ `
  query ListStockDetailsHistorys(
    $filter: ModelStockDetailsHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStockDetailsHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        screenerDate
        updatedDateTime
        screenedStocks {
          id
          stockId
          updatedDateTime
          type
          market
          stockName
          stockCode
          screenedPrice
          stopLossPrice
          firstProfitPrice
          secondProfitPrice
          thirdProfitPrice
          lastPrice
          change
          changePercentage
          volume
          transaction
          buyValue
          sellValue
          buyMinusSellValue
          vwap
          ema5
          volumeVMAChangePercentage20
          lastForthQuarterNetProfit
          averageBuyPrice
          previousClosePrice
          openPrice
          highPrice
          lowPrice
          oneYearHighPrice
          ema20
          marketCap
          eps
          peRatio
          rsi
          sector
          subSector
          listedOn
          shareIssued
          shortSelling
          dividend
          dividendYield
          fyeDate
          quarterDate
          lastForthQuarterDividend
          lastForthQuarterDividendYield
          lastForthQuarterEps
          lastForthQuarterNab
          lastForthQuarterPeRatio
          lastForthQuarterRevenue
          nab
          netProfit
          revenue
          remisierCode
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStockDetailsHistoryTest = /* GraphQL */ `
  query GetStockDetailsHistoryTest($id: ID!) {
    getStockDetailsHistoryTest(id: $id) {
      id
      screenerDate
      updatedDateTime
      screenedStocks {
        id
        stockId
        updatedDateTime
        type
        market
        stockName
        stockCode
        screenedPrice
        stopLossPrice
        firstProfitPrice
        secondProfitPrice
        thirdProfitPrice
        lastPrice
        change
        changePercentage
        volume
        transaction
        buyValue
        sellValue
        buyMinusSellValue
        vwap
        ema5
        volumeVMAChangePercentage20
        lastForthQuarterNetProfit
        averageBuyPrice
        previousClosePrice
        openPrice
        highPrice
        lowPrice
        oneYearHighPrice
        ema20
        marketCap
        eps
        peRatio
        rsi
        sector
        subSector
        listedOn
        shareIssued
        shortSelling
        dividend
        dividendYield
        fyeDate
        quarterDate
        lastForthQuarterDividend
        lastForthQuarterDividendYield
        lastForthQuarterEps
        lastForthQuarterNab
        lastForthQuarterPeRatio
        lastForthQuarterRevenue
        nab
        netProfit
        revenue
        remisierCode
      }
      createdAt
      updatedAt
    }
  }
`;
export const listStockDetailsHistoryTests = /* GraphQL */ `
  query ListStockDetailsHistoryTests(
    $filter: ModelStockDetailsHistoryTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStockDetailsHistoryTests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        screenerDate
        updatedDateTime
        screenedStocks {
          id
          stockId
          updatedDateTime
          type
          market
          stockName
          stockCode
          screenedPrice
          stopLossPrice
          firstProfitPrice
          secondProfitPrice
          thirdProfitPrice
          lastPrice
          change
          changePercentage
          volume
          transaction
          buyValue
          sellValue
          buyMinusSellValue
          vwap
          ema5
          volumeVMAChangePercentage20
          lastForthQuarterNetProfit
          averageBuyPrice
          previousClosePrice
          openPrice
          highPrice
          lowPrice
          oneYearHighPrice
          ema20
          marketCap
          eps
          peRatio
          rsi
          sector
          subSector
          listedOn
          shareIssued
          shortSelling
          dividend
          dividendYield
          fyeDate
          quarterDate
          lastForthQuarterDividend
          lastForthQuarterDividendYield
          lastForthQuarterEps
          lastForthQuarterNab
          lastForthQuarterPeRatio
          lastForthQuarterRevenue
          nab
          netProfit
          revenue
          remisierCode
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWatchList = /* GraphQL */ `
  query GetWatchList($id: ID!) {
    getWatchList(id: $id) {
      id
      watchListUserId
      stockDetailsId
      stockName
      stockCode
      lastPrice
      entryPrice
      stopLossPrice
      firstProfitPrice
      secondProfitPrice
      thirdProfitPrice
      status
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const listWatchLists = /* GraphQL */ `
  query ListWatchLists(
    $filter: ModelWatchListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWatchLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        watchListUserId
        stockDetailsId
        stockName
        stockCode
        lastPrice
        entryPrice
        stopLossPrice
        firstProfitPrice
        secondProfitPrice
        thirdProfitPrice
        status
        createdDateTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDailyEmailNotification = /* GraphQL */ `
  query GetDailyEmailNotification($id: ID!) {
    getDailyEmailNotification(id: $id) {
      id
      notes
      screenerDate
      screenedStocks {
        id
        stockId
        updatedDateTime
        type
        market
        stockName
        stockCode
        screenedPrice
        stopLossPrice
        firstProfitPrice
        secondProfitPrice
        thirdProfitPrice
        lastPrice
        change
        changePercentage
        volume
        transaction
        buyValue
        sellValue
        buyMinusSellValue
        vwap
        ema5
        volumeVMAChangePercentage20
        lastForthQuarterNetProfit
        averageBuyPrice
        previousClosePrice
        openPrice
        highPrice
        lowPrice
        oneYearHighPrice
        ema20
        marketCap
        eps
        peRatio
        rsi
        sector
        subSector
        listedOn
        shareIssued
        shortSelling
        dividend
        dividendYield
        fyeDate
        quarterDate
        lastForthQuarterDividend
        lastForthQuarterDividendYield
        lastForthQuarterEps
        lastForthQuarterNab
        lastForthQuarterPeRatio
        lastForthQuarterRevenue
        nab
        netProfit
        revenue
        remisierCode
      }
      resultDate
      stockResults {
        id
        stockId
        updatedDateTime
        type
        market
        stockName
        stockCode
        screenedPrice
        stopLossPrice
        firstProfitPrice
        secondProfitPrice
        thirdProfitPrice
        lastPrice
        change
        changePercentage
        volume
        transaction
        buyValue
        sellValue
        buyMinusSellValue
        vwap
        ema5
        volumeVMAChangePercentage20
        lastForthQuarterNetProfit
        averageBuyPrice
        previousClosePrice
        openPrice
        highPrice
        lowPrice
        oneYearHighPrice
        ema20
        marketCap
        eps
        peRatio
        rsi
        sector
        subSector
        listedOn
        shareIssued
        shortSelling
        dividend
        dividendYield
        fyeDate
        quarterDate
        lastForthQuarterDividend
        lastForthQuarterDividendYield
        lastForthQuarterEps
        lastForthQuarterNab
        lastForthQuarterPeRatio
        lastForthQuarterRevenue
        nab
        netProfit
        revenue
        remisierCode
      }
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const listDailyEmailNotifications = /* GraphQL */ `
  query ListDailyEmailNotifications(
    $filter: ModelDailyEmailNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailyEmailNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        notes
        screenerDate
        screenedStocks {
          id
          stockId
          updatedDateTime
          type
          market
          stockName
          stockCode
          screenedPrice
          stopLossPrice
          firstProfitPrice
          secondProfitPrice
          thirdProfitPrice
          lastPrice
          change
          changePercentage
          volume
          transaction
          buyValue
          sellValue
          buyMinusSellValue
          vwap
          ema5
          volumeVMAChangePercentage20
          lastForthQuarterNetProfit
          averageBuyPrice
          previousClosePrice
          openPrice
          highPrice
          lowPrice
          oneYearHighPrice
          ema20
          marketCap
          eps
          peRatio
          rsi
          sector
          subSector
          listedOn
          shareIssued
          shortSelling
          dividend
          dividendYield
          fyeDate
          quarterDate
          lastForthQuarterDividend
          lastForthQuarterDividendYield
          lastForthQuarterEps
          lastForthQuarterNab
          lastForthQuarterPeRatio
          lastForthQuarterRevenue
          nab
          netProfit
          revenue
          remisierCode
        }
        resultDate
        stockResults {
          id
          stockId
          updatedDateTime
          type
          market
          stockName
          stockCode
          screenedPrice
          stopLossPrice
          firstProfitPrice
          secondProfitPrice
          thirdProfitPrice
          lastPrice
          change
          changePercentage
          volume
          transaction
          buyValue
          sellValue
          buyMinusSellValue
          vwap
          ema5
          volumeVMAChangePercentage20
          lastForthQuarterNetProfit
          averageBuyPrice
          previousClosePrice
          openPrice
          highPrice
          lowPrice
          oneYearHighPrice
          ema20
          marketCap
          eps
          peRatio
          rsi
          sector
          subSector
          listedOn
          shareIssued
          shortSelling
          dividend
          dividendYield
          fyeDate
          quarterDate
          lastForthQuarterDividend
          lastForthQuarterDividendYield
          lastForthQuarterEps
          lastForthQuarterNab
          lastForthQuarterPeRatio
          lastForthQuarterRevenue
          nab
          netProfit
          revenue
          remisierCode
        }
        updatedDateTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBtstEmailNotification = /* GraphQL */ `
  query GetBtstEmailNotification($id: ID!) {
    getBTSTEmailNotification(id: $id) {
      id
      screenerDate
      screenedStocks {
        id
        stockId
        updatedDateTime
        type
        market
        stockName
        stockCode
        screenedPrice
        stopLossPrice
        firstProfitPrice
        secondProfitPrice
        thirdProfitPrice
        lastPrice
        change
        changePercentage
        volume
        transaction
        buyValue
        sellValue
        buyMinusSellValue
        vwap
        ema5
        volumeVMAChangePercentage20
        lastForthQuarterNetProfit
        averageBuyPrice
        previousClosePrice
        openPrice
        highPrice
        lowPrice
        oneYearHighPrice
        ema20
        marketCap
        eps
        peRatio
        rsi
        sector
        subSector
        listedOn
        shareIssued
        shortSelling
        dividend
        dividendYield
        fyeDate
        quarterDate
        lastForthQuarterDividend
        lastForthQuarterDividendYield
        lastForthQuarterEps
        lastForthQuarterNab
        lastForthQuarterPeRatio
        lastForthQuarterRevenue
        nab
        netProfit
        revenue
        remisierCode
      }
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const listBtstEmailNotifications = /* GraphQL */ `
  query ListBtstEmailNotifications(
    $filter: ModelBTSTEmailNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBTSTEmailNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        screenerDate
        screenedStocks {
          id
          stockId
          updatedDateTime
          type
          market
          stockName
          stockCode
          screenedPrice
          stopLossPrice
          firstProfitPrice
          secondProfitPrice
          thirdProfitPrice
          lastPrice
          change
          changePercentage
          volume
          transaction
          buyValue
          sellValue
          buyMinusSellValue
          vwap
          ema5
          volumeVMAChangePercentage20
          lastForthQuarterNetProfit
          averageBuyPrice
          previousClosePrice
          openPrice
          highPrice
          lowPrice
          oneYearHighPrice
          ema20
          marketCap
          eps
          peRatio
          rsi
          sector
          subSector
          listedOn
          shareIssued
          shortSelling
          dividend
          dividendYield
          fyeDate
          quarterDate
          lastForthQuarterDividend
          lastForthQuarterDividendYield
          lastForthQuarterEps
          lastForthQuarterNab
          lastForthQuarterPeRatio
          lastForthQuarterRevenue
          nab
          netProfit
          revenue
          remisierCode
        }
        updatedDateTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLearningMaterial = /* GraphQL */ `
  query GetLearningMaterial($id: ID!) {
    getLearningMaterial(id: $id) {
      id
      learningMaterialUserId
      remisierCode
      commonCode
      title
      description
      updatedDateTime
      contents {
        id
        learningContentsUserId
        title
        description
        remisierCode
        file {
          bucket
          region
          key
        }
        type
        updatedDateTime
      }
      createdAt
      updatedAt
    }
  }
`;
export const listLearningMaterials = /* GraphQL */ `
  query ListLearningMaterials(
    $filter: ModelLearningMaterialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLearningMaterials(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        learningMaterialUserId
        remisierCode
        commonCode
        title
        description
        updatedDateTime
        contents {
          id
          learningContentsUserId
          title
          description
          remisierCode
          type
          updatedDateTime
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getForum = /* GraphQL */ `
  query GetForum($id: ID!) {
    getForum(id: $id) {
      id
      forumOwnerId
      remisierCode
      commonCode
      fullName
      title
      description
      file {
        bucket
        region
        key
      }
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const listForums = /* GraphQL */ `
  query ListForums(
    $filter: ModelForumFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForums(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        forumOwnerId
        remisierCode
        commonCode
        fullName
        title
        description
        file {
          bucket
          region
          key
        }
        createdDateTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getForumThread = /* GraphQL */ `
  query GetForumThread($id: ID!) {
    getForumThread(id: $id) {
      id
      forumId
      forumOwnerId
      userId
      username
      description
      file {
        bucket
        region
        key
      }
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const listForumThreads = /* GraphQL */ `
  query ListForumThreads(
    $filter: ModelForumThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForumThreads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        forumId
        forumOwnerId
        userId
        username
        description
        file {
          bucket
          region
          key
        }
        createdDateTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNewsletterEmail = /* GraphQL */ `
  query GetNewsletterEmail($id: ID!) {
    getNewsletterEmail(id: $id) {
      id
      title
      description
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const listNewsletterEmails = /* GraphQL */ `
  query ListNewsletterEmails(
    $filter: ModelNewsletterEmailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewsletterEmails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        createdDateTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTechnicalView = /* GraphQL */ `
  query GetTechnicalView($id: ID!) {
    getTechnicalView(id: $id) {
      id
      stockId
      stockName
      userId
      fullName
      description
      file {
        bucket
        region
        key
      }
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const listTechnicalViews = /* GraphQL */ `
  query ListTechnicalViews(
    $filter: ModelTechnicalViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTechnicalViews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stockId
        stockName
        userId
        fullName
        description
        file {
          bucket
          region
          key
        }
        createdDateTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRequestUpdate = /* GraphQL */ `
  query GetRequestUpdate($id: ID!) {
    getRequestUpdate(id: $id) {
      id
      requestUpdateUserId
      sendEmail
      fullName
      clientCode
      remisierCode
      commonCode
      email
      phoneNumber
      identityCardNumber
      currentEmail
      currentAddress
      occupation
      companyName
      annualIncome
      yearsInBusiness
      maritalStatus
      spouseName
      spouseIdentityCardNumber
      spousePhoneNumber
      spouseOccupation
      spouseCompanyName
      spouseAnnualIncome
      spouseYearsInBusiness
      subject
      description
      status
      notes
      notesToUser
      createdDateTime
      cdsEmail
      cdsAddress
      cdsPhoneNumber
      cdsAccountType
      cdsBankAccountName
      cdsBankAccount
      file {
        bucket
        region
        key
      }
      assigneeUserId
      assigneeFullName
      assigneeNotes
      createdAt
      updatedAt
    }
  }
`;
export const listRequestUpdates = /* GraphQL */ `
  query ListRequestUpdates(
    $filter: ModelRequestUpdateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequestUpdates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        requestUpdateUserId
        sendEmail
        fullName
        clientCode
        remisierCode
        commonCode
        email
        phoneNumber
        identityCardNumber
        currentEmail
        currentAddress
        occupation
        companyName
        annualIncome
        yearsInBusiness
        maritalStatus
        spouseName
        spouseIdentityCardNumber
        spousePhoneNumber
        spouseOccupation
        spouseCompanyName
        spouseAnnualIncome
        spouseYearsInBusiness
        subject
        description
        status
        notes
        notesToUser
        createdDateTime
        cdsEmail
        cdsAddress
        cdsPhoneNumber
        cdsAccountType
        cdsBankAccountName
        cdsBankAccount
        file {
          bucket
          region
          key
        }
        assigneeUserId
        assigneeFullName
        assigneeNotes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRequestForm = /* GraphQL */ `
  query GetRequestForm($id: ID!) {
    getRequestForm(id: $id) {
      id
      remisierCode
      userId
      broker
      file {
        bucket
        region
        key
      }
      title
      description
      tags
      uploadedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const listRequestForms = /* GraphQL */ `
  query ListRequestForms(
    $filter: ModelRequestFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequestForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        remisierCode
        userId
        broker
        file {
          bucket
          region
          key
        }
        title
        description
        tags
        uploadedDateTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestionnaire = /* GraphQL */ `
  query GetQuestionnaire($id: ID!) {
    getQuestionnaire(id: $id) {
      id
      userId
      fullName
      clientCode
      identityCardNumber
      email
      phoneNumber
      changeRemisier
      survey
      registered
      notes
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const listQuestionnaires = /* GraphQL */ `
  query ListQuestionnaires(
    $filter: ModelQuestionnaireFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionnaires(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        fullName
        clientCode
        identityCardNumber
        email
        phoneNumber
        changeRemisier
        survey
        registered
        notes
        createdDateTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFeedback = /* GraphQL */ `
  query GetFeedback($id: ID!) {
    getFeedback(id: $id) {
      id
      userId
      fullName
      email
      phoneNumber
      ratingValue
      screenerValue
      firstTopFeature
      secondTopFeature
      thirdTopFeature
      reachable
      testimonial
      feedback
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const listFeedbacks = /* GraphQL */ `
  query ListFeedbacks(
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        fullName
        email
        phoneNumber
        ratingValue
        screenerValue
        firstTopFeature
        secondTopFeature
        thirdTopFeature
        reachable
        testimonial
        feedback
        createdDateTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClientSurvey = /* GraphQL */ `
  query GetClientSurvey($id: ID!) {
    getClientSurvey(id: $id) {
      id
      userId
      investmentTerm
      riskTolerance
      tradingExperience
      investmentObjective
      mainSource
      notes
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const listClientSurveys = /* GraphQL */ `
  query ListClientSurveys(
    $filter: ModelClientSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientSurveys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        investmentTerm
        riskTolerance
        tradingExperience
        investmentObjective
        mainSource
        notes
        createdDateTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
