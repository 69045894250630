import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import PaperTrading from './PaperTrading';

const PaperTradingPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return(
    <>
      <Helmet>
        <title>Ringgit Lab: Paper Trading</title>
        <meta name="description" 
          content="Scared to pull the trigger on that first stock purchase? Looking for a way to start practicing before you put your actual, hard-earned money on the line? Start paper trading with Ringgit Lab."
        />

        {/* Google / Search Engine Tags */}
        <meta itemProp="name" content="Ringgit Lab: Paper Trading" />
        <meta itemProp="description" content="Scared to pull the trigger on that first stock purchase? Looking for a way to start practicing before you put your actual, hard-earned money on the line? Start paper trading with Ringgit Lab." />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content="https://stocks.ringgitlab.com/paper-trading" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Ringgit Lab: Paper Trading" />
        <meta property="og:description" content="Scared to pull the trigger on that first stock purchase? Looking for a way to start practicing before you put your actual, hard-earned money on the line? Start paper trading with Ringgit Lab." />
        <meta property="og:image" content="https://stocks.ringgitlab.com/paper-trading-dashboard.png" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Ringgit Lab: Paper Trading" />
        <meta name="twitter:description" content="Scared to pull the trigger on that first stock purchase? Looking for a way to start practicing before you put your actual, hard-earned money on the line? Start paper trading with Ringgit Lab." />
        <meta name="twitter:image" content="https://stocks.ringgitlab.com/paper-trading-dashboard.png" />
      </Helmet>
      <Header />
      <PaperTrading />
      <Footer />
    </>
  )
}

export default PaperTradingPage;