import React from 'react';
import { Route } from 'react-router-dom';
import PaperTradingPage from '../components/landing/PaperTradingPage';
import StockScreenerPage from '../components/landing/StockScreenerPage';
import TradingJournalPage from '../components/landing/TradingJournalPage';
import Homepage from '../components/landing/Homepage';
import StockDashboard from '../components/stock/StockDashboard';
import { StockDetailsPage } from '../components/stock/StockDetailsPage';
import CalculatorDashboard from '../components/calculator/CalculatorDashboard';
import { StockDetailsStaticPage } from '../components/stock/StockDetailsStaticPage';

const NoAuthRoutes = () => {
  return (
    <>
      <Route exact path="/" component={Homepage} />
      <Route exact path="/search" component={StockDashboard} />
      <Route exact path="/search/:id" component={StockDetailsPage} />
      <Route exact path="/search/MYX/:id" component={StockDetailsStaticPage} />
      <Route exact path="/paper-trading" component={PaperTradingPage} />
      <Route exact path="/trading-journal" component={TradingJournalPage} />
      <Route exact path="/screener" component={StockScreenerPage} />
      <Route exact path="/brokerage-calculator" component={CalculatorDashboard} />
    </>
  );
};

export default NoAuthRoutes;