import React, { useEffect, useState } from 'react';
import { CurrentStockDetailsInput } from '../../graphql/API';
import { Button, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import GraphqlClient from '../../utils/GraphqlClient';
import { StockDetailsModel } from '../../models/StockDetails';
import { modelFactory } from '../../models/ModelFactory';

type Props = {
  stock: CurrentStockDetailsInput;
}

export const PotentialStocksListTable = ({ stock }: Props) => {
  const [errors, setErrors] = useState([]);
  const [stockDetails, setStockDetails] = useState<StockDetailsModel>(null);

  useEffect(() => {
    try {
      (async () => {
        const stockName = stock.stockName;
        const stockFilter = {
          stockName: { eq: stockName }
        };
        const results = await Promise.all([
          GraphqlClient.listStockDetailss(stockFilter)
        ]);
        const stockDetails = results[0].map(i =>
          modelFactory<StockDetailsModel>(i, StockDetailsModel)
        );
        setStockDetails(stockDetails[0]);
      })();
    } catch (err) {
      const newError = err.errors ? err.errors.map(error => error.message) : err;
      setErrors([...errors, newError]);
    }
  }, [stock, errors]);

  return(
    <>
      <tr>
        <td className="font-weight-bold text-center">{stock.stockName}</td>
        {stockDetails && (stockDetails.stockName === stock.stockName) && (
          <td className="text-center">{stockDetails.lastPrice.toFixed(3)}</td>
        )}
        {stock.stopLossPrice && (
          <td className="text-center">{stock.stopLossPrice.toFixed(3)}</td>
        )}
        {!stock.stopLossPrice && (
          <td></td>
        )}
        {stock.firstProfitPrice && (
          <td className="text-center">{stock.firstProfitPrice.toFixed(3)}<br/>{stock.secondProfitPrice.toFixed(3)}<br/>{stock.thirdProfitPrice.toFixed(3)}</td>
        )}
        {!stock.firstProfitPrice && (
          <td></td>
        )}
        {!stockDetails && (
          <td className="text-capitalize text-center"><Spinner size="sm" color="success" /></td>
        )}
        <td className="text-center">
          <a href={`https://stocks.ringgitlab.com/search/${stock.stockName}`} rel="noopener noreferrer" target="_blank">
            <Button
              className="white-btn"
              style={{ borderRadius: "10px" }}
            >
              Open
            </Button>
          </a>
        </td>
        <td className="text-center">
          <Button
            className="secondary-btn"
            style={{ borderRadius: "10px" }}
            href={`https://www.tradingview.com/chart/?symbol=MYX%3A${stock.stockName}`}
            rel="noopener noreferrer" target="_blank"
          >
            View
          </Button>
        </td>
      </tr>
    </>
  )
}