import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Alert, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import BuyCalculator from './BuyCalculator';
import LotCalculator from './LotCalculator';

const CalculatorDashboard = () => {
  const [form, setForm] = useState({
    brokerageFee: 0.08,
    minBrokerageFee: 8
  });

  const handleChange = event => {
    const { name, value, type } = event.target;
    const newForm = {
      ...form,
      [name]: type === 'number' ? parseFloat(value) : value
    };
    setForm(newForm);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return(
    <>
      <Helmet>
        <title>Ringgit Lab: Brokerage Calculator</title>
        <meta name="description" 
          content="Calculate your net entries before buying any stocks with Ringgit Lab Brokerage Calculator. These calculations include brokerage, stamp duty and clearing fee."
        />

        {/* Google / Search Engine Tags */}
        <meta itemProp="name" content="Ringgit Lab: Brokerage Calculator" />
        <meta itemProp="description" content="Calculate your net entries before buying any stocks with Ringgit Lab Brokerage Calculator. These calculations include brokerage, stamp duty and clearing fee." />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content="https://stocks.ringgitlab.com/brokerage-calculator" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Ringgit Lab: Brokerage Calculator" />
        <meta property="og:description" content="Calculate your net entries before buying any stocks with Ringgit Lab Brokerage Calculator. These calculations include brokerage, stamp duty and clearing fee." />
        <meta property="og:image" content="https://stocks.ringgitlab.com/calculator.png" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Ringgit Lab: Brokerage Calculator" />
        <meta name="twitter:description" content="Calculate your net entries before buying any stocks with Ringgit Lab Brokerage Calculator. These calculations include brokerage, stamp duty and clearing fee." />
        <meta name="twitter:image" content="https://stocks.ringgitlab.com/calculator.png" />
      </Helmet>
      <Header />
      <Container className="pt-5 pb-5">
        <Alert color="info" className="mt-5">
          This calculator has been updated with new calculation effective on 1st January 2022.
        </Alert>
        <h3 style={{ fontSize: '20px' }} className="font-weight-bold mt-5">
          Calculator
        </h3>
        <h4 className="grey-color" style={{ fontSize: '16px' }}>
          Calculate your total net investment and profit / loss
        </h4>
        <div className="divider"></div>
        <Row>
          <Col md={6} sm={6} xs={6} className="mb-3">
            <Label className="font-weight-bold">
              Brokerage fee (%)
            </Label>
            <FormGroup>
              <Input
                type="number"
                name="brokerageFee"
                id="brokerageFee"
                value={form.brokerageFee}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col md={6} sm={6} xs={6} className="mb-3">
            <Label className="font-weight-bold">
              Min brokerage fee (RM)
            </Label>
            <FormGroup>
              <Input
                type="number"
                name="minBrokerageFee"
                id="minBrokerageFee"
                value={form.minBrokerageFee}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <div className="mt-5">
          <h5 className="font-weight-bold" style={{ fontSize: '18px' }}>
            Buy Calculator
          </h5>
          <BuyCalculator 
            brokerageFee={form.brokerageFee} 
            minBrokerageFee={form.minBrokerageFee}
          />
        </div>
        <div className="divider mt-5"></div>
        <div className="mt-5">
          <h5 className="font-weight-bold" style={{ fontSize: '18px' }}>
            Lot Calculator
          </h5>
          <LotCalculator 
            brokerageFee={form.brokerageFee} 
            minBrokerageFee={form.minBrokerageFee}
          />
        </div>
      </Container>
      <Footer />
    </>
  )
}

export default CalculatorDashboard;