import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import GraphqlClient from '../../utils/GraphqlClient';
import { modelFactory } from '../../models/ModelFactory';
import LoadingSpinner from '../shared/LoadingSpinner';
import { StockDetailsHistoryModel } from '../../models/StockDetailsHistory';
import { Helmet } from 'react-helmet';
import { StockNewsModel } from '../../models/StockNews';
import { StockDetailsModel } from '../../models/StockDetails';

interface Props extends RouteComponentProps<{ id: string; }> {
}

export const StockDetailsStaticPage: React.FC<Props> = (props) => {
  const [errors, setErrors] = useState([]);
  const [stockDetails, setStockDetails] = useState<StockDetailsModel>(undefined);
  const [allHistories, setAllHistories] = useState<StockDetailsHistoryModel[]>(null);
  const [allNews, setAllNews] = useState<StockNewsModel[]>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      (async () => {
        const stockName = props.match.params.id;
        const filter = {
          stockName: { eq: stockName }
        };
        const results = await Promise.all([
          GraphqlClient.listStockDetailss(filter)
        ]);
        const stockDetails = results[0].map(i =>
          modelFactory<StockDetailsModel>(i, StockDetailsModel)
        )
        setStockDetails(stockDetails[0]);
      })();
    } catch (err) {
      const newError = err.errors ? err.errors.map(error => error.message) : err;
      setErrors([...errors, newError]);
    }
  }, [props.match.params.id, errors]);

  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      (async () => {
        const stockName = props.match.params.id;
        const filter = {
          stockName: { eq: stockName }
        };
        const results = await Promise.all([
          GraphqlClient.listStockNewss(filter)
        ]);
        const allNews = results[0].map(i =>
          modelFactory<StockNewsModel>(i, StockNewsModel)
        ).sort((a, b) => {
          if (b.publishedDateTime < a.publishedDateTime) return -1;
          if (b.publishedDateTime > a.publishedDateTime) return 1;
          return 0;
        });
        setAllNews(allNews);
      })();
    } catch (err) {
      const newError = err.errors ? err.errors.map(error => error.message) : err;
      setErrors([...errors, newError]);
    }
  }, [props.match.params.id, errors]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setAllHistories(null);
    const listAllStockHistories = async () => {
      const results = await Promise.all([
        GraphqlClient.listStockDetailsHistorys(null)
      ]);
      const allHistories = results[0].map(i => modelFactory<StockDetailsHistoryModel>(i, StockDetailsHistoryModel))
      .sort((a, b) => {
        if (b.screenerDate < a.screenerDate) return -1;
        if (b.screenerDate > a.screenerDate) return 1;
        return 0;
      });
      setAllHistories(allHistories);
    };
    listAllStockHistories();
  }, []);

  if (!stockDetails || !allHistories) {
    return <LoadingSpinner />
  }

  return (
    <>
      <Helmet>
        <title>Ringgit Lab Stock Search: {stockDetails.stockName}</title>
        <meta name="description" 
          content={`Learn more about the fundamental and technical analysis including the charting of ${stockDetails.stockLongName} to gain insights before investing at ${stockDetails.stockName}.`} 
        />

        {/* Google / Search Engine Tags */}
        <meta itemProp="name" content={`Ringgit Lab Stock Search: ${stockDetails.stockName}`} />
        <meta itemProp="description" content={`Learn more about the fundamental and technical analysis including the charting of ${stockDetails.stockLongName} to gain insights before investing at ${stockDetails.stockName}.`} />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content={`https://stocks.ringgitlab.com/search/${stockDetails.stockName}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Ringgit Lab Stock Search: ${stockDetails.stockName}`} />
        <meta property="og:description" content={`Learn more about the fundamental and technical analysis including the charting of ${stockDetails.stockLongName} to gain insights before investing at ${stockDetails.stockName}.`} />
        {(allNews && allNews.length !== 0) && (
          <meta property="og:image" content={`https://admin.shariahscreener.com${allNews[0].imageLink}`} />
        )}

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Ringgit Lab Stock Search: ${stockDetails.stockName}`} />
        <meta name="twitter:description" content={`Learn more about the fundamental and technical analysis including the charting of ${stockDetails.stockLongName} to gain insights before investing at ${stockDetails.stockName}.`} />
        {(allNews && allNews.length !== 0) && (
          <meta name="twitter:image" content={`https://admin.shariahscreener.com${allNews[0].imageLink}`} />
        )}
      </Helmet>
    </>
  )
}