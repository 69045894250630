import React from 'react';
import { Modal, ModalHeader, ModalBody, Container, Button } from 'reactstrap';

interface Props {
  open: boolean;
  toggle: () => void;
}

export const NoAuthActionModal: React.FC<Props> = (props) => {

  const handleCancel = () => {
    props.toggle();
  };

  return(
    <>
      <Modal isOpen={props.open} toggle={props.toggle} size="md" zIndex={1250}>
        <ModalHeader toggle={props.toggle}>
          <span style={{ fontSize: '20px' }} className="font-weight-bold pt-2 pb-2">
            Please login to Ringgit Lab to proceed.
          </span>
        </ModalHeader>
        <ModalBody className="pb-5 mb-5">
          <Container>
            <h3 style={{ fontSize: '18px' }} className="grey-color">
              Haven't registered Ringgit Lab yet?
            </h3>
            <h4 style={{ fontSize: '18px' }} className="grey-color mt-3">
              Register Ringgit Lab <strong className="success">for free</strong>, the #1 trading tools for stock markets. 
            </h4>
            <h4 style={{ fontSize: '18px' }} className="grey-color mt-3">
              Ringgit Lab offers a set of trading tools for stock markets including stock screener, trading journal, paper trading, market news, free learning materials and more.
            </h4>
            <div className="mt-5">
              <a href="https://www.ringgitlab.com/login">
                <Button
                  className="secondary-btn"
                >
                  Login
                </Button>
              </a>
              <div className="float-right">
                <Button
                  className="mr-3 cancel-btn"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <a href="https://www.ringgitlab.com/signup">
                  <Button
                    className="primary-btn"
                  >
                    Sign Up Now
                  </Button>
                </a>
              </div>
            </div>
          </Container>
        </ModalBody>
      </Modal>
    </>
  )
}
