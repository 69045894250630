import React, { ReactNode } from 'react';

export default class TradingViewChart extends React.Component {
  componentDidMount(): void {

    if (document.getElementById("chart")) {
      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js'
      script.async = true;
      script.innerHTML = JSON.stringify({
        "symbols": [
          {
            "description": "KLSE",
            "proName": "INDEX:KLSE"
          },
          {
            "description": "Dow 30",
            "proName": "FOREXCOM:DJI"
          },
          {
            "description": "S&P 500",
            "proName": "FOREXCOM:SPXUSD"
          },
          {
            "description": "NASDAQ 100",
            "proName": "FOREXCOM:NSXUSD"
          },
          {
            "description": "Nikkei 225",
            "proName": "INDEX:NKY"
          },
          {
            "description": "Crude Oil",
            "proName": "NYMEX:CL1!"
          },
          {
            "description": "Gold",
            "proName": "COMEX:GC1!"
          }
        ],
        "showSymbolLogo": true,
        "colorTheme": "light",
        "isTransparent": false,
        "displayMode": "adaptive",
        "locale": "en"
      });

      document.getElementById("chart")!.appendChild(script);;
    }
  }
  render(): ReactNode {
    return (<div id="chart"> </div>);
  }
}