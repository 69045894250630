import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import GraphqlClient from '../../utils/GraphqlClient';
import { modelFactory } from '../../models/ModelFactory';
import LoadingSpinner from '../shared/LoadingSpinner';
import { Button, Card, CardBody, Col, Row, Alert, Nav, NavItem, NavLink, TabContent, TabPane, Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faEye, faPaste } from '@fortawesome/free-solid-svg-icons';
import { StockDetailsHistoryModel } from '../../models/StockDetailsHistory';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import { StockNewsModel } from '../../models/StockNews';
import { DateTimeFormatter } from '../../utils/DateTimeFormatter';
import parse from 'html-react-parser';
import TradingViewCompanyWidget from './TradingViewCompanyWidget';
import TradingViewFinancialWidget from './TradingViewFinancialWidget';
import TradingViewAnalysisWidget from './TradingViewAnalysisWidget';
import Header from '../header/Header';
import { NoAuthActionModal } from './NoAuthActionModal';
import Footer from '../footer/Footer';
import { StockDetailsModel } from '../../models/StockDetails';
import TradingViewChart from '../shared/TradingViewChart'

interface Props extends RouteComponentProps<{ id: string; }> {
}

export const StockDetailsPage: React.FC<Props> = (props) => {
  const [errors, setErrors] = useState([]);
  const [activeTab, setActiveTab] = useState('1');
  const [showHistories, setShowHistories] = useState(false);
  const [stockDetails, setStockDetails] = useState<StockDetailsModel>(undefined);
  const [openModal, setOpenModal] = useState(false);
  const [allHistories, setAllHistories] = useState<StockDetailsHistoryModel[]>(null);
  const [allNews, setAllNews] = useState<StockNewsModel[]>(null);
  const [stockHistories] = useState([{ id: "", date: "", price: undefined, high: undefined, percentage: undefined }]);

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      (async () => {
        const stockName = props.match.params.id;
        const filter = {
          stockName: { eq: stockName }
        };
        const results = await Promise.all([
          GraphqlClient.listStockDetailss(filter)
        ]);
        const stockDetails = results[0].map(i =>
          modelFactory<StockDetailsModel>(i, StockDetailsModel)
        )
        setStockDetails(stockDetails[0]);
      })();
    } catch (err) {
      const newError = err.errors ? err.errors.map(error => error.message) : err;
      setErrors([...errors, newError]);
    }
  }, [props.match.params.id, errors]);

  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      (async () => {
        const stockName = props.match.params.id;
        const filter = {
          stockName: { eq: stockName }
        };
        const results = await Promise.all([
          GraphqlClient.listStockNewss(filter)
        ]);
        const allNews = results[0].map(i =>
          modelFactory<StockNewsModel>(i, StockNewsModel)
        ).sort((a, b) => {
          if (b.publishedDateTime < a.publishedDateTime) return -1;
          if (b.publishedDateTime > a.publishedDateTime) return 1;
          return 0;
        });
        setAllNews(allNews);
      })();
    } catch (err) {
      const newError = err.errors ? err.errors.map(error => error.message) : err;
      setErrors([...errors, newError]);
    }
  }, [props.match.params.id, errors]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setAllHistories(null);
    const listAllStockHistories = async () => {
      const results = await Promise.all([
        GraphqlClient.listStockDetailsHistorys(null)
      ]);
      const allHistories = results[0].map(i => modelFactory<StockDetailsHistoryModel>(i, StockDetailsHistoryModel))
      .sort((a, b) => {
        if (b.screenerDate < a.screenerDate) return -1;
        if (b.screenerDate > a.screenerDate) return 1;
        return 0;
      });
      setAllHistories(allHistories);
    };
    listAllStockHistories();
  }, []);

  const handleShowHistories = () => {
    setShowHistories(true);
    for (let i = 0; i < allHistories.length; i++) {
      const screenedStocks = Object.assign([], allHistories[i].screenedStocks);
      for (let j = 0; j < screenedStocks.length; j++) {
        if (screenedStocks[j].type === "all") {
          if (screenedStocks[j].stockName === stockDetails.stockName) {
            stockHistories.push({
              id: screenedStocks[j].id,
              date: allHistories[i].screenerDate,
              price: screenedStocks[j].screenedPrice,
              high: screenedStocks[j].highPrice,
              percentage: (((screenedStocks[j].highPrice - screenedStocks[j].screenedPrice) / screenedStocks[j].screenedPrice) * 100).toFixed(2)
            });
          }
        }
      }
    }
  };

  if (!stockDetails || !allHistories) {
    return <LoadingSpinner />
  }

  return (
    <>
      <Helmet>
        <title>Ringgit Lab Stock Search: {stockDetails.stockName}</title>
        <meta name="description" 
          content={`Learn more about the fundamental and technical analysis including the charting of ${stockDetails.stockLongName} to gain insights before investing at ${stockDetails.stockName}.`} 
        />

        {/* Google / Search Engine Tags */}
        <meta itemProp="name" content={`Ringgit Lab Stock Search: ${stockDetails.stockName}`} />
        <meta itemProp="description" content={`Learn more about the fundamental and technical analysis including the charting of ${stockDetails.stockLongName} to gain insights before investing at ${stockDetails.stockName}.`} />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content={`https://stocks.ringgitlab.com/search/MYX/${stockDetails.stockName}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Ringgit Lab Stock Search: ${stockDetails.stockName}`} />
        <meta property="og:description" content={`Learn more about the fundamental and technical analysis including the charting of ${stockDetails.stockLongName} to gain insights before investing at ${stockDetails.stockName}.`} />
        {(allNews && allNews.length !== 0) && (
          <meta property="og:image" content={`https://admin.shariahscreener.com${allNews[0].imageLink}`} />
        )}

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Ringgit Lab Stock Search: ${stockDetails.stockName}`} />
        <meta name="twitter:description" content={`Learn more about the fundamental and technical analysis including the charting of ${stockDetails.stockLongName} to gain insights before investing at ${stockDetails.stockName}.`} />
        {(allNews && allNews.length !== 0) && (
          <meta name="twitter:image" content={`https://admin.shariahscreener.com${allNews[0].imageLink}`} />
        )}
      </Helmet>
      <Header />
      <Container className="pb-5 pt-5 mt-5">
        <TradingViewChart />
        <Alert color="info" className="mt-3">
          The price is delayed by 15 minutes.
        </Alert>
        <h1 style={{ fontSize: '24px' }} className="font-weight-bold mt-5">{stockDetails.stockLongName}</h1>
        <div className="divider"></div>
        <Row>
          <Col md={4} xs={12} sm={12} className="mb-3">
            <Button
              className="primary-btn w-100"
              style={{ borderRadius: "10px" }}
              onClick={toggleModal}
            >
              <span className="mr-3"><FontAwesomeIcon icon={faPaste} /></span>Paper trade {stockDetails.stockName}
            </Button>
          </Col>
          <Col md={4} xs={12} sm={12} className="mb-3">
            <Button
              className="white-btn w-100"
              style={{ borderRadius: "10px" }}
              onClick={toggleModal}
            >
              <span className="mr-3"><FontAwesomeIcon icon={faEye} /></span>Add {stockDetails.stockName} to watch list
            </Button>
          </Col>
          <Col md={4} xs={12} sm={12} className="mb-3">
            <Button
              className="secondary-btn w-100"
              style={{ borderRadius: "10px" }}
              href={`https://www.tradingview.com/chart/?symbol=MYX%3A${stockDetails.stockName}`}
              rel="noopener noreferrer" target="_blank"
            >
              <span className="mr-3"><FontAwesomeIcon icon={faChartLine} /></span>View Trading View chart
            </Button>
          </Col>
        </Row>
        <Card className="mt-5">
          <CardBody>
            <Row>
              <Col md={6} sm={12} xs={12} className="mt-2">
                <h2 className="font-weight-bold">
                  {stockDetails.stockName}
                </h2>
              </Col>
              <Col md={6} sm={12} xs={12} className="mt-2 text-md-right text-left">
                <h3 className="font-weight-bold">
                  {stockDetails.lastPrice && (
                    <>
                      RM {stockDetails.lastPrice}
                    </>
                  )}
                  {!stockDetails.lastPrice && (
                    <span className="grey-color">
                      NOT ACTIVE
                    </span>
                  )}
                  {(stockDetails.change > 0) && (
                    <span className="font-weight-bold success ml-3">
                      +{stockDetails.change} (+{stockDetails.changePercentage})
                    </span>
                  )}
                  {(stockDetails.change < 0) && (
                    <span className="font-weight-bold danger ml-3">
                      {stockDetails.change} ({stockDetails.changePercentage})
                    </span>
                  )}
                  {((stockDetails.change === 0) || (stockDetails.change === null)) && (
                    <span className="font-weight-bold grey-color ml-3">
                      ±0.00
                    </span>
                  )}
                  {((stockDetails.changePercentage === "-") || (stockDetails.changePercentage === "") || (stockDetails.changePercentage === "0.00%")) && (
                    <span className="font-weight-bold grey-color ml-3">
                      (0.00%)
                    </span>
                  )}
                </h3>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <div className="mt-5">
          <Nav tabs>
            <NavItem className="p-0">
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => { toggle('1'); }}
                style={{ cursor: 'pointer', fontSize: '14px' }}
              >
                Technical
              </NavLink>
            </NavItem>
            <NavItem className="p-0">
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => { toggle('2'); }}
                style={{ cursor: 'pointer', fontSize: '14px' }}
              >
                Fundamental
              </NavLink>
            </NavItem>
            <NavItem className="p-0">
              <NavLink
                className={classnames({ active: activeTab === '3' })}
                onClick={() => { toggle('3'); }}
                style={{ cursor: 'pointer', fontSize: '14px' }}
              >
                News
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row className="mt-3">
              <Col md={6} sm={12} xs={12} className="mb-3">
                <div className="mb-3">
                  <TradingViewAnalysisWidget stockName={stockDetails.stockName} />
                </div>
                <Card>
                  <CardBody>
                    <h3 style={{ fontSize: '18px' }} className="font-weight-bold mt-2 mb-4">
                      Ringgit Lab Stock Screener List (Latest 20)
                    </h3>
                    {!showHistories && (
                      <div onClick={handleShowHistories} style={{ cursor: 'pointer', fontSize: '14px' }} className="mb-3 link-text">
                        View all histories
                      </div>
                    )}
                    {stockHistories && stockHistories.slice(0,20).map(stock => {
                      return (
                        <React.Fragment key={stock.id}>
                          {(stock.price !== undefined) && (
                            <>
                              {stock.high && (
                                <>
                                  {((stock.price < stockDetails.lastPrice) && (stock.percentage > 0)) && (
                                    <h5 className="grey-color" style={{ fontSize: '14px' }} key={stock.date}>
                                      Screened on {stock.date} at RM {stock.price} with highest result at RM {stock.high} <span className="success">(+{stock.percentage}%)</span>
                                    </h5>
                                  )}
                                  {((stock.price < stockDetails.lastPrice) && (stock.percentage < 0)) && (
                                    <h5 className="grey-color" style={{ fontSize: '14px' }} key={stock.date}>
                                      Screened on {stock.date} at RM {stock.price} with highest result at RM {stock.high} <span className="danger">({stock.percentage}%)</span>
                                    </h5>
                                  )}
                                  {((stock.price < stockDetails.lastPrice) && (stock.percentage === 0)) && (
                                    <h5 className="grey-color" style={{ fontSize: '14px' }} key={stock.date}>
                                      Screened on {stock.date} at RM {stock.price} with highest result at RM {stock.high} ({stock.percentage}%)
                                    </h5>
                                  )}

                                  {((stock.price > stockDetails.lastPrice) && (stock.percentage > 0)) && (
                                    <h5 className="grey-color" style={{ fontSize: '14px' }} key={stock.date}>
                                      Screened on {stock.date} at RM {stock.price} with highest result at RM {stock.high} <span className="success">(+{stock.percentage}%)</span>
                                    </h5>
                                  )}
                                  {((stock.price > stockDetails.lastPrice) && (stock.percentage < 0)) && (
                                    <h5 className="grey-color" style={{ fontSize: '14px' }} key={stock.date}>
                                      Screened on {stock.date} at RM {stock.price} with highest result at RM {stock.high} <span className="danger">({stock.percentage}%)</span>
                                    </h5>
                                  )}
                                  {((stock.price > stockDetails.lastPrice) && (stock.percentage === 0)) && (
                                    <h5 className="grey-color" style={{ fontSize: '14px' }} key={stock.date}>
                                      Screened on {stock.date} at RM {stock.price} with highest result at RM {stock.high} ({stock.percentage}%)
                                    </h5>
                                  )}

                                  {((stock.price === stockDetails.lastPrice) && (stock.percentage > 0)) && (
                                    <h5 className="grey-color" style={{ fontSize: '14px' }} key={stock.date}>
                                      Screened on {stock.date} at RM {stock.price} with highest result at RM {stock.high} <span className="success">(+{stock.percentage}%)</span>
                                    </h5>
                                  )}
                                  {((stock.price === stockDetails.lastPrice) && (stock.percentage < 0)) && (
                                    <h5 className="grey-color" style={{ fontSize: '14px' }} key={stock.date}>
                                      Screened on {stock.date} at RM {stock.price} with highest result at RM {stock.high} <span className="danger">({stock.percentage}%)</span>
                                    </h5>
                                  )}
                                  {((stock.price === stockDetails.lastPrice) && (stock.percentage === 0)) && (
                                    <h5 className="grey-color" style={{ fontSize: '14px' }} key={stock.date}>
                                      Screened on {stock.date} at RM {stock.price} with highest result at RM {stock.high} ({stock.percentage}%)
                                    </h5>
                                  )}
                                </>
                              )}
                              {!stock.high && (
                                <h5 className="grey-color" style={{ fontSize: '14px' }} key={stock.date}>
                                  Screened on {stock.date} at RM {stock.price} with highest result at RM {stock.price} (0%)
                                </h5>
                              )}
                            </>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </CardBody>
                </Card>
              </Col>
              <Col md={6} sm={12} xs={12} className="mb-3">
                <div>
                  <TradingViewFinancialWidget stockName={stockDetails.stockName} />
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row className="mt-3">
              <Col md={6} sm={12} xs={12} className="mb-3">
                <Card className="pb-5 mb-3">
                  <CardBody>
                    <h2 style={{ fontSize: '18px' }} className="font-weight-bold mt-2">
                      General Info
                    </h2>
                    <h4 style={{ fontSize: '16px' }} className="grey-color mt-4 font-weight-bold">
                      Listed On
                    </h4>
                    {((!stockDetails.listedOn) || (stockDetails.listedOn === "#N/A")) && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        Undefined
                      </h5>
                    )}
                    {(stockDetails.listedOn === "100") && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        Main Market
                      </h5>
                    )}
                    {(stockDetails.listedOn === "400") && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        ACE Market
                      </h5>
                    )}
                    {(stockDetails.listedOn === "700") && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        LEAP Market
                      </h5>
                    )}
                    {(stockDetails.listedOn === "300") && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        Structured Warrants
                      </h5>
                    )}
                    {(stockDetails.listedOn === "500") && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        Bond and Loan
                      </h5>
                    )}
                    <h4 style={{ fontSize: '16px' }} className="grey-color mt-4 font-weight-bold">
                      Sector
                    </h4>
                    {((!stockDetails.sector ) || (stockDetails.sector === "#N/A"))&& (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        Undefined
                      </h5>
                    )}
                    {((stockDetails.sector === "101") || (stockDetails.sector === "201") || (stockDetails.sector === "301") || (stockDetails.sector === "401")) && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        Consumer Products & Services
                      </h5>
                    )}
                    {((stockDetails.sector === "102") || (stockDetails.sector === "202") || (stockDetails.sector === "302") || (stockDetails.sector === "402")) && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        Industrial Products & Services
                      </h5>
                    )}
                    {((stockDetails.sector === "103") || (stockDetails.sector === "203") || (stockDetails.sector === "303") || (stockDetails.sector === "403")) && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        Construction
                      </h5>
                    )}
                    {((stockDetails.sector === "105") || (stockDetails.sector === "205") || (stockDetails.sector === "305") || (stockDetails.sector === "405")) && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        Technology
                      </h5>
                    )}
                    {((stockDetails.sector === "110") || (stockDetails.sector === "210") || (stockDetails.sector === "310") || (stockDetails.sector === "410")) && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        Finance Services
                      </h5>
                    )}
                    {((stockDetails.sector === "120") || (stockDetails.sector === "220") || (stockDetails.sector === "320") || (stockDetails.sector === "420")) && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        Property
                      </h5>
                    )}
                    {((stockDetails.sector === "125") || (stockDetails.sector === "225") || (stockDetails.sector === "325") || (stockDetails.sector === "425")) && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        Plantations
                      </h5>
                    )}
                    {((stockDetails.sector === "150") || (stockDetails.sector === "250") || (stockDetails.sector === "350") || (stockDetails.sector === "450")) && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        Real Estate Investment Trusts
                      </h5>
                    )}
                    {(stockDetails.sector === "656") && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        Exchange Traded Fund-Equity
                      </h5>
                    )}
                    {(stockDetails.sector === "358") && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        Structured Warrants
                      </h5>
                    )}
                    {(stockDetails.sector === "560") && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        Bond-Islamic
                      </h5>
                    )}
                    {((stockDetails.sector === "161") || (stockDetails.sector === "261") || (stockDetails.sector === "361") || (stockDetails.sector === "461")) && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        Energy
                      </h5>
                    )}
                    {((stockDetails.sector === "162") || (stockDetails.sector === "262") || (stockDetails.sector === "362") || (stockDetails.sector === "462")) && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        Health Care
                      </h5>
                    )}
                    {((stockDetails.sector === "163") || (stockDetails.sector === "263") || (stockDetails.sector === "363") || (stockDetails.sector === "463")) && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        Telecommunications & Media
                      </h5>
                    )}
                    {((stockDetails.sector === "164") || (stockDetails.sector === "264") || (stockDetails.sector === "364") || (stockDetails.sector === "464")) && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        Transportation & Logistics
                      </h5>
                    )}
                    {((stockDetails.sector === "165") || (stockDetails.sector === "265") || (stockDetails.sector === "365") || (stockDetails.sector === "465")) && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        Utilities
                      </h5>
                    )}
                    <h4 style={{ fontSize: '16px' }} className="grey-color mt-4 font-weight-bold">
                      Sub Sector
                    </h4>
                    {((!stockDetails.subSector) || (stockDetails.subSector === "#N/A")) && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        Undefined
                      </h5>
                    )}
                    {(stockDetails.subSector !== "#N/A") && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        {stockDetails.subSector}
                      </h5>
                    )}
                    <h4 style={{ fontSize: '16px' }} className="grey-color mt-4 font-weight-bold">
                      Shares Issued
                    </h4>
                    {((stockDetails.shareIssued) && (stockDetails.shareIssued !== "#N/A")) && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        {stockDetails.shareIssued.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </h5>
                    )}
                    {((!stockDetails.shareIssued) || (stockDetails.shareIssued === "#N/A")) && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        Undefined
                      </h5>
                    )}
                    <h4 style={{ fontSize: '16px' }} className="grey-color mt-4 font-weight-bold">
                      Market Capitalization
                    </h4>
                    {((!stockDetails.marketCap) || (stockDetails.marketCap === "#N/A")) && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        Undefined
                      </h5>
                    )}
                    <h5 style={{ fontSize: '16px' }} className="grey-color">
                      RM {stockDetails.marketCap.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </h5>
                    <h4 style={{ fontSize: '16px' }} className="grey-color mt-4 font-weight-bold">
                      Short Sell Indicator
                    </h4>
                    {((!stockDetails.shortSelling) || (stockDetails.shortSelling === "#N/A")) && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color">
                        Undefined
                      </h5>
                    )}
                    {(stockDetails.shortSelling === "0") && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color pb-5">
                        No
                      </h5>
                    )}
                    {(stockDetails.shortSelling === "1") && (
                      <h5 style={{ fontSize: '16px' }} className="grey-color pb-5">
                        Yes
                      </h5>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col md={6} sm={12} xs={12} className="mb-3">
                <TradingViewCompanyWidget stockName={stockDetails.stockName} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Card className="mt-3">
            <CardBody>
              <h2 style={{ fontSize: '18px' }} className="font-weight-bold mt-2 mb-4">
                {stockDetails.stockName} Related News (Latest 10)
              </h2>
              {(allNews && (allNews.length === 0)) && (
                <Alert color="info">
                  There's no current related news about {stockDetails.stockName}.
                </Alert>
              )}
              {allNews && allNews.slice(0,10).map(news => {
                return(
                  <div key={news.id}>
                    <div className="divider mb-4"></div>
                    <Row>
                      <Col md={3} sm={12} xs={12} className="mb-3">
                        <img
                          src={`https://admin.shariahscreener.com${news.imageLink}`}
                          alt={news.title}
                          style={{ width: '100%', height: '150px', objectFit: 'cover' }}
                        />
                        <h5 className="grey-color text-center mt-3" style={{ fontSize: '10px' }}>
                          Published Date: {DateTimeFormatter.formatDate(news.createdDateTime)}
                        </h5>
                      </Col>
                      <Col md={9} sm={12} xs={12} className="mb-3">
                        <h3 className="font-weight-bold" style={{ fontSize: '16px' }}>
                          {news.title}
                        </h3>
                        <h4 style={{ fontSize: '14px' }}>
                          {parse(news.description.slice(0,350) + (news.description.length > 350 ? " ..." : ""))}
                        </h4>
                        {news.editor && (
                          <h4 style={{ fontSize: '14px' }}>
                            Source:
                            <span className="ml-3">
                              <a className="link-text" href={news.link} rel="noopener noreferrer" target="_blank">
                                {news.editor}
                              </a>
                            </span>
                          </h4>
                        )}
                        <div className="pt-3">
                          <a href={`https://www.ringgitlab.com/market-news/${news.id}`} rel="noopener noreferrer" target="_blank">Read more here</a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )
              })}
            </CardBody>
          </Card>
          </TabPane>
        </TabContent>
        <Card className="mt-5">
          <Alert color="light">Ringgit Lab Ads</Alert>
          <CardBody>
            <Row>
              <Col md={3} sm={12} xs={12} className="mb-3">
                <img 
                  src="https://stocks.ringgitlab.com/trading-zulhilmi-ads.png"
                  alt="Open CDS MPlus Online"
                  style={{ width: '100%', height: '150px', objectFit: 'cover' }}
                />
              </Col>
              <Col md={9} sm={12} xs={12} className="mb-3">
                <h3 className="font-weight-bold" style={{ fontSize: '16px' }}>
                  Sign up Ringgit Lab Elite for free
                </h3>
                <h4 style={{ fontSize: '16px' }} className="pb-4">
                  Start stock trading with MPlus Online. Open a trading account (CDS account) with Zulhilmi Zolkfli and get free access to Ringgit Lab Elite.
                </h4>
                <a href="https://www.zulhilmizolkfli.com" rel="noopener noreferrer" target="_blank">Read more here</a>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
      <Footer />
      <NoAuthActionModal 
        open={openModal}
        toggle={toggleModal}
      />
    </>
  )
}