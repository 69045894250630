import React, { useState } from 'react';
import { Row, Col, Label, FormGroup, Input, Card, CardBody } from 'reactstrap';
import Calculations from '../../utils/Calculations';
import { CapitalForm } from '../forms/trading/CapitalForm';
import { EntryPriceForm } from '../forms/trading/EntryPriceForm';
import { ExitPriceForm } from '../forms/trading/ExitPriceForm';

const LotCalculator = (props) => {
  const [suggestedQuantity, setSuggestedQuantity] = useState(null);
  const [totalEntryNet, setTotalEntryNet] = useState(null);
  const [totalExitNet, setTotalExitNet] = useState(null);
  const [totalReturn, setTotalReturn] = useState(null);
  const [totalReturnPercentage, setTotalReturnPercentage] = useState(null);
  const [showEntryDetails, setShowEntryDetails] = useState(false);
  const [showExitDetails, setShowExitDetails] = useState(false);
  const [brokerageFeeEntry, setBrokerageFeeEntry] = useState(null);
  const [clearingFeeEntry, setClearingFeeEntry] = useState(null);
  const [stampDutyEntry, setStampDutyEntry] = useState(null);
  const [brokerageFeeExit, setBrokerageFeeExit] = useState(null);
  const [clearingFeeExit, setClearingFeeExit] = useState(null);
  const [stampDutyExit, setStampDutyExit] = useState(null);
  const [form, setForm] = useState({
    capital: undefined,
    entryPrice: undefined,
    exitPrice: undefined,
  });

  const calculateEntryNetTotal = (price, share, brokerage, brokerageMin) => {
    const grossTotal = (price * (share * 100));
    const brokerageFee = grossTotal * brokerage / 100;
    const stampDutyFee = grossTotal / 1000;
    const clearing = 0.0003;
    if ((brokerageFee < brokerageMin) || (brokerageFee === brokerageMin)) {
      // Clearing below RM 1000
      if ((grossTotal === 333333) || (grossTotal < 333333)) {
        // Stamp duty below RM 1000
        if ((grossTotal=== 666666.67) || (grossTotal < 666666.67)) {
          const totalOut = grossTotal + brokerageMin + (Math.ceil(stampDutyFee) * 1.5) + (clearing * grossTotal);
          setBrokerageFeeEntry(brokerageMin);
          setClearingFeeEntry(clearing * grossTotal);
          setStampDutyEntry(Math.ceil(stampDutyFee) * 1.5);
          return totalOut;
        }
        // Stamp duty max RM 1000
        if (grossTotal > 666666.67) {
          const totalOut = grossTotal + brokerageMin + 1000 + (clearing * grossTotal);
          setBrokerageFeeEntry(brokerageMin);
          setClearingFeeEntry(clearing * grossTotal);
          setStampDutyEntry(1000);
          return totalOut;
        }
      }
      // Clearing max RM 1000
      if (grossTotal > 333333) {
        // Stamp duty below RM 1000
        if ((grossTotal === 666666.67) || (grossTotal < 666666.67)) {
          const totalOut = grossTotal + brokerageMin + (Math.ceil(stampDutyFee) * 1.5) + 1000;
          setBrokerageFeeEntry(brokerageMin);
          setClearingFeeEntry(1000);
          setStampDutyEntry(Math.ceil(stampDutyFee) * 1.5);
          return totalOut;
        }
        // Stamp duty max RM 1000
        if (grossTotal > 666666.67) {
          const totalOut = (price * (share * 100)) + brokerageMin + 1000 + 1000;
          setBrokerageFeeEntry(brokerageMin);
          setClearingFeeEntry(1000);
          setStampDutyEntry(1000);
          return totalOut;
        }
      }
    }
    if (brokerageFee > brokerageMin) {
      // Clearing below RM 1000
      if ((grossTotal === 333333) || (grossTotal < 333333)) {
        // Stamp duty below RM 1000
        if ((grossTotal === 666666.67) || (grossTotal < 666666.67)) {
          const totalOut = grossTotal + brokerageMin + (Math.ceil(stampDutyFee) * 1.5) + (clearing * grossTotal);
          setBrokerageFeeEntry(brokerageFee);
          setClearingFeeEntry(clearing * grossTotal);
          setStampDutyEntry(Math.ceil(stampDutyFee) * 1.5);
          return totalOut;
        }
        // Stamp duty max RM 1000
        if (grossTotal > 666666.67) {
          const totalOut = grossTotal + brokerageMin + 1000 + (clearing * grossTotal);
          setBrokerageFeeEntry(brokerageFee);
          setClearingFeeEntry(clearing * grossTotal);
          setStampDutyEntry(1000);
          return totalOut;
        }
      }
      // Clearing max RM 1000
      if (grossTotal > 333333) {
        // Stamp duty below RM 1000
        if ((grossTotal === 666666.67) || (grossTotal < 666666.67)) {
          const totalOut = grossTotal + brokerageMin + (Math.ceil(stampDutyFee) * 1.5) + 1000;
          setBrokerageFeeEntry(brokerageFee);
          setClearingFeeEntry(1000);
          setStampDutyEntry(Math.ceil(stampDutyFee) * 1.5);
          return totalOut;
        }
        // Stamp duty max RM 1000
        if (grossTotal > 666666.67) {
          const totalOut = (price * (share * 100)) + brokerageMin + 1000 + 1000;
          setBrokerageFeeEntry(brokerageFee);
          setClearingFeeEntry(1000);
          setStampDutyEntry(1000);
          return totalOut;
        }
      }
    }
  }

  const calculateExitNetTotal = (price, share, brokerage, brokerageMin) => {
    const grossTotal = (price * (share * 100));
    const brokerageFee = grossTotal * brokerage / 100;
    const stampDutyFee = grossTotal / 1000;
    const clearing = 0.0003;
    if ((brokerageFee < brokerageMin) || (brokerageFee === brokerageMin)) {
      // Clearing below RM 1000
      if ((grossTotal === 333333) || (grossTotal < 333333)) {
        // Stamp duty below RM 1000
        if ((grossTotal=== 666666.67) || (grossTotal < 666666.67)) {
          const totalOut = grossTotal - (brokerageMin + (Math.ceil(stampDutyFee) * 1.5) + (clearing * grossTotal));
          setBrokerageFeeExit(brokerageMin);
          setClearingFeeExit(clearing * grossTotal);
          setStampDutyExit(Math.ceil(stampDutyFee) * 1.5);
          return totalOut;
        }
        // Stamp duty max RM 1000
        if (grossTotal > 666666.67) {
          const totalOut = grossTotal - (brokerageMin + 1000 + (clearing * grossTotal));
          setBrokerageFeeExit(brokerageMin);
          setClearingFeeExit(clearing * grossTotal);
          setStampDutyExit(1000);
          return totalOut;
        }
      }
      // Clearing max RM 1000
      if (grossTotal > 333333) {
        // Stamp duty below RM 1000
        if ((grossTotal === 666666.67) || (grossTotal < 666666.67)) {
          const totalOut = grossTotal - (brokerageMin + (Math.ceil(stampDutyFee) * 1.5) + 1000);
          setBrokerageFeeExit(brokerageMin);
          setClearingFeeExit(1000);
          setStampDutyExit(Math.ceil(stampDutyFee) * 1.5);
          return totalOut;
        }
        // Stamp duty max RM 1000
        if (grossTotal > 666666.67) {
          const totalOut = (price * (share * 100)) - (brokerageMin + 1000 + 1000);
          setBrokerageFeeExit(brokerageMin);
          setClearingFeeExit(1000);
          setStampDutyExit(1000);
          return totalOut;
        }
      }
    }
    if (brokerageFee > brokerageMin) {
      // Clearing below RM 1000
      if ((grossTotal === 333333) || (grossTotal < 333333)) {
        // Stamp duty below RM 1000
        if ((grossTotal === 666666.67) || (grossTotal < 666666.67)) {
          const totalOut = grossTotal - (brokerageMin + (Math.ceil(stampDutyFee) * 1.5) + (clearing * grossTotal));
          setBrokerageFeeExit(brokerageFee);
          setClearingFeeExit(clearing * grossTotal);
          setStampDutyExit(Math.ceil(stampDutyFee) * 1.5);
          return totalOut;
        }
        // Stamp duty max RM 1000
        if (grossTotal > 666666.67) {
          const totalOut = grossTotal - (brokerageMin + 1000 + (clearing * grossTotal));
          setBrokerageFeeExit(brokerageFee);
          setClearingFeeExit(clearing * grossTotal);
          setStampDutyExit(1000);
          return totalOut;
        }
      }
      // Clearing max RM 1000
      if (grossTotal > 333333) {
        // Stamp duty below RM 1000
        if ((grossTotal === 666666.67) || (grossTotal < 666666.67)) {
          const totalOut = grossTotal - (brokerageMin + (Math.ceil(stampDutyFee) * 1.5) + 1000);
          setBrokerageFeeExit(brokerageFee);
          setClearingFeeExit(1000);
          setStampDutyExit(Math.ceil(stampDutyFee) * 1.5);
          return totalOut;
        }
        // Stamp duty max RM 1000
        if (grossTotal > 666666.67) {
          const totalOut = (price * (share * 100)) - (brokerageMin + 1000 + 1000);
          setBrokerageFeeExit(brokerageFee);
          setClearingFeeExit(1000);
          setStampDutyExit(1000);
          return totalOut;
        }
      }
    }
  }

  const handleChange = event => {
    const { name, value, type } = event.target;
    const newForm = {
      ...form,
      [name]: type === 'number' ? parseFloat(value) : value
    };
    setForm(newForm);
    var suggestedQuantity;
    suggestedQuantity = Calculations.calculateShareFromCapitalByBrokerage(newForm.entryPrice, newForm.capital, props.minBrokerageFee);
    setSuggestedQuantity(suggestedQuantity);
    const totalEntryNet = calculateEntryNetTotal(newForm.entryPrice, suggestedQuantity, props.brokerageFee, props.minBrokerageFee);
    setTotalEntryNet(totalEntryNet);
    const totalExitNet = calculateExitNetTotal(newForm.exitPrice, suggestedQuantity, props.brokerageFee, props.minBrokerageFee);
    setTotalExitNet(totalExitNet);
    const totalReturn = totalExitNet - totalEntryNet;
    setTotalReturn(totalReturn);
    const totalReturnPercentage = ((totalExitNet - totalEntryNet) / totalEntryNet) * 100;
    setTotalReturnPercentage(totalReturnPercentage);
  };

  const handleShowEntryDetails = event => {
    setShowEntryDetails(!showEntryDetails);
  }

  const handleShowExitDetails = event => {
    setShowExitDetails(!showExitDetails);
  }

  return(
    <>
      <Row form className="mt-5">
        <Col md={6} xs={12} sm={12}>
          <Label className="font-weight-bold" for="entryPrice">
            Amount to invest (RM)
          </Label>
          <FormGroup>
            <CapitalForm 
              capital={form.capital}
              handleChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col md={6} xs={12} sm={12}>
          <Label className="font-weight-bold" for="entryShare">
            Entry Price
          </Label>
          <FormGroup>
            <EntryPriceForm 
              entryPrice={form.entryPrice}
              handleChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <div>
        {((form.entryPrice !== undefined) && (form.capital !== undefined)) && (
          <>
            <Label className="font-weight-bold">
              Total Suggested Quantity (x100):
            </Label>
            <FormGroup>
              <Input
                value={parseInt(suggestedQuantity)}
                readOnly
              />
            </FormGroup>
            <div className="pb-3">
              {!showEntryDetails && (
                <p className="link-text" style={{ cursor: 'pointer' }} onClick={handleShowEntryDetails}>Click here for details</p>
              )}
              {showEntryDetails && (
                <>
                  <p className="link-text" style={{ cursor: 'pointer' }} onClick={handleShowEntryDetails}>Hide details</p>
                  <Card>
                    <CardBody>
                      <p className="font-weight-bold" style={{ textDecoration: 'underline' }}>Calculation details for entry</p>
                      <p className="mb-0 font-weight-bold">
                        Gross amount:
                        <span className="font-weight-normal"> RM {(form.entryPrice * suggestedQuantity * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                      </p>
                      <p className="mb-0 font-weight-bold">
                        Brokerage fee:
                        <span className="font-weight-normal"> RM {brokerageFeeEntry.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                      </p>
                      <p className="mb-0 font-weight-bold">
                        Clearing fee:
                        <span className="font-weight-normal"> RM {clearingFeeEntry.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                      </p>
                      <p className="mb-0 font-weight-bold">
                        Stamp duty:
                        <span className="font-weight-normal"> RM {stampDutyEntry.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                      </p>
                      <p className="mb-0 font-weight-bold">
                        Net entry calculation:
                        <span className="font-weight-normal"> RM {(form.entryPrice * suggestedQuantity * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} + RM {brokerageFeeEntry.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} + RM {clearingFeeEntry.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} + RM {stampDutyEntry.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} = RM {totalEntryNet.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                      </p>
                    </CardBody>
                  </Card>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <div>
        {(totalEntryNet && ((form.entryPrice !== undefined) && (suggestedQuantity !== undefined))) && (
          <>
            <Label className="font-weight-bold">
              Total Net (RM):
            </Label>
            <FormGroup>
              <Input
                value={totalEntryNet.toFixed(2)}
                readOnly
              />
            </FormGroup>
          </>
        )}
      </div>
      <div>
        <Label className="font-weight-bold" for="exitPrice">
          Exit Price
        </Label>
        <FormGroup>
          <ExitPriceForm 
            exitPrice={form.exitPrice}
            handleChange={handleChange}
          />
        </FormGroup>
      </div>
      {(totalExitNet && ((form.exitPrice !== undefined) && (suggestedQuantity !== undefined))) && (
        <div className="pb-3">
          {!showExitDetails && (
            <p className="link-text" style={{ cursor: 'pointer' }} onClick={handleShowExitDetails}>Click here for details</p>
          )}
          {showExitDetails && (
            <>
              <p className="link-text" style={{ cursor: 'pointer' }} onClick={handleShowExitDetails}>Hide details</p>
              <Card className="mb-3">
                <CardBody>
                  <p className="font-weight-bold" style={{ textDecoration: 'underline' }}>Calculation details for exit</p>
                  <p className="mb-0 font-weight-bold">
                    Gross amount:
                    <span className="font-weight-normal"> RM {(form.exitPrice * suggestedQuantity * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                  </p>
                  <p className="mb-0 font-weight-bold">
                    Brokerage fee:
                    <span className="font-weight-normal"> RM {brokerageFeeExit.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                  </p>
                  <p className="mb-0 font-weight-bold">
                    Clearing fee:
                    <span className="font-weight-normal"> RM {clearingFeeExit.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                  </p>
                  <p className="mb-0 font-weight-bold">
                    Stamp duty:
                    <span className="font-weight-normal"> RM {stampDutyExit.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                  </p>
                  <p className="mb-0 font-weight-bold">
                    Net exit calculation:
                    <span className="font-weight-normal"> RM {(form.exitPrice * suggestedQuantity * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} - RM {brokerageFeeExit.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} - RM {clearingFeeExit.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} - RM {stampDutyExit.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} = RM {totalExitNet.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                  </p>
                </CardBody>
              </Card>
            </>
          )}
        </div>
      )}
      <div>
        {((form.exitPrice !== undefined) && (suggestedQuantity !== undefined)) && (
          <>
            <Label className="font-weight-bold">
              Total Profit / Loss (RM):
            </Label>
            <FormGroup>
              <Input
                value={totalReturn.toFixed(2)}
                readOnly
              />
            </FormGroup>
          </>
        )}
      </div>
      <div>
        {((form.exitPrice !== undefined) && (suggestedQuantity !== undefined)) && (
          <>
            <Label className="font-weight-bold">
              Total Profit / Loss (%):
            </Label>
            <FormGroup>
              <Input
                value={totalReturnPercentage.toFixed(2)}
                readOnly
              />
            </FormGroup>
          </>
        )}
      </div>
    </>
  )
}

export default LotCalculator;