export abstract class StockNews {
  id: string;
  apiId: string;
  stockId: string;
  stockName: string;
  link: string;
  editor: string;
  title: string;
  description: string;
  imageLink: string;
  publishedDateTime: string;
  createdDateTime: string;
}

export class StockNewsModel extends StockNews {
  json: any;

  constructor(json) {
    super();
    this.json = json;
  }
}