import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import FooterSNS from './FooterSNS';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const title = {
    fontSize: "32px",
    color: '#fff',
    fontFamily: '"Montserrat", sans-serif',
  };

  return(
    <>
      <div className="footer">
        <Container>
          <div className="upper-section-footer">
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12} className="mb-5">
                <h2 style={title} className="font-weight-bold pb-3">Ringgit Lab</h2>
                <FooterSNS />
              </Col>
              <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mb-5" style={{ fontSize: '18px' }}>
                <h3 style={{ fontSize: '18px', fontFamily: '"Montserrat", sans-serif' }} className="font-weight-bold mb-5">Ringgit Lab</h3>
                <Link className="footer-link mb-4" to="/stock-screener">Stock Screener</Link>
                <Link className="footer-link mb-4" to="/trading-journal">Trading Journal</Link>
                <Link className="footer-link mb-4" to="/paper-trading">Paper Trading</Link>
                <Link className="footer-link mb-4" to="/search">Stock Search</Link>
                <a className="footer-link mb-4" href="https://www.ringgitlab.com/market-news" rel="noopener noreferrer" target="_blank">Market News</a>
              </Col>
              <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mb-5" style={{ fontSize: '18px' }}>
                <h3 style={{ fontSize: '18px', fontFamily: '"Montserrat", sans-serif' }} className="font-weight-bold mb-5">Discover</h3>
                <a className="footer-link mb-4" href="https://www.ringgitlab.com/remisier" rel="noopener noreferrer" target="_blank">Ringgit Lab For Remisier</a>
                <a className="footer-link mb-4" href="https://elite.ringgitlab.com" rel="noopener noreferrer" target="_blank">Ringgit Lab Elite</a>
                <Link className="footer-link mb-4" to="/brokerage-calculator">Brokerage Calculator</Link>
                <a className="footer-link mb-4" href="https://www.ringgitlab.com/terms">Terms of Service</a>
                <a className="footer-link mb-4" href="https://www.ringgitlab.com/privacy-policy">Privacy Policy</a>
              </Col>
            </Row>
          </div>
          <div className="divider"></div>
          <div className="copyright">
            <p>Copyright &copy; {currentYear} <Link to="/"><strong>Ringgit Lab Enterprise</strong></Link> (RA0066693-W). All rights reserved.</p>
          </div>
        </Container>
      </div>
    </>
  )
}

export default Footer;