/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSystemMaintenance = /* GraphQL */ `
  mutation CreateSystemMaintenance(
    $input: CreateSystemMaintenanceInput!
    $condition: ModelSystemMaintenanceConditionInput
  ) {
    createSystemMaintenance(input: $input, condition: $condition) {
      id
      switch
      notes
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const updateSystemMaintenance = /* GraphQL */ `
  mutation UpdateSystemMaintenance(
    $input: UpdateSystemMaintenanceInput!
    $condition: ModelSystemMaintenanceConditionInput
  ) {
    updateSystemMaintenance(input: $input, condition: $condition) {
      id
      switch
      notes
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteSystemMaintenance = /* GraphQL */ `
  mutation DeleteSystemMaintenance(
    $input: DeleteSystemMaintenanceInput!
    $condition: ModelSystemMaintenanceConditionInput
  ) {
    deleteSystemMaintenance(input: $input, condition: $condition) {
      id
      switch
      notes
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const registerUser = /* GraphQL */ `
  mutation RegisterUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    registerUser(input: $input, condition: $condition) {
      id
      accountType
      changeStatus
      changedBy
      accountStatus
      registeredDate
      email
      firstName
      lastName
      username
      phoneNumber
      address
      identityCard
      clientCode
      cdsNumber
      broker
      totalCapital
      paperTradeCapital
      remisierCode
      commonCode
      registered
      verified
      subscriptionId
      subscriptionType
      subscriptionPriceId
      subscriptionExpiryDate
      subscriptionStatus
      platformSubscriptionId
      remisierSubscriptionId
      remisierLearningMaterialNumber
      stripeAccountId
      stripePortalUrl
      productId
      cdsClientId
      dailyEmail
      btstEmail
      newsletterEmail
      userType
      profileId
      userFeedback
      clientSurvey
      clientReferralId
      batch
      notes
      activation
      referralCode
      referredByCode
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      accountType
      changeStatus
      changedBy
      accountStatus
      registeredDate
      email
      firstName
      lastName
      username
      phoneNumber
      address
      identityCard
      clientCode
      cdsNumber
      broker
      totalCapital
      paperTradeCapital
      remisierCode
      commonCode
      registered
      verified
      subscriptionId
      subscriptionType
      subscriptionPriceId
      subscriptionExpiryDate
      subscriptionStatus
      platformSubscriptionId
      remisierSubscriptionId
      remisierLearningMaterialNumber
      stripeAccountId
      stripePortalUrl
      productId
      cdsClientId
      dailyEmail
      btstEmail
      newsletterEmail
      userType
      profileId
      userFeedback
      clientSurvey
      clientReferralId
      batch
      notes
      activation
      referralCode
      referredByCode
      createdAt
      updatedAt
    }
  }
`;
export const createAccountActivation = /* GraphQL */ `
  mutation CreateAccountActivation(
    $input: CreateAccountActivationInput!
    $condition: ModelAccountActivationConditionInput
  ) {
    createAccountActivation(input: $input, condition: $condition) {
      id
      userId
      fullName
      email
      remisierCode
      userType
      accountStatus
      emailNotification
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const updateAccountActivation = /* GraphQL */ `
  mutation UpdateAccountActivation(
    $input: UpdateAccountActivationInput!
    $condition: ModelAccountActivationConditionInput
  ) {
    updateAccountActivation(input: $input, condition: $condition) {
      id
      userId
      fullName
      email
      remisierCode
      userType
      accountStatus
      emailNotification
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccountActivation = /* GraphQL */ `
  mutation DeleteAccountActivation(
    $input: DeleteAccountActivationInput!
    $condition: ModelAccountActivationConditionInput
  ) {
    deleteAccountActivation(input: $input, condition: $condition) {
      id
      userId
      fullName
      email
      remisierCode
      userType
      accountStatus
      emailNotification
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const createReferralActivation = /* GraphQL */ `
  mutation CreateReferralActivation(
    $input: CreateReferralActivationInput!
    $condition: ModelReferralActivationConditionInput
  ) {
    createReferralActivation(input: $input, condition: $condition) {
      id
      userId
      referralId
      fullName
      email
      referralCode
      createdAt
      updatedAt
    }
  }
`;
export const updateReferralActivation = /* GraphQL */ `
  mutation UpdateReferralActivation(
    $input: UpdateReferralActivationInput!
    $condition: ModelReferralActivationConditionInput
  ) {
    updateReferralActivation(input: $input, condition: $condition) {
      id
      userId
      referralId
      fullName
      email
      referralCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteReferralActivation = /* GraphQL */ `
  mutation DeleteReferralActivation(
    $input: DeleteReferralActivationInput!
    $condition: ModelReferralActivationConditionInput
  ) {
    deleteReferralActivation(input: $input, condition: $condition) {
      id
      userId
      referralId
      fullName
      email
      referralCode
      createdAt
      updatedAt
    }
  }
`;
export const createClientReferral = /* GraphQL */ `
  mutation CreateClientReferral(
    $input: CreateClientReferralInput!
    $condition: ModelClientReferralConditionInput
  ) {
    createClientReferral(input: $input, condition: $condition) {
      id
      userId
      referralUserId
      fullName
      phoneNumber
      email
      numberOfReferral
      referralPoints
      bankName
      bankAccountType
      bankAccountNumber
      withdrawalRequestDate
      withdrawalStatus
      totalWithdrawal
      withdrawal {
        price
        date
      }
      adminNotes
      referralCode
      activation
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const updateClientReferral = /* GraphQL */ `
  mutation UpdateClientReferral(
    $input: UpdateClientReferralInput!
    $condition: ModelClientReferralConditionInput
  ) {
    updateClientReferral(input: $input, condition: $condition) {
      id
      userId
      referralUserId
      fullName
      phoneNumber
      email
      numberOfReferral
      referralPoints
      bankName
      bankAccountType
      bankAccountNumber
      withdrawalRequestDate
      withdrawalStatus
      totalWithdrawal
      withdrawal {
        price
        date
      }
      adminNotes
      referralCode
      activation
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteClientReferral = /* GraphQL */ `
  mutation DeleteClientReferral(
    $input: DeleteClientReferralInput!
    $condition: ModelClientReferralConditionInput
  ) {
    deleteClientReferral(input: $input, condition: $condition) {
      id
      userId
      referralUserId
      fullName
      phoneNumber
      email
      numberOfReferral
      referralPoints
      bankName
      bankAccountType
      bankAccountNumber
      withdrawalRequestDate
      withdrawalStatus
      totalWithdrawal
      withdrawal {
        price
        date
      }
      adminNotes
      referralCode
      activation
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const createRemisierClientLimit = /* GraphQL */ `
  mutation CreateRemisierClientLimit(
    $input: CreateRemisierClientLimitInput!
    $condition: ModelRemisierClientLimitConditionInput
  ) {
    createRemisierClientLimit(input: $input, condition: $condition) {
      id
      registeredDate
      email
      firstName
      lastName
      phoneNumber
      remisierCode
      createdAt
      updatedAt
    }
  }
`;
export const updateRemisierClientLimit = /* GraphQL */ `
  mutation UpdateRemisierClientLimit(
    $input: UpdateRemisierClientLimitInput!
    $condition: ModelRemisierClientLimitConditionInput
  ) {
    updateRemisierClientLimit(input: $input, condition: $condition) {
      id
      registeredDate
      email
      firstName
      lastName
      phoneNumber
      remisierCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteRemisierClientLimit = /* GraphQL */ `
  mutation DeleteRemisierClientLimit(
    $input: DeleteRemisierClientLimitInput!
    $condition: ModelRemisierClientLimitConditionInput
  ) {
    deleteRemisierClientLimit(input: $input, condition: $condition) {
      id
      registeredDate
      email
      firstName
      lastName
      phoneNumber
      remisierCode
      createdAt
      updatedAt
    }
  }
`;
export const createCdsClient = /* GraphQL */ `
  mutation CreateCdsClient(
    $input: CreateCdsClientInput!
    $condition: ModelCdsClientConditionInput
  ) {
    createCdsClient(input: $input, condition: $condition) {
      id
      clientCode
      fullName
      identityCard
      phoneNumber
      email
      registeredDate
      accountType
      applicationStatus
      contactStatus
      contactChannel
      ringgitLabTelegram
      ringgitLabRegistration
      notes
      userId
      remisierCode
      createdAt
      updatedAt
    }
  }
`;
export const updateCdsClient = /* GraphQL */ `
  mutation UpdateCdsClient(
    $input: UpdateCdsClientInput!
    $condition: ModelCdsClientConditionInput
  ) {
    updateCdsClient(input: $input, condition: $condition) {
      id
      clientCode
      fullName
      identityCard
      phoneNumber
      email
      registeredDate
      accountType
      applicationStatus
      contactStatus
      contactChannel
      ringgitLabTelegram
      ringgitLabRegistration
      notes
      userId
      remisierCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteCdsClient = /* GraphQL */ `
  mutation DeleteCdsClient(
    $input: DeleteCdsClientInput!
    $condition: ModelCdsClientConditionInput
  ) {
    deleteCdsClient(input: $input, condition: $condition) {
      id
      clientCode
      fullName
      identityCard
      phoneNumber
      email
      registeredDate
      accountType
      applicationStatus
      contactStatus
      contactChannel
      ringgitLabTelegram
      ringgitLabRegistration
      notes
      userId
      remisierCode
      createdAt
      updatedAt
    }
  }
`;
export const createTradingOrder = /* GraphQL */ `
  mutation CreateTradingOrder(
    $input: CreateTradingOrderInput!
    $condition: ModelTradingOrderConditionInput
  ) {
    createTradingOrder(input: $input, condition: $condition) {
      id
      clientCode
      fullName
      stockName
      price
      type
      share
      total
      brokerageFee
      tradingStatusDate
      createdAt
      updatedAt
    }
  }
`;
export const updateTradingOrder = /* GraphQL */ `
  mutation UpdateTradingOrder(
    $input: UpdateTradingOrderInput!
    $condition: ModelTradingOrderConditionInput
  ) {
    updateTradingOrder(input: $input, condition: $condition) {
      id
      clientCode
      fullName
      stockName
      price
      type
      share
      total
      brokerageFee
      tradingStatusDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteTradingOrder = /* GraphQL */ `
  mutation DeleteTradingOrder(
    $input: DeleteTradingOrderInput!
    $condition: ModelTradingOrderConditionInput
  ) {
    deleteTradingOrder(input: $input, condition: $condition) {
      id
      clientCode
      fullName
      stockName
      price
      type
      share
      total
      brokerageFee
      tradingStatusDate
      createdAt
      updatedAt
    }
  }
`;
export const createPlatformSubscription = /* GraphQL */ `
  mutation CreatePlatformSubscription(
    $input: CreatePlatformSubscriptionInput!
    $condition: ModelPlatformSubscriptionConditionInput
  ) {
    createPlatformSubscription(input: $input, condition: $condition) {
      id
      fullName
      email
      phoneNumber
      subscriptionUserId
      subscriptionId
      subscriptionPrice
      subscriptionPriceId
      subscriptionExpiryDate
      subscriptionStatus
      paymentMethod
      invoiceId
      invoiceUrl
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const updatePlatformSubscription = /* GraphQL */ `
  mutation UpdatePlatformSubscription(
    $input: UpdatePlatformSubscriptionInput!
    $condition: ModelPlatformSubscriptionConditionInput
  ) {
    updatePlatformSubscription(input: $input, condition: $condition) {
      id
      fullName
      email
      phoneNumber
      subscriptionUserId
      subscriptionId
      subscriptionPrice
      subscriptionPriceId
      subscriptionExpiryDate
      subscriptionStatus
      paymentMethod
      invoiceId
      invoiceUrl
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const deletePlatformSubscription = /* GraphQL */ `
  mutation DeletePlatformSubscription(
    $input: DeletePlatformSubscriptionInput!
    $condition: ModelPlatformSubscriptionConditionInput
  ) {
    deletePlatformSubscription(input: $input, condition: $condition) {
      id
      fullName
      email
      phoneNumber
      subscriptionUserId
      subscriptionId
      subscriptionPrice
      subscriptionPriceId
      subscriptionExpiryDate
      subscriptionStatus
      paymentMethod
      invoiceId
      invoiceUrl
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const createRemisierSubscription = /* GraphQL */ `
  mutation CreateRemisierSubscription(
    $input: CreateRemisierSubscriptionInput!
    $condition: ModelRemisierSubscriptionConditionInput
  ) {
    createRemisierSubscription(input: $input, condition: $condition) {
      id
      fullName
      email
      phoneNumber
      remisierCode
      cmsrlNumber
      cmsrlExpiryDate
      broker
      clientNumber
      subscriptionUserId
      subscriptionId
      subscriptionPrice
      subscriptionPriceId
      subscriptionExpiryDate
      subscriptionStatus
      subscriptionType
      paymentMethod
      totalPayments {
        price
        date
      }
      invoiceId
      invoiceUrl
      profileId
      changeStatus
      changeSubscription
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const updateRemisierSubscription = /* GraphQL */ `
  mutation UpdateRemisierSubscription(
    $input: UpdateRemisierSubscriptionInput!
    $condition: ModelRemisierSubscriptionConditionInput
  ) {
    updateRemisierSubscription(input: $input, condition: $condition) {
      id
      fullName
      email
      phoneNumber
      remisierCode
      cmsrlNumber
      cmsrlExpiryDate
      broker
      clientNumber
      subscriptionUserId
      subscriptionId
      subscriptionPrice
      subscriptionPriceId
      subscriptionExpiryDate
      subscriptionStatus
      subscriptionType
      paymentMethod
      totalPayments {
        price
        date
      }
      invoiceId
      invoiceUrl
      profileId
      changeStatus
      changeSubscription
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteRemisierSubscription = /* GraphQL */ `
  mutation DeleteRemisierSubscription(
    $input: DeleteRemisierSubscriptionInput!
    $condition: ModelRemisierSubscriptionConditionInput
  ) {
    deleteRemisierSubscription(input: $input, condition: $condition) {
      id
      fullName
      email
      phoneNumber
      remisierCode
      cmsrlNumber
      cmsrlExpiryDate
      broker
      clientNumber
      subscriptionUserId
      subscriptionId
      subscriptionPrice
      subscriptionPriceId
      subscriptionExpiryDate
      subscriptionStatus
      subscriptionType
      paymentMethod
      totalPayments {
        price
        date
      }
      invoiceId
      invoiceUrl
      profileId
      changeStatus
      changeSubscription
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const createRemisierProfile = /* GraphQL */ `
  mutation CreateRemisierProfile(
    $input: CreateRemisierProfileInput!
    $condition: ModelRemisierProfileConditionInput
  ) {
    createRemisierProfile(input: $input, condition: $condition) {
      id
      fullName
      remisierUserId
      remisierCode
      published
      cmsrlNumber
      broker
      profileImage {
        bucket
        region
        key
      }
      contactNumber
      email
      address
      introduction
      description
      website
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const updateRemisierProfile = /* GraphQL */ `
  mutation UpdateRemisierProfile(
    $input: UpdateRemisierProfileInput!
    $condition: ModelRemisierProfileConditionInput
  ) {
    updateRemisierProfile(input: $input, condition: $condition) {
      id
      fullName
      remisierUserId
      remisierCode
      published
      cmsrlNumber
      broker
      profileImage {
        bucket
        region
        key
      }
      contactNumber
      email
      address
      introduction
      description
      website
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteRemisierProfile = /* GraphQL */ `
  mutation DeleteRemisierProfile(
    $input: DeleteRemisierProfileInput!
    $condition: ModelRemisierProfileConditionInput
  ) {
    deleteRemisierProfile(input: $input, condition: $condition) {
      id
      fullName
      remisierUserId
      remisierCode
      published
      cmsrlNumber
      broker
      profileImage {
        bucket
        region
        key
      }
      contactNumber
      email
      address
      introduction
      description
      website
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const createBroker = /* GraphQL */ `
  mutation CreateBroker(
    $input: CreateBrokerInput!
    $condition: ModelBrokerConditionInput
  ) {
    createBroker(input: $input, condition: $condition) {
      id
      brokerName
      brokerOfficialName
      phoneNumber
      email
      website
      personInCharge
      address
      remisierNumber
      createdAt
      updatedAt
    }
  }
`;
export const updateBroker = /* GraphQL */ `
  mutation UpdateBroker(
    $input: UpdateBrokerInput!
    $condition: ModelBrokerConditionInput
  ) {
    updateBroker(input: $input, condition: $condition) {
      id
      brokerName
      brokerOfficialName
      phoneNumber
      email
      website
      personInCharge
      address
      remisierNumber
      createdAt
      updatedAt
    }
  }
`;
export const deleteBroker = /* GraphQL */ `
  mutation DeleteBroker(
    $input: DeleteBrokerInput!
    $condition: ModelBrokerConditionInput
  ) {
    deleteBroker(input: $input, condition: $condition) {
      id
      brokerName
      brokerOfficialName
      phoneNumber
      email
      website
      personInCharge
      address
      remisierNumber
      createdAt
      updatedAt
    }
  }
`;
export const createVerification = /* GraphQL */ `
  mutation CreateVerification(
    $input: CreateVerificationInput!
    $condition: ModelVerificationConditionInput
  ) {
    createVerification(input: $input, condition: $condition) {
      id
      verificationUserId
      verificationFile {
        bucket
        region
        key
      }
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateVerification = /* GraphQL */ `
  mutation UpdateVerification(
    $input: UpdateVerificationInput!
    $condition: ModelVerificationConditionInput
  ) {
    updateVerification(input: $input, condition: $condition) {
      id
      verificationUserId
      verificationFile {
        bucket
        region
        key
      }
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteVerification = /* GraphQL */ `
  mutation DeleteVerification(
    $input: DeleteVerificationInput!
    $condition: ModelVerificationConditionInput
  ) {
    deleteVerification(input: $input, condition: $condition) {
      id
      verificationUserId
      verificationFile {
        bucket
        region
        key
      }
      status
      createdAt
      updatedAt
    }
  }
`;
export const createLatestUpdate = /* GraphQL */ `
  mutation CreateLatestUpdate(
    $input: CreateLatestUpdateInput!
    $condition: ModelLatestUpdateConditionInput
  ) {
    createLatestUpdate(input: $input, condition: $condition) {
      id
      postedDate
      title
      description
      updateImage {
        bucket
        region
        key
      }
      remisierCode
      broker
      userId
      createdAt
      updatedAt
    }
  }
`;
export const updateLatestUpdate = /* GraphQL */ `
  mutation UpdateLatestUpdate(
    $input: UpdateLatestUpdateInput!
    $condition: ModelLatestUpdateConditionInput
  ) {
    updateLatestUpdate(input: $input, condition: $condition) {
      id
      postedDate
      title
      description
      updateImage {
        bucket
        region
        key
      }
      remisierCode
      broker
      userId
      createdAt
      updatedAt
    }
  }
`;
export const deleteLatestUpdate = /* GraphQL */ `
  mutation DeleteLatestUpdate(
    $input: DeleteLatestUpdateInput!
    $condition: ModelLatestUpdateConditionInput
  ) {
    deleteLatestUpdate(input: $input, condition: $condition) {
      id
      postedDate
      title
      description
      updateImage {
        bucket
        region
        key
      }
      remisierCode
      broker
      userId
      createdAt
      updatedAt
    }
  }
`;
export const createTradingJournal = /* GraphQL */ `
  mutation CreateTradingJournal(
    $input: CreateTradingJournalInput!
    $condition: ModelTradingJournalConditionInput
  ) {
    createTradingJournal(input: $input, condition: $condition) {
      id
      tradingJournalUserId
      stockName
      stockCode
      sector
      subSector
      status
      portfolio
      entries {
        date
        price
        share
        net
      }
      exits {
        date
        price
        share
        net
      }
      returnPrice
      returnPercentage
      side
      strategy
      otherStrategy
      mistake
      otherMistake
      cutLoss
      targetPrice
      notes
      createdAt
      updatedAt
    }
  }
`;
export const updateTradingJournal = /* GraphQL */ `
  mutation UpdateTradingJournal(
    $input: UpdateTradingJournalInput!
    $condition: ModelTradingJournalConditionInput
  ) {
    updateTradingJournal(input: $input, condition: $condition) {
      id
      tradingJournalUserId
      stockName
      stockCode
      sector
      subSector
      status
      portfolio
      entries {
        date
        price
        share
        net
      }
      exits {
        date
        price
        share
        net
      }
      returnPrice
      returnPercentage
      side
      strategy
      otherStrategy
      mistake
      otherMistake
      cutLoss
      targetPrice
      notes
      createdAt
      updatedAt
    }
  }
`;
export const deleteTradingJournal = /* GraphQL */ `
  mutation DeleteTradingJournal(
    $input: DeleteTradingJournalInput!
    $condition: ModelTradingJournalConditionInput
  ) {
    deleteTradingJournal(input: $input, condition: $condition) {
      id
      tradingJournalUserId
      stockName
      stockCode
      sector
      subSector
      status
      portfolio
      entries {
        date
        price
        share
        net
      }
      exits {
        date
        price
        share
        net
      }
      returnPrice
      returnPercentage
      side
      strategy
      otherStrategy
      mistake
      otherMistake
      cutLoss
      targetPrice
      notes
      createdAt
      updatedAt
    }
  }
`;
export const createJournal = /* GraphQL */ `
  mutation CreateJournal(
    $input: CreateJournalInput!
    $condition: ModelJournalConditionInput
  ) {
    createJournal(input: $input, condition: $condition) {
      id
      journalUserId
      journalList {
        id
        portfolio
        name
        date
        price
        share
        net
        type
        side
        strategy
        otherStrategy
        mistake
        otherMistake
        cutLoss
        targetPrice
        notes
        updatedDateTime
      }
      stockName
      stockCode
      sector
      returnPrice
      returnPercentage
      notes
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const updateJournal = /* GraphQL */ `
  mutation UpdateJournal(
    $input: UpdateJournalInput!
    $condition: ModelJournalConditionInput
  ) {
    updateJournal(input: $input, condition: $condition) {
      id
      journalUserId
      journalList {
        id
        portfolio
        name
        date
        price
        share
        net
        type
        side
        strategy
        otherStrategy
        mistake
        otherMistake
        cutLoss
        targetPrice
        notes
        updatedDateTime
      }
      stockName
      stockCode
      sector
      returnPrice
      returnPercentage
      notes
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteJournal = /* GraphQL */ `
  mutation DeleteJournal(
    $input: DeleteJournalInput!
    $condition: ModelJournalConditionInput
  ) {
    deleteJournal(input: $input, condition: $condition) {
      id
      journalUserId
      journalList {
        id
        portfolio
        name
        date
        price
        share
        net
        type
        side
        strategy
        otherStrategy
        mistake
        otherMistake
        cutLoss
        targetPrice
        notes
        updatedDateTime
      }
      stockName
      stockCode
      sector
      returnPrice
      returnPercentage
      notes
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const createPortfolio = /* GraphQL */ `
  mutation CreatePortfolio(
    $input: CreatePortfolioInput!
    $condition: ModelPortfolioConditionInput
  ) {
    createPortfolio(input: $input, condition: $condition) {
      id
      portfolioUserId
      fullName
      accountStatus
      phoneNumber
      totalCapital
      capitalPercentage
      totalNetReturn
      totalNetReturnPercentage
      totalPaidCommissionFee
      totalWithdrawal
      withdrawal {
        price
        date
      }
      portfolioList {
        id
        stockName
        status
        entryDate
        entryPrice
        entryShare
        entryGross
        entryNet
        exitDate
        exitPrice
        exitShare
        exitGross
        exitNet
        returnPrice
        returnPercentage
        commissionFee
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePortfolio = /* GraphQL */ `
  mutation UpdatePortfolio(
    $input: UpdatePortfolioInput!
    $condition: ModelPortfolioConditionInput
  ) {
    updatePortfolio(input: $input, condition: $condition) {
      id
      portfolioUserId
      fullName
      accountStatus
      phoneNumber
      totalCapital
      capitalPercentage
      totalNetReturn
      totalNetReturnPercentage
      totalPaidCommissionFee
      totalWithdrawal
      withdrawal {
        price
        date
      }
      portfolioList {
        id
        stockName
        status
        entryDate
        entryPrice
        entryShare
        entryGross
        entryNet
        exitDate
        exitPrice
        exitShare
        exitGross
        exitNet
        returnPrice
        returnPercentage
        commissionFee
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePortfolio = /* GraphQL */ `
  mutation DeletePortfolio(
    $input: DeletePortfolioInput!
    $condition: ModelPortfolioConditionInput
  ) {
    deletePortfolio(input: $input, condition: $condition) {
      id
      portfolioUserId
      fullName
      accountStatus
      phoneNumber
      totalCapital
      capitalPercentage
      totalNetReturn
      totalNetReturnPercentage
      totalPaidCommissionFee
      totalWithdrawal
      withdrawal {
        price
        date
      }
      portfolioList {
        id
        stockName
        status
        entryDate
        entryPrice
        entryShare
        entryGross
        entryNet
        exitDate
        exitPrice
        exitShare
        exitGross
        exitNet
        returnPrice
        returnPercentage
        commissionFee
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPaperTrade = /* GraphQL */ `
  mutation CreatePaperTrade(
    $input: CreatePaperTradeInput!
    $condition: ModelPaperTradeConditionInput
  ) {
    createPaperTrade(input: $input, condition: $condition) {
      id
      paperTradeUserId
      stockName
      stockCode
      status
      lastPrice
      entryDate
      entryPrice
      entryShare
      exitDate
      exitPrice
      exitShare
      returnPrice
      returnPercentage
      side
      strategy
      otherStrategy
      mistake
      otherMistake
      cutLoss
      targetPrice
      notes
      createdAt
      updatedAt
    }
  }
`;
export const updatePaperTrade = /* GraphQL */ `
  mutation UpdatePaperTrade(
    $input: UpdatePaperTradeInput!
    $condition: ModelPaperTradeConditionInput
  ) {
    updatePaperTrade(input: $input, condition: $condition) {
      id
      paperTradeUserId
      stockName
      stockCode
      status
      lastPrice
      entryDate
      entryPrice
      entryShare
      exitDate
      exitPrice
      exitShare
      returnPrice
      returnPercentage
      side
      strategy
      otherStrategy
      mistake
      otherMistake
      cutLoss
      targetPrice
      notes
      createdAt
      updatedAt
    }
  }
`;
export const deletePaperTrade = /* GraphQL */ `
  mutation DeletePaperTrade(
    $input: DeletePaperTradeInput!
    $condition: ModelPaperTradeConditionInput
  ) {
    deletePaperTrade(input: $input, condition: $condition) {
      id
      paperTradeUserId
      stockName
      stockCode
      status
      lastPrice
      entryDate
      entryPrice
      entryShare
      exitDate
      exitPrice
      exitShare
      returnPrice
      returnPercentage
      side
      strategy
      otherStrategy
      mistake
      otherMistake
      cutLoss
      targetPrice
      notes
      createdAt
      updatedAt
    }
  }
`;
export const createStockNews = /* GraphQL */ `
  mutation CreateStockNews(
    $input: CreateStockNewsInput!
    $condition: ModelStockNewsConditionInput
  ) {
    createStockNews(input: $input, condition: $condition) {
      id
      apiId
      stockId
      stockName
      link
      editor
      title
      description
      imageLink
      publishedDateTime
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const updateStockNews = /* GraphQL */ `
  mutation UpdateStockNews(
    $input: UpdateStockNewsInput!
    $condition: ModelStockNewsConditionInput
  ) {
    updateStockNews(input: $input, condition: $condition) {
      id
      apiId
      stockId
      stockName
      link
      editor
      title
      description
      imageLink
      publishedDateTime
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteStockNews = /* GraphQL */ `
  mutation DeleteStockNews(
    $input: DeleteStockNewsInput!
    $condition: ModelStockNewsConditionInput
  ) {
    deleteStockNews(input: $input, condition: $condition) {
      id
      apiId
      stockId
      stockName
      link
      editor
      title
      description
      imageLink
      publishedDateTime
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const createStockDetails = /* GraphQL */ `
  mutation CreateStockDetails(
    $input: CreateStockDetailsInput!
    $condition: ModelStockDetailsConditionInput
  ) {
    createStockDetails(input: $input, condition: $condition) {
      id
      market
      updatedDateTime
      stockLongName
      stockName
      stockCode
      lastPrice
      change
      changePercentage
      volume
      transaction
      buyValue
      sellValue
      buyMinusSellValue
      vwap
      ema5
      volumeVMAChangePercentage20
      lastForthQuarterNetProfit
      averageBuyPrice
      previousClosePrice
      openPrice
      highPrice
      lowPrice
      oneYearHighPrice
      ema20
      marketCap
      eps
      peRatio
      rsi
      sector
      subSector
      listedOn
      shareIssued
      shortSelling
      dividend
      dividendYield
      fyeDate
      quarterDate
      lastForthQuarterDividend
      lastForthQuarterDividendYield
      lastForthQuarterEps
      lastForthQuarterNab
      lastForthQuarterPeRatio
      lastForthQuarterRevenue
      nab
      netProfit
      revenue
      createdAt
      updatedAt
    }
  }
`;
export const updateStockDetails = /* GraphQL */ `
  mutation UpdateStockDetails(
    $input: UpdateStockDetailsInput!
    $condition: ModelStockDetailsConditionInput
  ) {
    updateStockDetails(input: $input, condition: $condition) {
      id
      market
      updatedDateTime
      stockLongName
      stockName
      stockCode
      lastPrice
      change
      changePercentage
      volume
      transaction
      buyValue
      sellValue
      buyMinusSellValue
      vwap
      ema5
      volumeVMAChangePercentage20
      lastForthQuarterNetProfit
      averageBuyPrice
      previousClosePrice
      openPrice
      highPrice
      lowPrice
      oneYearHighPrice
      ema20
      marketCap
      eps
      peRatio
      rsi
      sector
      subSector
      listedOn
      shareIssued
      shortSelling
      dividend
      dividendYield
      fyeDate
      quarterDate
      lastForthQuarterDividend
      lastForthQuarterDividendYield
      lastForthQuarterEps
      lastForthQuarterNab
      lastForthQuarterPeRatio
      lastForthQuarterRevenue
      nab
      netProfit
      revenue
      createdAt
      updatedAt
    }
  }
`;
export const deleteStockDetails = /* GraphQL */ `
  mutation DeleteStockDetails(
    $input: DeleteStockDetailsInput!
    $condition: ModelStockDetailsConditionInput
  ) {
    deleteStockDetails(input: $input, condition: $condition) {
      id
      market
      updatedDateTime
      stockLongName
      stockName
      stockCode
      lastPrice
      change
      changePercentage
      volume
      transaction
      buyValue
      sellValue
      buyMinusSellValue
      vwap
      ema5
      volumeVMAChangePercentage20
      lastForthQuarterNetProfit
      averageBuyPrice
      previousClosePrice
      openPrice
      highPrice
      lowPrice
      oneYearHighPrice
      ema20
      marketCap
      eps
      peRatio
      rsi
      sector
      subSector
      listedOn
      shareIssued
      shortSelling
      dividend
      dividendYield
      fyeDate
      quarterDate
      lastForthQuarterDividend
      lastForthQuarterDividendYield
      lastForthQuarterEps
      lastForthQuarterNab
      lastForthQuarterPeRatio
      lastForthQuarterRevenue
      nab
      netProfit
      revenue
      createdAt
      updatedAt
    }
  }
`;
export const createStockDetailsHistory = /* GraphQL */ `
  mutation CreateStockDetailsHistory(
    $input: CreateStockDetailsHistoryInput!
    $condition: ModelStockDetailsHistoryConditionInput
  ) {
    createStockDetailsHistory(input: $input, condition: $condition) {
      id
      screenerDate
      updatedDateTime
      screenedStocks {
        id
        stockId
        updatedDateTime
        type
        market
        stockName
        stockCode
        screenedPrice
        stopLossPrice
        firstProfitPrice
        secondProfitPrice
        thirdProfitPrice
        lastPrice
        change
        changePercentage
        volume
        transaction
        buyValue
        sellValue
        buyMinusSellValue
        vwap
        ema5
        volumeVMAChangePercentage20
        lastForthQuarterNetProfit
        averageBuyPrice
        previousClosePrice
        openPrice
        highPrice
        lowPrice
        oneYearHighPrice
        ema20
        marketCap
        eps
        peRatio
        rsi
        sector
        subSector
        listedOn
        shareIssued
        shortSelling
        dividend
        dividendYield
        fyeDate
        quarterDate
        lastForthQuarterDividend
        lastForthQuarterDividendYield
        lastForthQuarterEps
        lastForthQuarterNab
        lastForthQuarterPeRatio
        lastForthQuarterRevenue
        nab
        netProfit
        revenue
        remisierCode
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateStockDetailsHistory = /* GraphQL */ `
  mutation UpdateStockDetailsHistory(
    $input: UpdateStockDetailsHistoryInput!
    $condition: ModelStockDetailsHistoryConditionInput
  ) {
    updateStockDetailsHistory(input: $input, condition: $condition) {
      id
      screenerDate
      updatedDateTime
      screenedStocks {
        id
        stockId
        updatedDateTime
        type
        market
        stockName
        stockCode
        screenedPrice
        stopLossPrice
        firstProfitPrice
        secondProfitPrice
        thirdProfitPrice
        lastPrice
        change
        changePercentage
        volume
        transaction
        buyValue
        sellValue
        buyMinusSellValue
        vwap
        ema5
        volumeVMAChangePercentage20
        lastForthQuarterNetProfit
        averageBuyPrice
        previousClosePrice
        openPrice
        highPrice
        lowPrice
        oneYearHighPrice
        ema20
        marketCap
        eps
        peRatio
        rsi
        sector
        subSector
        listedOn
        shareIssued
        shortSelling
        dividend
        dividendYield
        fyeDate
        quarterDate
        lastForthQuarterDividend
        lastForthQuarterDividendYield
        lastForthQuarterEps
        lastForthQuarterNab
        lastForthQuarterPeRatio
        lastForthQuarterRevenue
        nab
        netProfit
        revenue
        remisierCode
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteStockDetailsHistory = /* GraphQL */ `
  mutation DeleteStockDetailsHistory(
    $input: DeleteStockDetailsHistoryInput!
    $condition: ModelStockDetailsHistoryConditionInput
  ) {
    deleteStockDetailsHistory(input: $input, condition: $condition) {
      id
      screenerDate
      updatedDateTime
      screenedStocks {
        id
        stockId
        updatedDateTime
        type
        market
        stockName
        stockCode
        screenedPrice
        stopLossPrice
        firstProfitPrice
        secondProfitPrice
        thirdProfitPrice
        lastPrice
        change
        changePercentage
        volume
        transaction
        buyValue
        sellValue
        buyMinusSellValue
        vwap
        ema5
        volumeVMAChangePercentage20
        lastForthQuarterNetProfit
        averageBuyPrice
        previousClosePrice
        openPrice
        highPrice
        lowPrice
        oneYearHighPrice
        ema20
        marketCap
        eps
        peRatio
        rsi
        sector
        subSector
        listedOn
        shareIssued
        shortSelling
        dividend
        dividendYield
        fyeDate
        quarterDate
        lastForthQuarterDividend
        lastForthQuarterDividendYield
        lastForthQuarterEps
        lastForthQuarterNab
        lastForthQuarterPeRatio
        lastForthQuarterRevenue
        nab
        netProfit
        revenue
        remisierCode
      }
      createdAt
      updatedAt
    }
  }
`;
export const createStockDetailsHistoryTest = /* GraphQL */ `
  mutation CreateStockDetailsHistoryTest(
    $input: CreateStockDetailsHistoryTestInput!
    $condition: ModelStockDetailsHistoryTestConditionInput
  ) {
    createStockDetailsHistoryTest(input: $input, condition: $condition) {
      id
      screenerDate
      updatedDateTime
      screenedStocks {
        id
        stockId
        updatedDateTime
        type
        market
        stockName
        stockCode
        screenedPrice
        stopLossPrice
        firstProfitPrice
        secondProfitPrice
        thirdProfitPrice
        lastPrice
        change
        changePercentage
        volume
        transaction
        buyValue
        sellValue
        buyMinusSellValue
        vwap
        ema5
        volumeVMAChangePercentage20
        lastForthQuarterNetProfit
        averageBuyPrice
        previousClosePrice
        openPrice
        highPrice
        lowPrice
        oneYearHighPrice
        ema20
        marketCap
        eps
        peRatio
        rsi
        sector
        subSector
        listedOn
        shareIssued
        shortSelling
        dividend
        dividendYield
        fyeDate
        quarterDate
        lastForthQuarterDividend
        lastForthQuarterDividendYield
        lastForthQuarterEps
        lastForthQuarterNab
        lastForthQuarterPeRatio
        lastForthQuarterRevenue
        nab
        netProfit
        revenue
        remisierCode
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateStockDetailsHistoryTest = /* GraphQL */ `
  mutation UpdateStockDetailsHistoryTest(
    $input: UpdateStockDetailsHistoryTestInput!
    $condition: ModelStockDetailsHistoryTestConditionInput
  ) {
    updateStockDetailsHistoryTest(input: $input, condition: $condition) {
      id
      screenerDate
      updatedDateTime
      screenedStocks {
        id
        stockId
        updatedDateTime
        type
        market
        stockName
        stockCode
        screenedPrice
        stopLossPrice
        firstProfitPrice
        secondProfitPrice
        thirdProfitPrice
        lastPrice
        change
        changePercentage
        volume
        transaction
        buyValue
        sellValue
        buyMinusSellValue
        vwap
        ema5
        volumeVMAChangePercentage20
        lastForthQuarterNetProfit
        averageBuyPrice
        previousClosePrice
        openPrice
        highPrice
        lowPrice
        oneYearHighPrice
        ema20
        marketCap
        eps
        peRatio
        rsi
        sector
        subSector
        listedOn
        shareIssued
        shortSelling
        dividend
        dividendYield
        fyeDate
        quarterDate
        lastForthQuarterDividend
        lastForthQuarterDividendYield
        lastForthQuarterEps
        lastForthQuarterNab
        lastForthQuarterPeRatio
        lastForthQuarterRevenue
        nab
        netProfit
        revenue
        remisierCode
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteStockDetailsHistoryTest = /* GraphQL */ `
  mutation DeleteStockDetailsHistoryTest(
    $input: DeleteStockDetailsHistoryTestInput!
    $condition: ModelStockDetailsHistoryTestConditionInput
  ) {
    deleteStockDetailsHistoryTest(input: $input, condition: $condition) {
      id
      screenerDate
      updatedDateTime
      screenedStocks {
        id
        stockId
        updatedDateTime
        type
        market
        stockName
        stockCode
        screenedPrice
        stopLossPrice
        firstProfitPrice
        secondProfitPrice
        thirdProfitPrice
        lastPrice
        change
        changePercentage
        volume
        transaction
        buyValue
        sellValue
        buyMinusSellValue
        vwap
        ema5
        volumeVMAChangePercentage20
        lastForthQuarterNetProfit
        averageBuyPrice
        previousClosePrice
        openPrice
        highPrice
        lowPrice
        oneYearHighPrice
        ema20
        marketCap
        eps
        peRatio
        rsi
        sector
        subSector
        listedOn
        shareIssued
        shortSelling
        dividend
        dividendYield
        fyeDate
        quarterDate
        lastForthQuarterDividend
        lastForthQuarterDividendYield
        lastForthQuarterEps
        lastForthQuarterNab
        lastForthQuarterPeRatio
        lastForthQuarterRevenue
        nab
        netProfit
        revenue
        remisierCode
      }
      createdAt
      updatedAt
    }
  }
`;
export const createWatchList = /* GraphQL */ `
  mutation CreateWatchList(
    $input: CreateWatchListInput!
    $condition: ModelWatchListConditionInput
  ) {
    createWatchList(input: $input, condition: $condition) {
      id
      watchListUserId
      stockDetailsId
      stockName
      stockCode
      lastPrice
      entryPrice
      stopLossPrice
      firstProfitPrice
      secondProfitPrice
      thirdProfitPrice
      status
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const updateWatchList = /* GraphQL */ `
  mutation UpdateWatchList(
    $input: UpdateWatchListInput!
    $condition: ModelWatchListConditionInput
  ) {
    updateWatchList(input: $input, condition: $condition) {
      id
      watchListUserId
      stockDetailsId
      stockName
      stockCode
      lastPrice
      entryPrice
      stopLossPrice
      firstProfitPrice
      secondProfitPrice
      thirdProfitPrice
      status
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteWatchList = /* GraphQL */ `
  mutation DeleteWatchList(
    $input: DeleteWatchListInput!
    $condition: ModelWatchListConditionInput
  ) {
    deleteWatchList(input: $input, condition: $condition) {
      id
      watchListUserId
      stockDetailsId
      stockName
      stockCode
      lastPrice
      entryPrice
      stopLossPrice
      firstProfitPrice
      secondProfitPrice
      thirdProfitPrice
      status
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const createDailyEmailNotification = /* GraphQL */ `
  mutation CreateDailyEmailNotification(
    $input: CreateDailyEmailNotificationInput!
    $condition: ModelDailyEmailNotificationConditionInput
  ) {
    createDailyEmailNotification(input: $input, condition: $condition) {
      id
      notes
      screenerDate
      screenedStocks {
        id
        stockId
        updatedDateTime
        type
        market
        stockName
        stockCode
        screenedPrice
        stopLossPrice
        firstProfitPrice
        secondProfitPrice
        thirdProfitPrice
        lastPrice
        change
        changePercentage
        volume
        transaction
        buyValue
        sellValue
        buyMinusSellValue
        vwap
        ema5
        volumeVMAChangePercentage20
        lastForthQuarterNetProfit
        averageBuyPrice
        previousClosePrice
        openPrice
        highPrice
        lowPrice
        oneYearHighPrice
        ema20
        marketCap
        eps
        peRatio
        rsi
        sector
        subSector
        listedOn
        shareIssued
        shortSelling
        dividend
        dividendYield
        fyeDate
        quarterDate
        lastForthQuarterDividend
        lastForthQuarterDividendYield
        lastForthQuarterEps
        lastForthQuarterNab
        lastForthQuarterPeRatio
        lastForthQuarterRevenue
        nab
        netProfit
        revenue
        remisierCode
      }
      resultDate
      stockResults {
        id
        stockId
        updatedDateTime
        type
        market
        stockName
        stockCode
        screenedPrice
        stopLossPrice
        firstProfitPrice
        secondProfitPrice
        thirdProfitPrice
        lastPrice
        change
        changePercentage
        volume
        transaction
        buyValue
        sellValue
        buyMinusSellValue
        vwap
        ema5
        volumeVMAChangePercentage20
        lastForthQuarterNetProfit
        averageBuyPrice
        previousClosePrice
        openPrice
        highPrice
        lowPrice
        oneYearHighPrice
        ema20
        marketCap
        eps
        peRatio
        rsi
        sector
        subSector
        listedOn
        shareIssued
        shortSelling
        dividend
        dividendYield
        fyeDate
        quarterDate
        lastForthQuarterDividend
        lastForthQuarterDividendYield
        lastForthQuarterEps
        lastForthQuarterNab
        lastForthQuarterPeRatio
        lastForthQuarterRevenue
        nab
        netProfit
        revenue
        remisierCode
      }
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const updateDailyEmailNotification = /* GraphQL */ `
  mutation UpdateDailyEmailNotification(
    $input: UpdateDailyEmailNotificationInput!
    $condition: ModelDailyEmailNotificationConditionInput
  ) {
    updateDailyEmailNotification(input: $input, condition: $condition) {
      id
      notes
      screenerDate
      screenedStocks {
        id
        stockId
        updatedDateTime
        type
        market
        stockName
        stockCode
        screenedPrice
        stopLossPrice
        firstProfitPrice
        secondProfitPrice
        thirdProfitPrice
        lastPrice
        change
        changePercentage
        volume
        transaction
        buyValue
        sellValue
        buyMinusSellValue
        vwap
        ema5
        volumeVMAChangePercentage20
        lastForthQuarterNetProfit
        averageBuyPrice
        previousClosePrice
        openPrice
        highPrice
        lowPrice
        oneYearHighPrice
        ema20
        marketCap
        eps
        peRatio
        rsi
        sector
        subSector
        listedOn
        shareIssued
        shortSelling
        dividend
        dividendYield
        fyeDate
        quarterDate
        lastForthQuarterDividend
        lastForthQuarterDividendYield
        lastForthQuarterEps
        lastForthQuarterNab
        lastForthQuarterPeRatio
        lastForthQuarterRevenue
        nab
        netProfit
        revenue
        remisierCode
      }
      resultDate
      stockResults {
        id
        stockId
        updatedDateTime
        type
        market
        stockName
        stockCode
        screenedPrice
        stopLossPrice
        firstProfitPrice
        secondProfitPrice
        thirdProfitPrice
        lastPrice
        change
        changePercentage
        volume
        transaction
        buyValue
        sellValue
        buyMinusSellValue
        vwap
        ema5
        volumeVMAChangePercentage20
        lastForthQuarterNetProfit
        averageBuyPrice
        previousClosePrice
        openPrice
        highPrice
        lowPrice
        oneYearHighPrice
        ema20
        marketCap
        eps
        peRatio
        rsi
        sector
        subSector
        listedOn
        shareIssued
        shortSelling
        dividend
        dividendYield
        fyeDate
        quarterDate
        lastForthQuarterDividend
        lastForthQuarterDividendYield
        lastForthQuarterEps
        lastForthQuarterNab
        lastForthQuarterPeRatio
        lastForthQuarterRevenue
        nab
        netProfit
        revenue
        remisierCode
      }
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteDailyEmailNotification = /* GraphQL */ `
  mutation DeleteDailyEmailNotification(
    $input: DeleteDailyEmailNotificationInput!
    $condition: ModelDailyEmailNotificationConditionInput
  ) {
    deleteDailyEmailNotification(input: $input, condition: $condition) {
      id
      notes
      screenerDate
      screenedStocks {
        id
        stockId
        updatedDateTime
        type
        market
        stockName
        stockCode
        screenedPrice
        stopLossPrice
        firstProfitPrice
        secondProfitPrice
        thirdProfitPrice
        lastPrice
        change
        changePercentage
        volume
        transaction
        buyValue
        sellValue
        buyMinusSellValue
        vwap
        ema5
        volumeVMAChangePercentage20
        lastForthQuarterNetProfit
        averageBuyPrice
        previousClosePrice
        openPrice
        highPrice
        lowPrice
        oneYearHighPrice
        ema20
        marketCap
        eps
        peRatio
        rsi
        sector
        subSector
        listedOn
        shareIssued
        shortSelling
        dividend
        dividendYield
        fyeDate
        quarterDate
        lastForthQuarterDividend
        lastForthQuarterDividendYield
        lastForthQuarterEps
        lastForthQuarterNab
        lastForthQuarterPeRatio
        lastForthQuarterRevenue
        nab
        netProfit
        revenue
        remisierCode
      }
      resultDate
      stockResults {
        id
        stockId
        updatedDateTime
        type
        market
        stockName
        stockCode
        screenedPrice
        stopLossPrice
        firstProfitPrice
        secondProfitPrice
        thirdProfitPrice
        lastPrice
        change
        changePercentage
        volume
        transaction
        buyValue
        sellValue
        buyMinusSellValue
        vwap
        ema5
        volumeVMAChangePercentage20
        lastForthQuarterNetProfit
        averageBuyPrice
        previousClosePrice
        openPrice
        highPrice
        lowPrice
        oneYearHighPrice
        ema20
        marketCap
        eps
        peRatio
        rsi
        sector
        subSector
        listedOn
        shareIssued
        shortSelling
        dividend
        dividendYield
        fyeDate
        quarterDate
        lastForthQuarterDividend
        lastForthQuarterDividendYield
        lastForthQuarterEps
        lastForthQuarterNab
        lastForthQuarterPeRatio
        lastForthQuarterRevenue
        nab
        netProfit
        revenue
        remisierCode
      }
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const createBtstEmailNotification = /* GraphQL */ `
  mutation CreateBtstEmailNotification(
    $input: CreateBTSTEmailNotificationInput!
    $condition: ModelBTSTEmailNotificationConditionInput
  ) {
    createBTSTEmailNotification(input: $input, condition: $condition) {
      id
      screenerDate
      screenedStocks {
        id
        stockId
        updatedDateTime
        type
        market
        stockName
        stockCode
        screenedPrice
        stopLossPrice
        firstProfitPrice
        secondProfitPrice
        thirdProfitPrice
        lastPrice
        change
        changePercentage
        volume
        transaction
        buyValue
        sellValue
        buyMinusSellValue
        vwap
        ema5
        volumeVMAChangePercentage20
        lastForthQuarterNetProfit
        averageBuyPrice
        previousClosePrice
        openPrice
        highPrice
        lowPrice
        oneYearHighPrice
        ema20
        marketCap
        eps
        peRatio
        rsi
        sector
        subSector
        listedOn
        shareIssued
        shortSelling
        dividend
        dividendYield
        fyeDate
        quarterDate
        lastForthQuarterDividend
        lastForthQuarterDividendYield
        lastForthQuarterEps
        lastForthQuarterNab
        lastForthQuarterPeRatio
        lastForthQuarterRevenue
        nab
        netProfit
        revenue
        remisierCode
      }
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const updateBtstEmailNotification = /* GraphQL */ `
  mutation UpdateBtstEmailNotification(
    $input: UpdateBTSTEmailNotificationInput!
    $condition: ModelBTSTEmailNotificationConditionInput
  ) {
    updateBTSTEmailNotification(input: $input, condition: $condition) {
      id
      screenerDate
      screenedStocks {
        id
        stockId
        updatedDateTime
        type
        market
        stockName
        stockCode
        screenedPrice
        stopLossPrice
        firstProfitPrice
        secondProfitPrice
        thirdProfitPrice
        lastPrice
        change
        changePercentage
        volume
        transaction
        buyValue
        sellValue
        buyMinusSellValue
        vwap
        ema5
        volumeVMAChangePercentage20
        lastForthQuarterNetProfit
        averageBuyPrice
        previousClosePrice
        openPrice
        highPrice
        lowPrice
        oneYearHighPrice
        ema20
        marketCap
        eps
        peRatio
        rsi
        sector
        subSector
        listedOn
        shareIssued
        shortSelling
        dividend
        dividendYield
        fyeDate
        quarterDate
        lastForthQuarterDividend
        lastForthQuarterDividendYield
        lastForthQuarterEps
        lastForthQuarterNab
        lastForthQuarterPeRatio
        lastForthQuarterRevenue
        nab
        netProfit
        revenue
        remisierCode
      }
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteBtstEmailNotification = /* GraphQL */ `
  mutation DeleteBtstEmailNotification(
    $input: DeleteBTSTEmailNotificationInput!
    $condition: ModelBTSTEmailNotificationConditionInput
  ) {
    deleteBTSTEmailNotification(input: $input, condition: $condition) {
      id
      screenerDate
      screenedStocks {
        id
        stockId
        updatedDateTime
        type
        market
        stockName
        stockCode
        screenedPrice
        stopLossPrice
        firstProfitPrice
        secondProfitPrice
        thirdProfitPrice
        lastPrice
        change
        changePercentage
        volume
        transaction
        buyValue
        sellValue
        buyMinusSellValue
        vwap
        ema5
        volumeVMAChangePercentage20
        lastForthQuarterNetProfit
        averageBuyPrice
        previousClosePrice
        openPrice
        highPrice
        lowPrice
        oneYearHighPrice
        ema20
        marketCap
        eps
        peRatio
        rsi
        sector
        subSector
        listedOn
        shareIssued
        shortSelling
        dividend
        dividendYield
        fyeDate
        quarterDate
        lastForthQuarterDividend
        lastForthQuarterDividendYield
        lastForthQuarterEps
        lastForthQuarterNab
        lastForthQuarterPeRatio
        lastForthQuarterRevenue
        nab
        netProfit
        revenue
        remisierCode
      }
      updatedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const createLearningMaterial = /* GraphQL */ `
  mutation CreateLearningMaterial(
    $input: CreateLearningMaterialInput!
    $condition: ModelLearningMaterialConditionInput
  ) {
    createLearningMaterial(input: $input, condition: $condition) {
      id
      learningMaterialUserId
      remisierCode
      commonCode
      title
      description
      updatedDateTime
      contents {
        id
        learningContentsUserId
        title
        description
        remisierCode
        file {
          bucket
          region
          key
        }
        type
        updatedDateTime
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateLearningMaterial = /* GraphQL */ `
  mutation UpdateLearningMaterial(
    $input: UpdateLearningMaterialInput!
    $condition: ModelLearningMaterialConditionInput
  ) {
    updateLearningMaterial(input: $input, condition: $condition) {
      id
      learningMaterialUserId
      remisierCode
      commonCode
      title
      description
      updatedDateTime
      contents {
        id
        learningContentsUserId
        title
        description
        remisierCode
        file {
          bucket
          region
          key
        }
        type
        updatedDateTime
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteLearningMaterial = /* GraphQL */ `
  mutation DeleteLearningMaterial(
    $input: DeleteLearningMaterialInput!
    $condition: ModelLearningMaterialConditionInput
  ) {
    deleteLearningMaterial(input: $input, condition: $condition) {
      id
      learningMaterialUserId
      remisierCode
      commonCode
      title
      description
      updatedDateTime
      contents {
        id
        learningContentsUserId
        title
        description
        remisierCode
        file {
          bucket
          region
          key
        }
        type
        updatedDateTime
      }
      createdAt
      updatedAt
    }
  }
`;
export const createForum = /* GraphQL */ `
  mutation CreateForum(
    $input: CreateForumInput!
    $condition: ModelForumConditionInput
  ) {
    createForum(input: $input, condition: $condition) {
      id
      forumOwnerId
      remisierCode
      commonCode
      fullName
      title
      description
      file {
        bucket
        region
        key
      }
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const updateForum = /* GraphQL */ `
  mutation UpdateForum(
    $input: UpdateForumInput!
    $condition: ModelForumConditionInput
  ) {
    updateForum(input: $input, condition: $condition) {
      id
      forumOwnerId
      remisierCode
      commonCode
      fullName
      title
      description
      file {
        bucket
        region
        key
      }
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteForum = /* GraphQL */ `
  mutation DeleteForum(
    $input: DeleteForumInput!
    $condition: ModelForumConditionInput
  ) {
    deleteForum(input: $input, condition: $condition) {
      id
      forumOwnerId
      remisierCode
      commonCode
      fullName
      title
      description
      file {
        bucket
        region
        key
      }
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const createForumThread = /* GraphQL */ `
  mutation CreateForumThread(
    $input: CreateForumThreadInput!
    $condition: ModelForumThreadConditionInput
  ) {
    createForumThread(input: $input, condition: $condition) {
      id
      forumId
      forumOwnerId
      userId
      username
      description
      file {
        bucket
        region
        key
      }
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const updateForumThread = /* GraphQL */ `
  mutation UpdateForumThread(
    $input: UpdateForumThreadInput!
    $condition: ModelForumThreadConditionInput
  ) {
    updateForumThread(input: $input, condition: $condition) {
      id
      forumId
      forumOwnerId
      userId
      username
      description
      file {
        bucket
        region
        key
      }
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteForumThread = /* GraphQL */ `
  mutation DeleteForumThread(
    $input: DeleteForumThreadInput!
    $condition: ModelForumThreadConditionInput
  ) {
    deleteForumThread(input: $input, condition: $condition) {
      id
      forumId
      forumOwnerId
      userId
      username
      description
      file {
        bucket
        region
        key
      }
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const createNewsletterEmail = /* GraphQL */ `
  mutation CreateNewsletterEmail(
    $input: CreateNewsletterEmailInput!
    $condition: ModelNewsletterEmailConditionInput
  ) {
    createNewsletterEmail(input: $input, condition: $condition) {
      id
      title
      description
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const updateNewsletterEmail = /* GraphQL */ `
  mutation UpdateNewsletterEmail(
    $input: UpdateNewsletterEmailInput!
    $condition: ModelNewsletterEmailConditionInput
  ) {
    updateNewsletterEmail(input: $input, condition: $condition) {
      id
      title
      description
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteNewsletterEmail = /* GraphQL */ `
  mutation DeleteNewsletterEmail(
    $input: DeleteNewsletterEmailInput!
    $condition: ModelNewsletterEmailConditionInput
  ) {
    deleteNewsletterEmail(input: $input, condition: $condition) {
      id
      title
      description
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const createTechnicalView = /* GraphQL */ `
  mutation CreateTechnicalView(
    $input: CreateTechnicalViewInput!
    $condition: ModelTechnicalViewConditionInput
  ) {
    createTechnicalView(input: $input, condition: $condition) {
      id
      stockId
      stockName
      userId
      fullName
      description
      file {
        bucket
        region
        key
      }
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const updateTechnicalView = /* GraphQL */ `
  mutation UpdateTechnicalView(
    $input: UpdateTechnicalViewInput!
    $condition: ModelTechnicalViewConditionInput
  ) {
    updateTechnicalView(input: $input, condition: $condition) {
      id
      stockId
      stockName
      userId
      fullName
      description
      file {
        bucket
        region
        key
      }
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteTechnicalView = /* GraphQL */ `
  mutation DeleteTechnicalView(
    $input: DeleteTechnicalViewInput!
    $condition: ModelTechnicalViewConditionInput
  ) {
    deleteTechnicalView(input: $input, condition: $condition) {
      id
      stockId
      stockName
      userId
      fullName
      description
      file {
        bucket
        region
        key
      }
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const createRequestUpdate = /* GraphQL */ `
  mutation CreateRequestUpdate(
    $input: CreateRequestUpdateInput!
    $condition: ModelRequestUpdateConditionInput
  ) {
    createRequestUpdate(input: $input, condition: $condition) {
      id
      requestUpdateUserId
      sendEmail
      fullName
      clientCode
      remisierCode
      commonCode
      email
      phoneNumber
      identityCardNumber
      currentEmail
      currentAddress
      occupation
      companyName
      annualIncome
      yearsInBusiness
      maritalStatus
      spouseName
      spouseIdentityCardNumber
      spousePhoneNumber
      spouseOccupation
      spouseCompanyName
      spouseAnnualIncome
      spouseYearsInBusiness
      subject
      description
      status
      notes
      notesToUser
      createdDateTime
      cdsEmail
      cdsAddress
      cdsPhoneNumber
      cdsAccountType
      cdsBankAccountName
      cdsBankAccount
      file {
        bucket
        region
        key
      }
      assigneeUserId
      assigneeFullName
      assigneeNotes
      createdAt
      updatedAt
    }
  }
`;
export const updateRequestUpdate = /* GraphQL */ `
  mutation UpdateRequestUpdate(
    $input: UpdateRequestUpdateInput!
    $condition: ModelRequestUpdateConditionInput
  ) {
    updateRequestUpdate(input: $input, condition: $condition) {
      id
      requestUpdateUserId
      sendEmail
      fullName
      clientCode
      remisierCode
      commonCode
      email
      phoneNumber
      identityCardNumber
      currentEmail
      currentAddress
      occupation
      companyName
      annualIncome
      yearsInBusiness
      maritalStatus
      spouseName
      spouseIdentityCardNumber
      spousePhoneNumber
      spouseOccupation
      spouseCompanyName
      spouseAnnualIncome
      spouseYearsInBusiness
      subject
      description
      status
      notes
      notesToUser
      createdDateTime
      cdsEmail
      cdsAddress
      cdsPhoneNumber
      cdsAccountType
      cdsBankAccountName
      cdsBankAccount
      file {
        bucket
        region
        key
      }
      assigneeUserId
      assigneeFullName
      assigneeNotes
      createdAt
      updatedAt
    }
  }
`;
export const deleteRequestUpdate = /* GraphQL */ `
  mutation DeleteRequestUpdate(
    $input: DeleteRequestUpdateInput!
    $condition: ModelRequestUpdateConditionInput
  ) {
    deleteRequestUpdate(input: $input, condition: $condition) {
      id
      requestUpdateUserId
      sendEmail
      fullName
      clientCode
      remisierCode
      commonCode
      email
      phoneNumber
      identityCardNumber
      currentEmail
      currentAddress
      occupation
      companyName
      annualIncome
      yearsInBusiness
      maritalStatus
      spouseName
      spouseIdentityCardNumber
      spousePhoneNumber
      spouseOccupation
      spouseCompanyName
      spouseAnnualIncome
      spouseYearsInBusiness
      subject
      description
      status
      notes
      notesToUser
      createdDateTime
      cdsEmail
      cdsAddress
      cdsPhoneNumber
      cdsAccountType
      cdsBankAccountName
      cdsBankAccount
      file {
        bucket
        region
        key
      }
      assigneeUserId
      assigneeFullName
      assigneeNotes
      createdAt
      updatedAt
    }
  }
`;
export const createRequestForm = /* GraphQL */ `
  mutation CreateRequestForm(
    $input: CreateRequestFormInput!
    $condition: ModelRequestFormConditionInput
  ) {
    createRequestForm(input: $input, condition: $condition) {
      id
      remisierCode
      userId
      broker
      file {
        bucket
        region
        key
      }
      title
      description
      tags
      uploadedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const updateRequestForm = /* GraphQL */ `
  mutation UpdateRequestForm(
    $input: UpdateRequestFormInput!
    $condition: ModelRequestFormConditionInput
  ) {
    updateRequestForm(input: $input, condition: $condition) {
      id
      remisierCode
      userId
      broker
      file {
        bucket
        region
        key
      }
      title
      description
      tags
      uploadedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteRequestForm = /* GraphQL */ `
  mutation DeleteRequestForm(
    $input: DeleteRequestFormInput!
    $condition: ModelRequestFormConditionInput
  ) {
    deleteRequestForm(input: $input, condition: $condition) {
      id
      remisierCode
      userId
      broker
      file {
        bucket
        region
        key
      }
      title
      description
      tags
      uploadedDateTime
      createdAt
      updatedAt
    }
  }
`;
export const createQuestionnaire = /* GraphQL */ `
  mutation CreateQuestionnaire(
    $input: CreateQuestionnaireInput!
    $condition: ModelQuestionnaireConditionInput
  ) {
    createQuestionnaire(input: $input, condition: $condition) {
      id
      userId
      fullName
      clientCode
      identityCardNumber
      email
      phoneNumber
      changeRemisier
      survey
      registered
      notes
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestionnaire = /* GraphQL */ `
  mutation UpdateQuestionnaire(
    $input: UpdateQuestionnaireInput!
    $condition: ModelQuestionnaireConditionInput
  ) {
    updateQuestionnaire(input: $input, condition: $condition) {
      id
      userId
      fullName
      clientCode
      identityCardNumber
      email
      phoneNumber
      changeRemisier
      survey
      registered
      notes
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestionnaire = /* GraphQL */ `
  mutation DeleteQuestionnaire(
    $input: DeleteQuestionnaireInput!
    $condition: ModelQuestionnaireConditionInput
  ) {
    deleteQuestionnaire(input: $input, condition: $condition) {
      id
      userId
      fullName
      clientCode
      identityCardNumber
      email
      phoneNumber
      changeRemisier
      survey
      registered
      notes
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback(
    $input: CreateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    createFeedback(input: $input, condition: $condition) {
      id
      userId
      fullName
      email
      phoneNumber
      ratingValue
      screenerValue
      firstTopFeature
      secondTopFeature
      thirdTopFeature
      reachable
      testimonial
      feedback
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const updateFeedback = /* GraphQL */ `
  mutation UpdateFeedback(
    $input: UpdateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    updateFeedback(input: $input, condition: $condition) {
      id
      userId
      fullName
      email
      phoneNumber
      ratingValue
      screenerValue
      firstTopFeature
      secondTopFeature
      thirdTopFeature
      reachable
      testimonial
      feedback
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteFeedback = /* GraphQL */ `
  mutation DeleteFeedback(
    $input: DeleteFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    deleteFeedback(input: $input, condition: $condition) {
      id
      userId
      fullName
      email
      phoneNumber
      ratingValue
      screenerValue
      firstTopFeature
      secondTopFeature
      thirdTopFeature
      reachable
      testimonial
      feedback
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const createClientSurvey = /* GraphQL */ `
  mutation CreateClientSurvey(
    $input: CreateClientSurveyInput!
    $condition: ModelClientSurveyConditionInput
  ) {
    createClientSurvey(input: $input, condition: $condition) {
      id
      userId
      investmentTerm
      riskTolerance
      tradingExperience
      investmentObjective
      mainSource
      notes
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const updateClientSurvey = /* GraphQL */ `
  mutation UpdateClientSurvey(
    $input: UpdateClientSurveyInput!
    $condition: ModelClientSurveyConditionInput
  ) {
    updateClientSurvey(input: $input, condition: $condition) {
      id
      userId
      investmentTerm
      riskTolerance
      tradingExperience
      investmentObjective
      mainSource
      notes
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteClientSurvey = /* GraphQL */ `
  mutation DeleteClientSurvey(
    $input: DeleteClientSurveyInput!
    $condition: ModelClientSurveyConditionInput
  ) {
    deleteClientSurvey(input: $input, condition: $condition) {
      id
      userId
      investmentTerm
      riskTolerance
      tradingExperience
      investmentObjective
      mainSource
      notes
      createdDateTime
      createdAt
      updatedAt
    }
  }
`;
