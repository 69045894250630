import React, { useState, useEffect } from 'react';
import { StockDetailsHistoryModel } from '../../models/StockDetailsHistory';
import GraphqlClient from '../../utils/GraphqlClient';
import { modelFactory } from '../../models/ModelFactory';
import { DateTimeFormatter } from '../../utils/DateTimeFormatter';
import { Alert, Button, Container, Table } from 'reactstrap';
import { PotentialStocksListTable } from './PotentialStocksListTable';
import LoadingSpinnerNoWord from '../shared/LoadingSpinnerNoWord';
import { CurrentStockDetailsInput } from '../../graphql/API';

const PotentialStocksDashboard = () => {
  const [screenerHistories, setScreenerHistories] = useState<StockDetailsHistoryModel[]>(null);
  const [allHistories, setAllHistories] = useState<StockDetailsHistoryModel[]>(null);
  const [newStockDetails, setNewStockDetails] = useState<CurrentStockDetailsInput[]>(null);
  const [oldStockDetails, setOldStockDetails] = useState<CurrentStockDetailsInput[]>(null);
  const [newTempScreenedStocks] = useState([]);
  const [oldTempScreenedStocks] = useState([]);

  useEffect(() => {
    const listAllStocks = async () => {
      const results = await Promise.all([
        GraphqlClient.listStockDetailsHistorys(null)
      ]);
      const allHistories = results[0].map(i => modelFactory<StockDetailsHistoryModel>(i, StockDetailsHistoryModel))
      .sort((a, b) => {
        if (b.screenerDate < a.screenerDate) return -1;
        if (b.screenerDate > a.screenerDate) return 1;
        return 0;
      });
      setAllHistories(allHistories);
      const allStockHistories = results[0].map(i => modelFactory<StockDetailsHistoryModel>(i, StockDetailsHistoryModel))
      .sort((a, b) => {
        if (b.screenerDate < a.screenerDate) return -1;
        if (b.screenerDate > a.screenerDate) return 1;
        return 0;
      });
      setScreenerHistories(allStockHistories);
      const newForm = Object.assign([], allStockHistories);
      for (let i = 0; i < newForm.length; i++) {
        if (newForm[i].screenerDate === DateTimeFormatter.formatDate(allHistories[0].screenerDate)) {
          for (let j = 0; j < newForm[i].screenedStocks.length; j++) {
            if (newForm[i].screenedStocks[j].type === "all") {
              if (newForm[i].screenedStocks[j].screenedPrice < 20) {
                newTempScreenedStocks.push({
                  id: newForm[i].screenedStocks[j].id,
                  stockId: newForm[i].screenedStocks[j].stockId,
                  type: newForm[i].screenedStocks[j].type,
                  market: newForm[i].screenedStocks[j].market,
                  updatedDateTime: newForm[i].screenedStocks[j].updatedDateTime,
                  stockName: newForm[i].screenedStocks[j].stockName,
                  stockCode: newForm[i].screenedStocks[j].stockCode,
                  lastPrice: newForm[i].screenedStocks[j].lastPrice,
                  screenedPrice: newForm[i].screenedStocks[j].screenedPrice,
                  stopLossPrice: newForm[i].screenedStocks[j].stopLossPrice,
                  firstProfitPrice: newForm[i].screenedStocks[j].firstProfitPrice,
                  secondProfitPrice: newForm[i].screenedStocks[j].secondProfitPrice,
                  thirdProfitPrice: newForm[i].screenedStocks[j].thirdProfitPrice,
                  change: newForm[i].screenedStocks[j].change,
                  changePercentage: newForm[i].screenedStocks[j].changePercentage,
                  volume: newForm[i].screenedStocks[j].volume,
                  transaction: newForm[i].screenedStocks[j].transaction,
                  buyValue: newForm[i].screenedStocks[j].buyValue,
                  sellValue: newForm[i].screenedStocks[j].sellValue,
                  buyMinusSellValue: newForm[i].screenedStocks[j].buyMinusSellValue,
                  vwap: newForm[i].screenedStocks[j].vwap,
                  ema5: newForm[i].screenedStocks[j].ema5,
                  volumeVMAChangePercentage20: newForm[i].screenedStocks[j].volumeVMAChangePercentage20,
                  lastForthQuarterNetProfit: newForm[i].screenedStocks[j].lastForthQuarterNetProfit,
                  averageBuyPrice: newForm[i].screenedStocks[j].averageBuyPrice,
                  previousClosePrice: newForm[i].screenedStocks[j].previousClosePrice,
                  openPrice: newForm[i].screenedStocks[j].openPrice,
                  highPrice: newForm[i].screenedStocks[j].highPrice,
                  lowPrice: newForm[i].screenedStocks[j].lowPrice,
                  oneYearHighPrice: newForm[i].screenedStocks[j].oneYearHighPrice,
                  ema20: newForm[i].screenedStocks[j].ema20,
                  marketCap: newForm[i].screenedStocks[j].marketCap,
                  eps: newForm[i].screenedStocks[j].eps,
                  peRatio: newForm[i].screenedStocks[j].peRatio,
                  rsi: newForm[i].screenedStocks[j].rsi,
                  sector: newForm[i].screenedStocks[j].sector,
                });
              }
            }
          }
        }
        if (newForm[i].screenerDate === DateTimeFormatter.formatDate(allHistories[1].screenerDate)) {
          for (let j = 0; j < newForm[i].screenedStocks.length; j++) {
            if (newForm[i].screenedStocks[j].type === "all") {
              if (newForm[i].screenedStocks[j].screenedPrice < 20) {
                oldTempScreenedStocks.push({
                  id: newForm[i].screenedStocks[j].id,
                  stockId: newForm[i].screenedStocks[j].stockId,
                  type: newForm[i].screenedStocks[j].type,
                  market: newForm[i].screenedStocks[j].market,
                  updatedDateTime: newForm[i].screenedStocks[j].updatedDateTime,
                  stockName: newForm[i].screenedStocks[j].stockName,
                  stockCode: newForm[i].screenedStocks[j].stockCode,
                  lastPrice: newForm[i].screenedStocks[j].lastPrice,
                  screenedPrice: newForm[i].screenedStocks[j].screenedPrice,
                  stopLossPrice: newForm[i].screenedStocks[j].stopLossPrice,
                  firstProfitPrice: newForm[i].screenedStocks[j].firstProfitPrice,
                  secondProfitPrice: newForm[i].screenedStocks[j].secondProfitPrice,
                  thirdProfitPrice: newForm[i].screenedStocks[j].thirdProfitPrice,
                  change: newForm[i].screenedStocks[j].change,
                  changePercentage: newForm[i].screenedStocks[j].changePercentage,
                  volume: newForm[i].screenedStocks[j].volume,
                  transaction: newForm[i].screenedStocks[j].transaction,
                  buyValue: newForm[i].screenedStocks[j].buyValue,
                  sellValue: newForm[i].screenedStocks[j].sellValue,
                  buyMinusSellValue: newForm[i].screenedStocks[j].buyMinusSellValue,
                  vwap: newForm[i].screenedStocks[j].vwap,
                  ema5: newForm[i].screenedStocks[j].ema5,
                  volumeVMAChangePercentage20: newForm[i].screenedStocks[j].volumeVMAChangePercentage20,
                  lastForthQuarterNetProfit: newForm[i].screenedStocks[j].lastForthQuarterNetProfit,
                  averageBuyPrice: newForm[i].screenedStocks[j].averageBuyPrice,
                  previousClosePrice: newForm[i].screenedStocks[j].previousClosePrice,
                  openPrice: newForm[i].screenedStocks[j].openPrice,
                  highPrice: newForm[i].screenedStocks[j].highPrice,
                  lowPrice: newForm[i].screenedStocks[j].lowPrice,
                  oneYearHighPrice: newForm[i].screenedStocks[j].oneYearHighPrice,
                  ema20: newForm[i].screenedStocks[j].ema20,
                  marketCap: newForm[i].screenedStocks[j].marketCap,
                  eps: newForm[i].screenedStocks[j].eps,
                  peRatio: newForm[i].screenedStocks[j].peRatio,
                  rsi: newForm[i].screenedStocks[j].rsi,
                  sector: newForm[i].screenedStocks[j].sector,
                });
              }
            }
          }
        }
      }
      setNewStockDetails(newTempScreenedStocks);
      setOldStockDetails(oldTempScreenedStocks);
    };
    listAllStocks();
  }, [newTempScreenedStocks, oldTempScreenedStocks]);

  return (
    <>
      <Container className="pb-5 pt-5">
        <div className="divider"></div>
        <div className="pt-5">
          {(!screenerHistories || !allHistories) && (
            <>
              <h3 className="font-weight-bold mb-5" style={{ fontSize: "24px" }}>
                Intraday potential stocks
              </h3>
              <Alert color="info">
                Support and resistant prices are determined using Gann Square of Nine.
              </Alert>
              <Table className="mt-3" bordered responsive hover>
                <thead>
                  <tr className="text-center">
                    <th>Name</th>
                    <th>Last Price</th>
                    <th>Support Level</th>
                    <th>Resistant Level</th>
                    <th>More Info</th>
                    <th>Trading View Chart</th>
                  </tr>
                </thead>
              </Table>
              <LoadingSpinnerNoWord />
            </>
          )}
          {(oldStockDetails && newStockDetails && screenerHistories && allHistories) && (
            <>
              {(newStockDetails.length !== 0) && (
                <>
                  <h3 className="font-weight-bold mb-5" style={{ fontSize: "24px" }}>
                    Intraday potential stocks screened on {DateTimeFormatter.formatStringDate(allHistories[0].screenerDate)}
                  </h3>
                  <Alert color="info">
                    Support and resistant prices are determined using Gann Square of Nine.
                  </Alert>
                  <Table className="mt-3" bordered responsive hover>
                    <thead>
                      <tr className="text-center">
                        <th>Name</th>
                        <th>Last Price</th>
                        <th>Support Level</th>
                        <th>Resistant Level</th>
                        <th>More Info</th>
                        <th>Trading View Chart</th>
                      </tr>
                    </thead>
                    <tbody>
                      {newStockDetails.slice(0, 3).map(stock => (
                        <PotentialStocksListTable 
                          stock={stock}
                          key={stock.id}
                        />
                      ))}
                    </tbody>
                  </Table>
                </>
              )}
              {(newStockDetails.length === 0) && (
                <>
                  <h3 className="font-weight-bold mb-5" style={{ fontSize: "24px" }}>
                    Intraday potential stocks screened on {DateTimeFormatter.formatStringDate(allHistories[1].screenerDate)}
                  </h3>
                  <Alert color="info">
                    Support and resistant prices are determined using Gann Square of Nine.
                  </Alert>
                  <Table className="mt-3" bordered responsive hover>
                    <thead>
                      <tr className="text-center">
                        <th>Name</th>
                        <th>Last Price</th>
                        <th>Support Level</th>
                        <th>Resistant Level</th>
                        <th>More Info</th>
                        <th>Trading View Chart</th>
                      </tr>
                    </thead>
                    <tbody>
                      {oldStockDetails.slice(0, 3).map(stock => (
                        <PotentialStocksListTable 
                          stock={stock}
                          key={stock.id}
                        />
                      ))}
                    </tbody>
                  </Table>
                </>
              )}
            </>
          )}
          <div className="pt-5 pb-5 text-center">
            <a href="https://www.ringgitlab.com/signup" className="mb-3">
              <h3 className="mb-0">
                <Button className="primary-btn header-pill font-weight-bold">Sign Up For More Intraday Potential Stocks List</Button>
              </h3>
            </a>
          </div>
        </div>
      </Container>
    </>
  )
}

export default PotentialStocksDashboard;