import React from 'react';
import {
  FormGroup,
  Input,
} from 'reactstrap';

interface Props {
  capital: number | string;
  handleChange: (event: any) => void;
}
export const CapitalForm: React.FC<Props> = (props) => {
  return (
    <FormGroup>
      <Input
        type="number"
        name="capital"
        id="capital"
        value={props.capital}
        onChange={props.handleChange}
      />
    </FormGroup>
  );
};