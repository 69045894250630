import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const FooterSNS = () => {
  return(
    <ul className="social-icon">
      <li>
        <a className="youtube" href="https://www.youtube.com/channel/UCwNZGWGSSQbpiG__W8UrPrg" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={ faYoutube } /></a>
      </li>
      <li>
        <a className="linkedin" href="https://www.linkedin.com/company/ringgitlab" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={ faLinkedin } /></a>
      </li>
    </ul>
  );
};

export default FooterSNS;
