import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { StockDetailsModel } from '../../models/StockDetails';

type Props = {
  stock: StockDetailsModel;
}

export const NoAuthStockListTable = ({ stock }: Props) => {

  return(
    <>
      <tr>
        <td className="text-center">
          <Link to={`/search/MYX/${stock.stockName}`} rel="noopener noreferrer" target="_blank">
            <Button
              className="primary-btn"
              style={{ borderRadius: "10px" }}
            >
              Open
            </Button>
          </Link>
        </td>
        <td className="text-center">
          <Button
            className="secondary-btn"
            style={{ borderRadius: "10px" }}
            href={`https://www.tradingview.com/chart/?symbol=MYX%3A${stock.stockName}`}
            rel="noopener noreferrer" target="_blank"
          >
            View
          </Button>
        </td>
        <td className="font-weight-bold text-center">{stock.stockName}</td>
        {stock.lastPrice && (
          <td className="text-center">{stock.lastPrice}</td>
        )}
        {!stock.lastPrice && (
          <td className="text-center"></td>
        )}
        {(stock.change < 0) && (
          <td className="text-center" style={{ color: '#dc3545' }}>{stock.change}</td>
        )}
        {(stock.change > 0) && (
          <td className="text-center" style={{ color: '#28a745' }}>+{stock.change}</td>
        )}
        {((stock.change === null) || (stock.change === 0)) && (
          <td className="text-center">±0.00</td>
        )}
        {((parseFloat(stock.changePercentage) / 100) > 0) && (
          <td className="text-center" style={{ color: '#28a745' }}>{stock.changePercentage}</td>
        )}
        {((parseFloat(stock.changePercentage) / 100) < 0) && (
          <td className="text-center" style={{ color: '#dc3545' }}>{stock.changePercentage}</td>
        )}
        {((stock.changePercentage === "-") || (stock.changePercentage === "") || (stock.changePercentage === "0.00%")) && (
          <td className="text-center">0.00%</td>
        )}
      </tr>
    </>
  )
}