import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import PaperTradingImg from '../../assets/paper-trading-dashboard.png';
import IncomeStatementIcon from '../../assets/icons/income-statement.png';
import MoneyIcon from '../../assets/icons/money.png';
import IdeaIcon from '../../assets/icons/idea.png';
import BalanceIcon from '../../assets/icons/balance.png';
import BarGraphIcon from '../../assets/icons/bar-graph.png';
import AssetIcon from '../../assets/icons/asset-management.png';
import Slide from 'react-reveal/Slide';

const PaperTrading = () => {

  return(
    <>
      <Container className="pb-5 mt-5 pt-5">
        <Slide bottom>
          <h1 className="text-center mt-5 mb-3 font-weight-bold">
            Paper Trading on Ringgit Lab
          </h1>
          <img alt="Ringgit Lab: Paper Trading Dashboard" className="mt-3" src={PaperTradingImg} style={{ width: '100%' }} />
          <h2 className="mt-5 text-center font-weight-bold" style={{ fontSize: '28px' }}>
            Scared to pull the trigger on that first stock purchase? Looking for a way to start practicing before you 
            put your actual, hard-earned money on the line? Start with paper trading.
          </h2>
          <h3 className="mt-5 text-center grey-color" style={{ fontSize: '24px' }}>
            <strong>Paper trading is a simulated market environment in which you write down buying and selling decisions, rather than 
            placing actual orders at a brokerage.</strong> New traders are advised to paper trade until they learn basic strategies. 
            Paper trading is also suitable for experienced traders as they can utilize the practice from time to time, especially 
            when working on new ideas and approaches.
          </h3>
          <div className="pt-5 pb-5 text-center">
            <a href="https://www.ringgitlab.com/signup" className="mb-3">
              <h3 className="mb-0">
                <Button className="primary-btn header-pill font-weight-bold">Sign Up For Free</Button>
              </h3>
            </a>
          </div>
        </Slide>
        <div className="divider mt-5"></div>
        <Slide bottom>
          <h2 className="mt-5 pb-3 font-weight-bold text-center">
            Benefits of paper trading
          </h2>
          <Row className="mt-5">
            <Col md={1} sm={2} xs={2} className="mb-5">
              <img alt="Ringgit Lab: Icon" src={IncomeStatementIcon} style={{ width: '100%' }} />
            </Col>
            <Col md={11} sm={10} xs={10} className="mb-5">
              <h3 className="font-weight-bold" style={{ fontSize: '24px' }}>
                Learning to invest without any risk
              </h3>
              <h4 className="grey-color" style={{ fontSize: '24px' }}>
                Ringgit Lab gives you the ability to try without that fear holding you back. Because there is no 
                actual money involved, you can experiment without worrying about losing money.
              </h4>
            </Col>
            <Col md={1} sm={2} xs={2} className="mb-5">
              <img alt="Ringgit Lab: Icon" src={MoneyIcon} style={{ width: '100%' }} />
            </Col>
            <Col md={11} sm={10} xs={10} className="mb-5">
              <h3 className="font-weight-bold" style={{ fontSize: '24px' }}>
                Learn as you go
              </h3>
              <h4 className="grey-color" style={{ fontSize: '24px' }}>
                Terms like limit orders, market orders, stop-loss limits, quantity, and the price will pop up when you 
                place the first order. Most paper trading simulators will give you the same feel as a brokerage. This will allow the 
                chance to learn the process so that it takes away that fear.
              </h4>
            </Col>
            <Col md={1} sm={2} xs={2} className="mb-5">
              <img alt="Ringgit Lab: Icon" src={IdeaIcon} style={{ width: '100%' }} />
            </Col>
            <Col md={11} sm={10} xs={10} className="mb-5">
              <h3 className="font-weight-bold" style={{ fontSize: '24px' }}>
                No stress
              </h3>
              <h4 className="grey-color" style={{ fontSize: '24px' }}>
                Utilizing paper trading simulators to learn removes two of the biggest emotions that can cloud your 
                decisions when investing - fear and greed. Paper trading simulators help eliminate both of these emotions 
                from your trading. Because you have no fear of missing out or greed involved. It allows you to focus on 
                the process of valuing a company.
              </h4>
            </Col>
            <Col md={1} sm={2} xs={2} className="mb-5">
              <img alt="Ringgit Lab: Icon" src={BalanceIcon} style={{ width: '100%' }} />
            </Col>
            <Col md={11} sm={10} xs={10} className="mb-5">
              <h3 className="font-weight-bold" style={{ fontSize: '24px' }}>
                Confidence
              </h3>
              <h4 className="grey-color" style={{ fontSize: '24px' }}>
                As a beginner, you may approach investing with a little uncertainty. Nothing can help build your 
                confidence in your investing ability like executing trades and seeing the potential monetary gains from your 
                trades. These boosts can go a long way towards your ultimate success as an investor.
              </h4>
            </Col>
            <Col md={1} sm={2} xs={2} className="mb-5">
              <img alt="Ringgit Lab: Icon" src={BarGraphIcon} style={{ width: '100%' }} />
            </Col>
            <Col md={11} sm={10} xs={10} className="mb-5">
              <h3 className="font-weight-bold" style={{ fontSize: '24px' }}>
                Ability to make mistakes
              </h3>
              <h4 className="grey-color" style={{ fontSize: '24px' }}>
                One of the greatest learning tools we all have in live is the ability to learn from our mistakes. Paper trading 
                gives you the ability. To learn from your mistakes without losing actual money. As you learn from your mistakes 
                and change your strategies you can gather more evidence.
              </h4>
            </Col>
            <Col md={1} sm={2} xs={2} className="mb-5">
              <img alt="Ringgit Lab: Icon" src={AssetIcon} style={{ width: '100%' }} />
            </Col>
            <Col md={11} sm={10} xs={10} className="mb-5">
              <h3 className="font-weight-bold" style={{ fontSize: '24px' }}>
                Ability to test different strategies
              </h3>
              <h4 className="grey-color" style={{ fontSize: '24px' }}>
                With all of the different investing strategies out there it can be a bit overwhelming deciding which one 
                is the best. For you and your personality. As well as financially. With paper trading simulators, you are 
                able to conduct your own experiments and decide which one suits you the best.
              </h4>
            </Col>
          </Row>
          <div className="pt-5 pb-5 text-center">
            <a href="https://www.ringgitlab.com/signup" className="mb-3">
              <h3 className="mb-0">
                <Button className="primary-btn header-pill font-weight-bold">Sign Up For Free</Button>
              </h3>
            </a>
          </div>
        </Slide>
      </Container>
    </>
  )
}

export default PaperTrading;