import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, Row, Col } from 'reactstrap';
import RinggitLabMacbookImg from '../../assets/ringgitlab-macbook.png';
import Slide from 'react-reveal/Slide';
import TradingViewChart from '../shared/TradingViewChart';

const Introduction = () => {

  return(
    <Container className="mt-5 mb-5">
      <div className="pt-5">
        <TradingViewChart />
      </div>
      <Row className="pt-5">
        <Col md={6} sm={12} xs={12} className="pt-5 pb-5">
          <Slide bottom>
            <h1 className="text-md-left text-center font-weight-bold" style={{ fontFamily: '"Montserrat", sans-serif' }}>
              Ringgit Lab
            </h1>
            <h2 className="text-md-left text-center mt-4 pb-3 font-weight-bold grey-color" style={{ fontSize: '32px' }}>
              Maximize your profit in stock markets
            </h2>
            <h2 className="text-md-left text-center grey-color mt-5 pb-3" style={{ fontSize: '24px' }}>
              Ringgit Lab offers a set of trading tools for stock markets including stock screener, trading journal, paper trading, market news, free learning materials and more.
            </h2>
            <div className="mt-5 text-md-left text-center">
              <Link to="/signup">
                <h3 className="mb-0">
                  <Button className="primary-btn header-pill font-weight-bold">Sign Up For Free</Button>
                </h3>
              </Link>
            </div>
          </Slide>
        </Col>
        <Col md={6} sm={12} xs={12} className="align-self-center pt-5 pb-5">
          <Slide bottom>
            <img alt="Ringgit Lab" src={RinggitLabMacbookImg} style={{ width: '100%', objectFit: 'fill', borderRadius: '10px' }} />
          </Slide>
        </Col>
      </Row>
    </Container>
  )
}

export default Introduction;