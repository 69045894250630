import React, { useEffect, useState } from 'react';
import { Card, CardBody, Container, FormGroup, Input, Label, Table } from 'reactstrap';
import GraphqlClient from '../../utils/GraphqlClient';
import LoadingSpinnerNoWord from '../shared/LoadingSpinnerNoWord';
import { Helmet } from 'react-helmet';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import TradingViewChart from '../shared/TradingViewChart';
import { StockDetailsModel } from '../../models/StockDetails';
import { modelFactory } from '../../models/ModelFactory';
import { NoAuthStockListTable } from './NoAuthStockListTable';

const StockDashboard = () => {
  const [searchStock, setSearchStock] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);
  const [allStocks, setAllStocks] = useState<StockDetailsModel[]>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    setAllStocks(null);
    const filter = {
      changePercentage: { ne: "" }
    }
    const listAllStocks = async () => {
      const results = await Promise.all([
        GraphqlClient.listStockDetailss(filter)
      ]);
      const allStocks = results[0].map(i => modelFactory<StockDetailsModel>(i, StockDetailsModel))
      .sort((a, b) => {
        if (b.stockName > a.stockName) return -1;
        if (b.stockName < a.stockName) return 1;
        return 0;
      });
      setAllStocks(allStocks);
    };
    listAllStocks();
  }, []);

  const handleChange = event => {
    if (!allStocks) {
      return;
    }
    const results = allStocks.filter(stock =>
      stock.stockName.includes(searchStock.toUpperCase())
    );
    setSearchResults(results);
    setSearchStock(event.target.value);
  };

  return(
    <>
      <Helmet>
        <title>Ringgit Lab: Stock Search</title>
        <meta name="description" 
          content="Search a stock and learn more about the fundamental and technical analysis of the stock to help your decision in investing." 
        />

        {/* Google / Search Engine Tags */}
        <meta itemProp="name" content="Ringgit Lab: Stock Search" />
        <meta itemProp="description" content="Search a stock and learn more about the fundamental and technical analysis of the stock to help your decision in investing." />
      </Helmet>
      <Header />
      <Container className="pb-5 pt-5 mt-5 mb-5">
        <TradingViewChart />
        <h2 className="font-weight-bold mt-5">Stock List</h2>
        <div className="divider"></div>
        {allStocks && (
          <FormGroup>
            <Label className="font-weight-bold mt-3">Search stock</Label>
            <Input
              type="text"
              style={{ textTransform: 'uppercase' }}
              value={searchStock}
              onChange={handleChange}
            />
          </FormGroup>
        )}
        {!allStocks && (
          <>
            <Card className="pb-2 pt-2 mb-3 w-100">
              <CardBody>
                <LoadingSpinnerNoWord />
              </CardBody>
            </Card>
          </>
        )}
        {allStocks && (searchResults.length > 0) && (
          <Table striped bordered responsive className="mt-5">
            <thead>
              <tr className="text-center">
                <th>More Details</th>
                <th>Chart</th>
                <th>Name</th>
                <th>Last Price</th>
                <th>Change</th>
                <th>Change %</th>
              </tr>
            </thead>
            <tbody>
              {searchResults.map(stock => (
                <React.Fragment key={stock.id}>
                  {(stock.stockName) && (
                    <NoAuthStockListTable 
                      stock={stock}
                    />
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        )}
      </Container>
      <div className="pb-5 mb-5"></div>
      <div className="pb-5 mb-5"></div>
      <Footer />
    </>
  )
}

export default StockDashboard;