import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import Logo from '../../assets/ringgit-lab-logo.png';
import HoverControlledDropdown from '../shared/HoverControlledDropdown';

const Header = () => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return(
    <>
      <Navbar className="fixed-navbar pt-3 pb-3" expand="md">
        <Link to="/" className="text-decoration-none" style={{ width: '200px' }}>
          <img src={Logo} alt="Ringgit Lab Logo" className="w-100" />
        </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={open} navbar>
          <Nav className="mt-0 mr-auto" navbar>
            <NavItem className="toggle-space">
              <Link to="/search" className="nav-item-left" onClick={toggle}>
                Stock Search
              </Link>
            </NavItem>
            <NavItem className="toggle-space">
              <a href="https://www.ringgitlab.com/market-news" className="nav-item-left" onClick={toggle} rel="noopener noreferrer" target="_blank">
                Market News
              </a>
            </NavItem>
            <HoverControlledDropdown>
              <NavItem className="toggle-space">
                <DropdownToggle tag="a" className="nav-item-left" style={{ cursor: 'pointer' }}>
                  Features
                </DropdownToggle>
              </NavItem>
              <DropdownMenu>
                <DropdownItem>
                  <Link to="/screener" className="nav-item-dropdown" onClick={toggle}>
                    Stock Screener
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/trading-journal" className="nav-item-dropdown" onClick={toggle}>
                    Trading Journal
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/paper-trading" className="nav-item-dropdown" onClick={toggle}>
                    Paper Trading
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/brokerage-calculator" className="nav-item-dropdown" onClick={toggle}>
                    Brokerage Calculator
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            </HoverControlledDropdown>
          </Nav>
          <Nav className="mt-0 ml-auto" navbar>
            <NavItem className="toggle-space">
              <a href="https://www.ringgitlab.com/login" className="nav-item" onClick={toggle} rel="noopener noreferrer" target="_blank">Log In</a>
            </NavItem>
            <NavItem className="toggle-space">
              <a href="https://www.ringgitlab.com/signup" className="header-pill" rel="noopener noreferrer" target="_blank">
                Sign Up For Free
              </a>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </>
  )
}

export default Header;