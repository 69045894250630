import React from 'react';
import './App.scss';
import NoAuthRoutes from './routes/NoAuthRoutes';

function App() {

  return (
    <>
      <div className="App">
        {/* <CookieConsent
          disableStyles={true}
          location="bottom"
          buttonText="GOT IT"
          cookieName="_ringgit_lab"
          buttonClasses="consent-text btn-primary"
          containerClasses="consent-body"
          contentClasses="text-capitalize"
          expires={150}
        >
          This website uses cookies to ensure you get the best experience on our website.{" "}
          <Link to="/privacy-policy">Cookies Policy</Link>
        </CookieConsent> */}
        <NoAuthRoutes />
      </div>
    </>
  );
}

export default App;