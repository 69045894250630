import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import PerformanceManagement from './TradingJournal';

const TradingJournalPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return(
    <>
      <Helmet>
        <title>Ringgit Lab: Trading Journal</title>
        <meta name="description" 
          content="Trading journal is where you record and review daily trades for better output and for future reference. A journal can help you track progress as well as study mistakes made when entering or exiting a trade."
        />

        {/* Google / Search Engine Tags */}
        <meta itemProp="name" content="Ringgit Lab: Trading Journal" />
        <meta itemProp="description" content="Trading journal is where you record and review daily trades for better output and for future reference. A journal can help you track progress as well as study mistakes made when entering or exiting a trade." />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content="https://stocks.ringgitlab.com/trading-journal" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Ringgit Lab: Trading Journal" />
        <meta property="og:description" content="Trading journal is where you record and review daily trades for better output and for future reference. A journal can help you track progress as well as study mistakes made when entering or exiting a trade." />
        <meta property="og:image" content="https://stocks.ringgitlab.com/trading-journal-dashboard.png" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Ringgit Lab: Trading Journal" />
        <meta name="twitter:description" content="Trading journal is where you record and review daily trades for better output and for future reference. A journal can help you track progress as well as study mistakes made when entering or exiting a trade." />
        <meta name="twitter:image" content="https://stocks.ringgitlab.com/trading-journal-dashboard.png" />
      </Helmet>
      <Header />
      <PerformanceManagement />
      <Footer />
    </>
  )
}

export default TradingJournalPage;