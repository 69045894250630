import React, { useEffect } from 'react';

const TradingViewFinancialWidget = (props) => {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://s3.tradingview.com/external-embedding/embed-widget-financials.js`;
    script.async = true;
    script.innerHTML = JSON.stringify({
      "symbol": `MYX:${props.stockName}`,
      "colorTheme": "light",
      "isTransparent": false,
      "largeChartUrl": "",
      "displayMode": "regular",
      "width": "100%",
      "height": 780,
      "locale": "en"
    })
    document.getElementById("financialContainer").appendChild(script);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return(
    <div id="financialContainer">
      <div className="tradingview-widget-container">
        <div className="tradingview-widget-container__widget">
          </div>
      </div>
    </div>
  );
}

export default TradingViewFinancialWidget;